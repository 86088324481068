<div class="tbl-container">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows
        class="mat-elevation-z8 shipping-details-tbl parent-table">
        <ng-container matColumnDef="expandIcon">
            <th mat-header-cell *matHeaderCellDef> Details </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="toggleRow(element); $event.stopPropagation()">
                    <mat-icon>
                        {{ expandedElement === element ? 'remove' : 'add' }}
                    </mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container *ngFor="let column of columnsToDisplay">
            <ng-container *ngIf="column !== 'approveReject' && column !== 'expandIcon'">
                <ng-container matColumnDef="{{ column }}">
                    <th mat-header-cell *matHeaderCellDef class="column-width">{{ columnHeaderMap[column] }}</th>
                    <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container matColumnDef="approveReject">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button class="approve-button" matTooltip="Approve" (click)="approveRow(row)">
                    <span class="material-symbols-outlined approve-icon">check_circle</span>
                </button>
                <button mat-icon-button class="reject-button" matTooltip="Reject" (click)="rejectRow(row)">
                    <span class="material-symbols-outlined reject-icon">cancel</span>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="element.childData"
                        class="mat-elevation-z8 shipping-details-tbl child-table">
                        <ng-container matColumnDef="{{ column }}" *ngFor="let column of childColumnsToDisplay">
                            <th mat-header-cell *matHeaderCellDef class="column-width">{{ column }}</th>
                            <td mat-cell *matCellDef="let childData">{{ childData[column] }}</td>
                        </ng-container>
                        <tr mat-row *matRowDef="let childData; columns: childColumnsToDisplay"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="element-row"
            [class.expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
</div>
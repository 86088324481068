import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TableAction, TableColumn } from 'src/app/common/models/index';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { SKU_TABLE_HEADER, SKU_QUANTITY_TABLE_COLUMN, SKU_VALUE_TABLE_COLUMN } from '../../constants/orders-at-risk.constant';
import { IOrdersAtRiskMaterialTableData } from '../../interfaces/orders-at-risk.interface';

@Component({
  selector: 'app-projected-otif-by-sku-table',
  templateUrl: './projected-otif-by-sku-table.component.html',
  styleUrls: ['./projected-otif-by-sku-table.component.scss'],
})
export class ProjectedOtifBySkuTableComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() tableData: any[] = [];
  @Input() showPagination = true;
  @Input() isSortable = false;
  @Input() showSearch = true;
  @Input() showSelectionBox = false;
  @Input() paginationSize: number[] = [5, 10, 15];
  @Input() defaultPageSize = 10;
  @Input() actionList: TableAction[] = [];
  @Input() customHeight: string = '250';
  @Input() selectedToggle: boolean = true;

  @Output() emitSelectedCustomerId = new EventEmitter<string>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [];
  value: string;
  tableColumns: TableColumn[] = [];
  headerSuffix: string = '';

  tableHeader = SKU_TABLE_HEADER;

  constructor() {}

  ngOnInit() {
    // set table columns
    this.displayedColumns = this.displayedColumns.concat(
      this.tableColumns.map((x) => x.columnDef)
    );

    // set table data
    this.dataSource = new MatTableDataSource<any>(this.tableData);

    // set pagination
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.hasOwnProperty('tableData') && this.tableData) ||
      (changes.hasOwnProperty('selectedToggle') && this.selectedToggle)
    ) {
      this.dataSource = new MatTableDataSource(this.tableData);
      this.tableColumns = this.selectedToggle
        ? SKU_QUANTITY_TABLE_COLUMN
        : SKU_VALUE_TABLE_COLUMN;
      this.headerSuffix = this.selectedToggle
        ? this.tableHeader.QUANTITY
        : this.tableHeader.VALUE;
    }
  }

  /**
   * onRowClick emits the event to parent component with the rowData
   * @param rowData : table row data
   */
  onRowClick(rowData: IOrdersAtRiskMaterialTableData): void {
    const selectedMaterialId = rowData?.Material_ID;
    this.emitSelectedCustomerId.emit(selectedMaterialId);
  }

  /** column sorting is performed here */
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  /** applyFilter searches the entered input value */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

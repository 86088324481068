import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';
import { WarehouseUtilizationService } from '../../services/warehouse-utilization.service';
import {
  IModalTable,
  INameValue,
  IWMSDataRequestBody,
  IWMSDataResponse,
  IWMSSummaryCard,
} from '../../interfaces/warehouse-utilization.interface';
import {
  SPACE_UTILIZATION_RANGE_DATA,
  WMS_CHART_COLORS,
  WMS_CHART_NAMES,
  WMS_MIN_X_AXIS_LIMIT,
} from '../../constants/warehouse-utilization.constant';
import { FilterDataService } from 'src/app/services/filter-data/filter-data.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-daily-warehouse-container',
  templateUrl: './daily-warehouse-container.component.html',
  styleUrls: ['./daily-warehouse-container.component.scss'],
})
export class DailyWarehouseContainerComponent implements OnInit, OnDestroy {
  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  minimumXAxisLimit = WMS_MIN_X_AXIS_LIMIT;
  chartColorList = WMS_CHART_COLORS;
  chartNameList = WMS_CHART_NAMES;
  displayLoader: boolean = true;
  asISOrRecommendationToggle: boolean = true;
  isApiError: boolean = false;
  selectedWMSFilters: IWMSDataRequestBody;
  selectedDC: string[] = [];
  wmsSummaryCardData: IWMSSummaryCard[];
  utilizedDCCapacityChartData: any; //check model
  ioShipmentChartData: any; //check model
  spaceUtilizationData: IWMSSummaryCard[] = [];
  spaceUtilizationRange = SPACE_UTILIZATION_RANGE_DATA;
  forecastedDCUtilizationTableData: IModalTable;
  selectedMonth: string = 'Oct'; // need to ask srijita to send from backend
  showIOShipmentChart: boolean = false;
  showIOShipmentChartApiError: boolean = false;
  private subscriptions$: Array<Subscription> = new Array<Subscription>();
  isWeekly: boolean = false;
  selectedDeliveryDateMin: Date;
  selectedDeliveryDateMax: Date;
  private readonly SOE_FILTER_RESET_STATE = 'soeFilterResetState';
  allLabel = 'All';
  constructor(
    private warehouseUtilizationService: WarehouseUtilizationService,
    private readonly toasterService: ToasterWrapperService,
    private filterDataService: FilterDataService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.getDailyWMSFilter();
  }

  ngOnDestroy(): void {
    this.selectedWMSFilters = null;
    this.selectedDC = [];
    this.showIOShipmentChart = false;
    this.showIOShipmentChartApiError = false;
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {}

  getDailyWMSFilter(): void {
    this.subscriptions$.push(
      combineLatest([
        this.filterDataService.currentFilterData.pipe(debounceTime(300)),
        this.breadcrumbService.getAsIsOrRecommendationToggle.pipe(
          debounceTime(300)
        ),
      ]).subscribe(([filterData, asISOrRecommendationToggle]) => {
        if (filterData != null) {
          const filterResetSate =
            localStorage.getItem(this.SOE_FILTER_RESET_STATE) == 'true'
              ? true
              : false;
          if (filterResetSate) {
            this.selectedDC = [this.allLabel];
            this.selectedWMSFilters = {
              plantID: this.selectedDC,
              deliveryDateMin: filterData?.deliveryDateMin,
              deliveryDateMax: filterData?.deliveryDateMax,
            };
          } else {
            this.selectedWMSFilters = {
              plantID:
                this.selectedDC[0] != this.allLabel
                  ? this.selectedDC
                  : filterData?.plantId,
              deliveryDateMin: filterData?.deliveryDateMin,
              deliveryDateMax: filterData?.deliveryDateMax,
            };
          }

          this.asISOrRecommendationToggle = asISOrRecommendationToggle;
          this.loadWarehouseUtilizationData();
          if (
            this.selectedDC.length > 0 &&
            this.selectedDC[0] != this.allLabel
          ) {
            this.getIOShipmentData(this.selectedDC[0]);
          }
        }
      })
    );
  }

  loadWarehouseUtilizationData(): void {
    this.displayLoader = true;
    this.showIOShipmentChart = false;
    this.ioShipmentChartData = null;
    this.subscriptions$.push(
      this.warehouseUtilizationService
        .getWMSAllData(this.selectedWMSFilters, this.asISOrRecommendationToggle)
        .subscribe(
          (data: IWMSDataResponse) => {
            this.wmsSummaryCardData = data?.summaryCard?.data;
            const wsDataCard = data?.dataCard?.data;
            this.spaceUtilizationData = [];
            // commented till backend gets updated with correct logic
            // wsDataCard.map((data, index) => {
            //   this.spaceUtilizationData.push({
            //     ...data,
            //     lastYear: this.spaceUtilizationRange[index],
            //   });
            // });

            const soeChartsData: any = data?.tmsCharts?.data;

            soeChartsData.map((data) => {
              this.utilizedDCCapacityChartData = {
                title: data?.name,
                xAxisLabel: data?.xAxisLabel,
                yAxisLabel: data.yAxisLabel,
                chartData: '',
                xAxisScrollable: false,
              };

              if (data.lineChartData) {
                const xAxisScrollable =
                  data.lineChartData[0].series?.length > this.minimumXAxisLimit
                    ? true
                    : false;
                this.utilizedDCCapacityChartData = {
                  ...this.utilizedDCCapacityChartData,
                  xAxisScrollable: xAxisScrollable,
                };

                this.utilizedDCCapacityChartData.chartData =
                  data.lineChartData.map((el: any, i: number) => {
                    return {
                      type:
                        el.name ==
                        this.chartNameList.FIFTY_PERCENT_WAREHOUSE_CAPACITY
                          ? 'line'
                          : el.name ==
                            this.chartNameList.NINETY_PERCENT_WAREHOUSE_CAPACITY
                          ? 'line'
                          : 'column',
                      name: el.name,
                      color: this.chartColorList[i],
                      yAxis:
                        el.name ==
                        this.chartNameList.FIFTY_PERCENT_WAREHOUSE_CAPACITY
                          ? 1
                          : el.name ==
                            this.chartNameList.NINETY_PERCENT_WAREHOUSE_CAPACITY
                          ? 1
                          : 0,
                      data: el.series.map((el: INameValue) => {
                        {
                          return [el.name, el.value];
                        }
                      }),
                    };
                  });
              }
            });

            // Transform and set the required data for the forecasted DC Utilization table
            const forecastedData = data?.dynamicDatas[0].datas[0].columnValues;
            if (forecastedData?.length > 0) {
              let forecastTableData = this.transformForecastTableData(
                forecastedData,
                ['Plant Id', 'Rank']
              );

              const forecastColumns = Object.keys(forecastTableData[0]);
              let forecastHeaders = {};
              const headers = forecastColumns.map((data) => {
                return { [data]: data };
              });
              headers.map((data) => {
                Object.assign(forecastHeaders, data);
              });
              this.forecastedDCUtilizationTableData = {
                data: forecastTableData,
                columns: forecastColumns,
                headers: forecastHeaders,
              };
            }

            //getDCUtilizationCardData function added temporarily till backend logic gets updated for utilized card
            const dcData = data?.dynamicDatas[0].datas[0].columnValues;
            this.getDCUtilizationCardData(dcData, wsDataCard);

            this.displayLoader = false;
            this.isApiError = false;
          },
          (error) => {
            // Handle errors
            console.error('Error fetching data:', error);
            this.toasterService.error(error?.error);
            this.isApiError = true;
            // Stop the loader even in case of errors
            this.displayLoader = false;
          }
        )
    );
  }

  getDCUtilizationCardData(data, wsDataCard) {
    let forecastedData = data;
    // Keys you want to delete
    const keysToDelete: string[] = ['Plant Id', 'Rank'];
    // Delete the specified keys from each object in the array
    forecastedData.forEach((obj) => {
      keysToDelete.forEach((key) => {
        delete obj[key];
      });
    });
    // convert all values into flat array
    const values: number[] = forecastedData.flatMap((obj) =>
      Object.values(obj)
    );
    const result = values.reduce(
      (counts, num) => {
        if (num > 90) {
          counts.above90++;
        } else if (num >= 50 && num <= 90) {
          counts.between50and90++;
        } else if (num < 50) {
          counts.below50++;
        }
        return counts;
      },
      { above90: 0, between50and90: 0, below50: 0 }
    );

    this.spaceUtilizationData.push(
      {
        name: wsDataCard[0].name,
        icon: wsDataCard[0].icon,
        currentYear: result?.above90.toString(),
        lastYear: 'Above 90%',
        unit: wsDataCard[0].unit,
        hasIncreased: wsDataCard[0].hasIncreased,
      },
      {
        name: wsDataCard[1].name,
        icon: wsDataCard[1].icon,
        currentYear: result?.below50.toString(),
        lastYear: 'Below 50%',
        unit: wsDataCard[1].unit,
        hasIncreased: wsDataCard[1].hasIncreased,
      },
      {
        name: wsDataCard[2].name,
        icon: wsDataCard[2].icon,
        currentYear: result?.between50and90.toString(),
        lastYear: 'Between 50% and 90%',
        unit: wsDataCard[2].unit,
        hasIncreased: wsDataCard[2].hasIncreased,
      }
    );
  }

  transformForecastTableData(inputData: any[], dynamicKey?: string[]): any[] {
    const singleObj = inputData[0]; // take single object from array
    const allKeys = Object.keys(singleObj); // get all keys of object
    const finalKeys = [];
    // below function will transform date keys to required format
    allKeys.forEach((key) => {
      if (!dynamicKey.includes(key)) {
        finalKeys.push(
          new Date(key).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
          })
        );
      } else finalKeys.push(key);
    });
    // return new object created from separate keys and values.
    return inputData.map((item, index) => {
      const values = Object.keys(item).map((key) => item[key]);
      return finalKeys.reduce((acc, key, index) => {
        acc[key] = !dynamicKey.includes(key)
          ? `${values[index]}%`
          : values[index];
        return acc;
      }, {});
    });
  }

  getIOShipmentData(dc: string) {
    const ioShipmentFilter = { ...this.selectedWMSFilters, plantID: [dc] };
    this.selectedWMSFilters = { ...this.selectedWMSFilters, plantID: [dc] };
    this.selectedDC = [dc];
    this.loadWarehouseUtilizationData();
    this.subscriptions$.push(
      this.warehouseUtilizationService
        .getIOShipmentData(ioShipmentFilter, this.asISOrRecommendationToggle)
        .subscribe(
          (data) => {
            const ioShipmentChart = data?.tmsCharts?.data;

            ioShipmentChart.map((data) => {
              this.ioShipmentChartData = {
                title: data?.name,
                xAxisLabel: data?.xAxisLabel,
                yAxisLabel: data.yAxisLabel,
                chartData: '',
                xAxisScrollable: false,
              };

              if (data.lineChartData) {
                const xAxisScrollable =
                  data.lineChartData[0].series?.length > this.minimumXAxisLimit
                    ? true
                    : false;
                this.ioShipmentChartData = {
                  ...this.ioShipmentChartData,
                  xAxisScrollable: xAxisScrollable,
                };

                this.ioShipmentChartData.chartData = data.lineChartData.map(
                  (el: any, i: number) => {
                    return {
                      type: 'column',
                      name: el.name,
                      color: this.chartColorList[i],
                      yAxis: 0,
                      data: el.series.map((el: INameValue) => {
                        {
                          return [el.name + ' ' + this.selectedMonth, el.value];
                        }
                      }),
                    };
                  }
                );
              }
            });
            this.showIOShipmentChart = true;
          },
          (error) => {
            console.error(error);
            this.toasterService.error(
              'Error occurred while fetching Ingoing and Outgoing Shipment data'
            );
            this.showIOShipmentChart = false;
            this.showIOShipmentChartApiError = true;
          }
        )
    );
  }

  loadSelectedDcData(dc: string): void {
    this.selectedDC = [dc];
    this.selectedWMSFilters = {
      ...this.selectedWMSFilters,
      plantID: this.selectedDC,
    };
    this.loadWarehouseUtilizationData();
  }
}

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-sku-details-modal',
  templateUrl: './sku-details-modal.component.html',
  styleUrls: ['./sku-details-modal.component.scss']
})
export class SkuDetailsModalComponent implements OnInit, OnDestroy {
  tableData: any;
  displayLoader: boolean = false;
  allowEdits: boolean = false;
  subscriptions$: Subscription[] = [];
  changedRows: any[] = [];
  editingCell: { plantIndex: number, prodIndex: number } | null = null;
  originalValues: { [key: string]: number } = {};

  constructor(
    private dialogRef: MatDialogRef<SkuDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private schedulingDetailsService: SchedulingDetailsService,
    private toasterService: ToastrService,
    private msalService: MsalService
  ) { }

  ngOnInit(): void {
    this.getModalTableData();
    this.checkAllowEdits();
  }

  getModalTableData(): void {
    this.displayLoader = true;
    this.tableData = null;

    const payload: { plantID: string; materialID?: string } = {
      plantID: this.data.plantID,
      materialID: this.data.materialID || undefined
    };

    this.subscriptions$.push(
      this.schedulingDetailsService.getSkuDetailsData(payload).subscribe(
        (response: any[]) => {
          if (response && response.length) {
            this.tableData = response;
          } else {
            this.tableData = [];
            this.toasterService.info('No data available for the selected plant');
          }
          this.displayLoader = false;
        },
        (error) => {
          this.toasterService.error('Error occurred while fetching API response');
          this.displayLoader = false;
        }
      )
    );
  }

  checkAllowEdits(): void {
    this.subscriptions$.push(
      this.schedulingDetailsService.allowsubmitPSPopup().subscribe(
        (response: { allowEdits: boolean }) => {
          this.allowEdits = response.allowEdits;
        },
        (error) => {
          this.toasterService.error('Failed to fetch edit permissions');
        }
      )
    );
  }

  updatePlannedProduction(value: string, plantIndex: number, prodIndex: number): void {
    const newValue = parseInt(value, 10);
    const production = this.tableData[plantIndex].production[prodIndex];
  
    if (
      !isNaN(newValue) &&
      newValue >= 0 &&
      newValue <= production.plannedProduction + production.updatedQtyAtRisk
    ) {
      const difference = newValue - production.plannedProduction;
      const date = production.date;
  
      // Ensure remainingCapacity does not go negative
      if (production.remainingCapacity - difference < 0) {
        this.toasterService.error('Not enough remaining capacity!');
        return;
      }
  
      // Calculate the total difference for all rows sharing the same date
      let totalChangeForDate = 0;
      this.tableData.forEach((plant) => {
        plant.production.forEach((prod) => {
          if (prod.date === date) {
            totalChangeForDate += prod.plannedProduction - newValue;
          }
        });
      });
  
      // Prevent updatedQtyAtRisk from going negative
      this.tableData.forEach((plant) => {
        plant.production.forEach((prod) => {
          if (prod.date === date) {
            const newUpdatedQtyAtRisk = prod.updatedQtyAtRisk - difference;
            if (newUpdatedQtyAtRisk < 0) {
              this.toasterService.error('Updated Qty at Risk cannot be negative!');
              return;
            }
          }
        });
      });
  
      // If checks pass, apply the changes
      production.plannedProduction = newValue;
      production.remainingCapacity -= difference;
  
      // Update Updated Qty at Risk for all rows with the same date
      this.tableData.forEach((plant) => {
        plant.production.forEach((prod) => {
          if (prod.date === date) {
            prod.updatedQtyAtRisk = Math.max(prod.updatedQtyAtRisk - difference, 0); // Ensure it doesn't go below 0
          }
        });
      });
  
      this.allowEdits = true;
  
      // Track the changed row
      const changedRow = {
        plant: this.tableData[plantIndex].plant,
        production: { ...production, date: production.date } // Include date
      };
  
      // Check if the row already exists in changedRows
      const existingIndex = this.changedRows.findIndex(
        (row) => row.plant === changedRow.plant && row.production.date === changedRow.production.date
      );
  
      if (existingIndex > -1) {
        this.changedRows[existingIndex] = changedRow;
      } else {
        this.changedRows.push(changedRow);
      }
    } else {
      this.toasterService.error('Invalid value entered. Ensure it is within the valid range.');
    }
  }
  
// Method to check if the value is valid
isInvalidValue(plantIndex: number, prodIndex: number): boolean {
  const production = this.tableData[plantIndex].production[prodIndex];
  const value = production.plannedProduction;
  const validRange = value >= 0 && value <= production.plannedProduction + production.updatedQtyAtRisk;
  return !validRange;
}

// Method to check if all values are valid
isAllValuesValid(): boolean {
  for (let plantIndex = 0; plantIndex < this.tableData.length; plantIndex++) {
    for (let prodIndex = 0; prodIndex < this.tableData[plantIndex].production.length; prodIndex++) {
      if (this.isInvalidValue(plantIndex, prodIndex)) {
        return false;
      }
    }
  }
  return true;
}

  startEditing(plantIndex: number, prodIndex: number): void {
    this.editingCell = { plantIndex, prodIndex };
    const date = this.tableData[plantIndex].production[prodIndex].date;
    this.originalValues[`${plantIndex}-${prodIndex}`] = this.tableData[plantIndex].production[prodIndex].plannedProduction;
  }

  stopEditing(): void {
    this.editingCell = null;
  }

  saveChanges(): void {
    if (!this.allowEdits) {
      this.toasterService.warning('No changes to save.');
      return;
    }

    const username = this.msalService.instance.getActiveAccount().username;
    const payload = {
      user: username,
      results: this.changedRows.map((item: any) => ({
        Planned_Production: item.production.plannedProduction.toString(),
        Production_plant: item.plant,
        Production_Date: item.production.date,
        SKU_at_risk: this.data.materialID
      }))
    };

    this.schedulingDetailsService.submitProductionScheduling(payload).subscribe(
      (response) => {
        this.toasterService.success('Changes saved successfully!');
        this.allowEdits = false;
        this.changedRows = [];
      },
      (error) => {
        this.toasterService.error('Failed to save changes.');
        console.error('API Error:', error);
      }
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach(sub => sub.unsubscribe());
  }
}

export const TOGGLE_BUTTON = {
  ORDERS_AT_RISK: 'Orders at Risk',
  SKU_PLANT_PROJECTIONS: 'SKU DC Projections',
};

export const ORDERS_AT_RISK_DATASOURCE = [
  {
    sales_order_id: 1,
    sales_order_line: 1,
    sku: 'M80029212',
    customer_id: 'cs001',
    projected_OTIF: 7,
  },
];

export const ORDERS_AT_RISK_COLUMNS = [
  'Sales Order ID',
  'Sales Order Line',
  'Sales Order Date',
  'Sales Order Qty',
  'DC ID',
  'SKU',
  'Customer ID',
  'Requested Delivery Date',
  'Base Unit Of Measure',
];

export const ORDERS_AT_RISK_HEADERS = {
  'Sales Order ID': 'Sales Order ID',
  'Sales Order Line': 'Sales Order Line',
  'Sales Order Date': 'Sales Order Date',
  'Sales Order Qty': 'Sales Order Qty',
  'DC ID': 'DC ID',
  SKU: 'SKU',
  'Customer ID': 'Customer ID',
  'Requested Delivery Date': 'Requested Delivery Date',
  'Base Unit Of Measure': 'Base Unit Of Measure',
};

export const SKU_PLANT_PROJECTION_DATASOURCE = [
  {
    sku: 'M80029212',
    location: 'P11',
    day1: 100,
    day2: 150,
    day3: -40,
    day4: -250,
    day14: 120,
  },
  {
    sku: 'M80029212',
    location: 'P19',
    day1: 100,
    day2: 150,
    day3: -40,
    day4: -250,
    day14: 120,
  },
  {
    sku: 'M80029212',
    location: 'P20',
    day1: 100,
    day2: 150,
    day3: -40,
    day4: -250,
    day14: 120,
  },
  {
    sku: 'M80029212',
    location: 'P31',
    day1: 100,
    day2: 150,
    day3: -40,
    day4: -250,
    day14: 120,
  },
];

export const SKU_PLANT_PROJECTION_COLUMNS = [
  'sku',
  'location',
  'day1',
  'day2',
  'day3',
  'day4',
  'day14',
];

export const SKU_PLANT_PROJECTION_HEADERS = {
  sku: 'SKU',
  location: 'Location',
  day1: 'Day 1',
  day2: 'Day 2',
  day3: 'Day 3',
  day4: 'Day 4',
  day14: 'Day 14',
};

export const SKU_PLANT_COLOR_CLASS = {
  LESS_THAN_ZERO: 'less_than_zero',
  GREATER_THAN_ZERO: 'greater_than_zero',
};

export const RDD_LABEL = "Requested Delivery Date";
<div [ngClass]="isStickyHeader ? 'sticky-table-container' : 'table-container'">
  <table mat-table [dataSource]="dataSource" *ngIf="dataSource">
    <!-- Dynamic Column Generation -->
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef>{{ headers[column] }}</th>

      <!-- Apply dynamic background color based on cellColors -->
      <ng-container *ngIf="htmlView != soeViews?.SKU_PROJECTIONS
            && htmlView != soeViews?.WAREHOUSE_UTILIZATION">
        <td mat-cell *matCellDef="let element" [ngStyle]="{
              'background-color': element.cellColors?.[column] || 'inherit',
              'color': getFontColor(element.cellColors?.[column]) || 'inherit'
            }" [ngClass]="{
  'editable': isEditable(column)
}" (click)="setEditable(rowIndex, column)">

          <!-- Check if column is editable and then show input box, else display normal cell -->
          <ng-container
            *ngIf="editableCell.rowIndex === rowIndex && editableCell.column === column && isEditable(column); else nonEditable">
            <input type="number" [(ngModel)]="element[column]"
              (blur)="onTableValueChange({ row: element, column: column })" class="editable-input"
              [attr.placeholder]="element[column]" [disabled]="isInputDisabled(column)" />
          </ng-container>



          <!-- Non-editable cell -->
          <ng-template #nonEditable>
            {{ element[column] }}
          </ng-template>
        </td>
      </ng-container>

      <ng-container *ngIf="htmlView == soeViews?.SKU_PROJECTIONS || htmlView == soeViews?.WAREHOUSE_UTILIZATION">
        <td mat-cell *matCellDef="let element" [ngClass]="getColor(element[column])">
          {{ element[column] }}
        </td>
      </ng-container>
    </ng-container>
    <!-- Header Row -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: isStickyHeader"></tr>
    <!-- Data Rows -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></tr>
  </table>

  <!-- No Record found template start -->
  <div *ngIf="dataSource?.data?.length === 0">
    <table>
      <tr>
        <td class="no-record-data d-flex align-items-center" [attr.colspan]="6">
          No records found for the applied filter!
        </td>
      </tr>
    </table>
  </div>
  <!-- No Record found template end -->

  <!-- Table pagination start -->
  <ng-container *ngIf="showPaginator">
    <mat-paginator [pageSize]="5"></mat-paginator>
  </ng-container>
  <!-- Table pagination end -->
  <!-- Submit Button -->
  <div class="submit-button-container" *ngIf="htmlView == 'PLANT_DETAILS_MODEL'">
    <button (click)="onSubmit()" class="submit-button">
      Submit Changes
    </button>
  </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { OrdersAtRiskOneContainerComponent } from './../../../features/home/components/soe/orders-at-risk/container-components/orders-at-risk-one-container/orders-at-risk-one-container.component';
import { AssistantService } from 'src/app/services/assistant/assistant.service';
import { ProfilePicService } from 'src/app/services/profile-picture/profile-picture.service';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';
import { DailyWhTmsImpactContainerComponent } from './../../../features/home/components/soe/warehouse-truck-utilization/container-components/daily-wh-tms-impact-container/daily-wh-tms-impact-container.component';
import { ProdSchedulingContainerComponent } from './../../../features/home/components/soe/production-scheduling/components/prod-scheduling-container/prod-scheduling-container.component';
import { FilterDataService } from 'src/app/services/filter-data/filter-data.service';

interface Message {
  id: number;
  text: string;
  tableData: TableData;
  displayedColumns?: string[];
  sender: 'user' | 'bot';
  showProgressBar?: boolean;
  isError?: boolean;
  showChart?: boolean;
  showPowerBi?: boolean;
  powerBiData?: PowerBiData;
  showAngularScreen?: boolean;
  angularComponent?: any;
}

export interface TableData {
  headers: string[];
  rows: any[];
}

interface PowerBiData {
  reportId: string;
  embedUrl: string;
  accessToken: string;
  reportSection: string;
}

@Component({
  selector: 'assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss']
})
export class AssistantComponent implements OnInit {
  @Input() apiUrl;
  loggedInUser: string;
  chartDataArray = [];
  messages: Message[] = [];
  userInput: string = '';
  showSummaryCard: boolean = false;
  summaryCardData: any;
  isDisabled: boolean = false;
  chartData: any;
  chartTitle: string;
  xAxisLabel: string;
  yAxisLabel: string;
  chartType: string;
  embedUrl: any;
  reportId: any;
  accessToken: any;
  reportSection: any;
  currentMessageId = 0;
  errorMessage: string;
  canClick: boolean = true;
  profilePicUrl: any;
  msalAccount: any;
  userInitials: any;
  panelOpenState: boolean = false;

  constructor(private assistantService: AssistantService,
    private msalService: MsalService,
    private profilePicService: ProfilePicService,
    private sanitizer: DomSanitizer,
    private filterDataService: FilterDataService) { }

  ngOnInit(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      this.msalAccount = activeAccount;
    }
    this.loggedInUser = this.msalAccount.name.split('.').join(' ');
    this.getProfilePic();
  }

  getProfilePic(): void {
    this.profilePicService.getProfilePic(this.msalAccount.username)
      .subscribe((response) => {
        if (response instanceof Blob && response.size > 0 && response.type === 'image/jpeg') {
          const imageUrl = URL.createObjectURL(response);
          this.profilePicUrl = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
        } else {
          this.userInitials = this.msalAccount.name.split(' ').map(word => word[0]).join('');
        }
      },
        (error: Error) => {
          console.error('Error fetching profile picture:', error.message);
          console.error(error);
        }
      );
  }

  transformHeader(header: string): string {
    return header.replace(/_/g, ' ');
  }

  isTableDataEmpty(tableData: TableData): boolean {
    return !tableData || !tableData.headers || tableData.headers.length === 0 || !tableData.rows || tableData.rows.length === 0;
  }

  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  sendMessage(): void {
    if (this.userInput.trim()) {
      this.currentMessageId++;
      const newMessage: Message = {
        id: this.currentMessageId,
        text: this.userInput,
        tableData: { headers: [], rows: [] },
        sender: 'user',
        showProgressBar: true,
        isError: false,
        showChart: false,
        showPowerBi: false,
        powerBiData: { reportId: '', embedUrl: '', accessToken: '', reportSection: '' },
        showAngularScreen: false,
        angularComponent: null
      };
      this.messages.push(newMessage);
      this.isDisabled = true;
      const payload = {
        "user_message": this.userInput,
        "previous_question": "",
        "previous_answer": ""
      }
      // const payload = {
      //   "user_message": this.userInput,
      //   "previous_question": this.previousQuestion,
      //   "previous_answer": this.previousAnswer
      // }
      // const payload = {
      //   "user_id": this.msalService.instance.getActiveAccount().username,
      //   "chatHistory": chatHistory,
      //   "user_message": this.userInput
      // }
      this.userInput = '';
      this.assistantService.sendMessage(this.apiUrl, payload).subscribe(
        (response) => {
          if (response) {
            this.canClick = true;
            const tableData: TableData = response.tableData;
            const powerBiData: PowerBiData = response.powerBiReports;
            const messageIndex = this.messages.findIndex(msg => msg.id === this.currentMessageId);
            const existingMessageIndex = this.messages.findIndex(msg => msg.sender === 'bot' && msg.tableData && msg.id === this.currentMessageId);
            if (messageIndex !== -1) {
              this.messages[messageIndex].showProgressBar = false;
              if (existingMessageIndex !== -1) {
                this.messages[existingMessageIndex].tableData = tableData;
                this.messages[existingMessageIndex].text = response.text.replace(/\n/g, '<br>');
                this.messages[existingMessageIndex].displayedColumns = tableData.headers;
              } else {
                this.messages.push({
                  id: this.currentMessageId,
                  text: response.text.replace(/\n/g, '<br>'),
                  tableData: tableData,
                  displayedColumns: tableData.headers,
                  sender: 'bot',
                  showProgressBar: false,
                  isError: false,
                  showChart: response.chartData && response.length > 0,
                  showPowerBi: response.powerbi,
                  powerBiData: powerBiData,
                  showAngularScreen: response.angularScreenData?.length > 0 ? true : false,
                  angularComponent: response.angularScreenData?.length > 0
                    ? this.loadComponent(response.angularScreenData[0].component)
                    : null
                });
              }
            } else {
              this.messages.push({
                id: this.currentMessageId++,
                text: response.text.replace(/\n/g, '<br>'),
                tableData: tableData,
                displayedColumns: tableData.headers,
                sender: 'bot',
                showProgressBar: false,
                isError: false,
                showChart: response.chartData && response.length > 0,
                showPowerBi: response.powerbi,
                powerBiData: powerBiData,
                showAngularScreen: response.angularScreenData?.length > 0 ? true : false,
                angularComponent: response.angularScreenData?.length > 0
                  ? this.loadComponent(response.angularScreenData[0].component)
                  : null
              });
            }
            if (response.summaryCard && response.summaryCard.length) {
              this.showSummaryCard = true;
              this.summaryCardData = response.summaryCard;
            }
            if (response.chartData && response.chartData.length && response.powerbi == false) {
              response.chartData.forEach((res: any) => {
                switch (res.type) {
                  case 'Bar Chart':
                    this.chartType = 'bar';
                    break;
                  case 'Line Chart':
                    this.chartType = 'line';
                    break;
                  case 'Column Chart':
                    this.chartType = 'column';
                    break;
                  case 'Area Chart':
                    this.chartType = 'area';
                    break;
                  case 'Pie Chart':
                    this.chartType = 'pie';
                    break;
                  case 'Doughnut Chart':
                    this.chartType = 'doughnut';
                    break;
                  case 'Polar Chart':
                    this.chartType = 'polarArea';
                    break;
                  case 'Radar Chart':
                    this.chartType = 'radar';
                    break;
                  case 'Scatter Chart':
                    this.chartType = 'scatter';
                    break;
                  case 'Bubble Chart':
                    this.chartType = 'bubble';
                    break;
                  case 'Mixed Chart':
                    this.chartType = 'mixed';
                    break;
                  case 'Stacked Bar Chart':
                    this.chartType = 'stackedBar';
                    break;
                  case 'Stacked Line Chart':
                    this.chartType = 'stackedLine';
                    break;
                  case 'Stacked Area Chart':
                    this.chartType = 'stackedArea';
                    break;
                  default:
                    this.chartType = 'line';
                    break;
                }
                this.chartData = {
                  chartTitle: res.title,
                  xAxisLabel: res.xAxisLabel,
                  yAxisLabel: res.yAxisLabel,
                  stacking: res.stacking,
                  chartData: res.data.map((item, i) => {
                    return {
                      type: this.chartType,
                      name: item.name,
                      color: res.colors[i],
                      data: item.series
                    }
                  })
                }
                this.chartDataArray.push(this.chartData);
              })
            }
            this.userInput = '';
            this.isDisabled = false;
            this.chartType = '';
          } else {
            const messageIndex = this.messages.findIndex(msg => msg.id === newMessage.id);
            if (messageIndex !== -1) {
              this.messages[messageIndex].showProgressBar = false;
              this.messages.push({
                id: this.currentMessageId,
                text: `An error occurred: Empty or invalid response.`,
                tableData: { headers: [], rows: [] },
                sender: 'bot',
                showProgressBar: false,
                isError: true,
                showChart: false,
                showPowerBi: false,
                powerBiData: { reportId: '', embedUrl: '', accessToken: '', reportSection: '' },
                showAngularScreen: false,
                angularComponent: null
              });
            }
            this.userInput = '';
            this.isDisabled = false;
            this.chartType = '';
          }
        },
        (error) => {
          const messageIndex = this.messages.findIndex(msg => msg.id === newMessage.id);
          if (messageIndex !== -1) {
            this.messages[messageIndex].showProgressBar = false;
            this.messages.push({
              id: this.currentMessageId,
              text: `An error occurred: ${error}`,
              tableData: { headers: [], rows: [] },
              sender: 'bot',
              showProgressBar: false,
              isError: true,
              showChart: false,
              showPowerBi: false,
              powerBiData: { reportId: '', embedUrl: '', accessToken: '', reportSection: '' },
              showAngularScreen: false,
              angularComponent: null
            });
          }
          this.userInput = '';
          this.isDisabled = false;
          this.chartType = '';
        })
    }
  }

  loadComponent(componentName: string) {
    switch (componentName) {
      case 'OrdersAtRiskOneContainerComponent':
        this.filterDataService.updateFilterData({
          materialID: null,
          skuName: "All",
          dcName: "All",
          customer: "All",
          customerName: null,
          skuCategory: "All",
          customerPriority: "All",
          deliveryDateMin: "2022-10-01T00:00:00.000Z",
          deliveryDateMax: "2022-10-15T00:00:00.000Z",
          rdd: null
        });
        return OrdersAtRiskOneContainerComponent;
      case 'DailyWhTmsImpactContainerComponent':
        this.filterDataService.updateFilterData({
          SKU: "All",
          source: "All",
          destination: "All",
          modeOfTransport: "All",
          deliveryDateMin: "2022-10-01T00:00:00.000Z",
          deliveryDateMax: "2022-10-13T00:00:00.000Z",
          actions: "All",
        });
        return DailyWhTmsImpactContainerComponent;
      case 'ProdSchedulingContainerComponent':
        this.filterDataService.updateFilterData({
          customerId: "All",
          customerName: "All",
          customerPriority: "All",
          dcId: "All",
          dcName: "All",
          deliveryDateMax: "2022-10-15T00:00:00.000Z",
          deliveryDateMin: "2022-10-01T00:00:00.000Z",
          skuCategory: "All",
          skuId: "All",
          skuName: "All",
        });
        return ProdSchedulingContainerComponent;
      default:
        return null;
    }
  }
}

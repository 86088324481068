import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';
import {
  PLANT_ID_LABEL,
  SKU_TABLE_NAME,
  ORDERS_AT_RISK_GRAPH_NAME,
  ORDERS_AT_RISK_CHART_COLORS,
  MIN_X_AXIS_LIMIT,
  Y_LABEL,
  RDD_CHART_NAME,
  ORDERS_AT_RISK_LINE_CHART_NAMES,
  ALL_LABEL,
} from '../../constants/orders-at-risk.constant';
import {
  IOrdersAtRiskFilters,
  IOrdersAtRiskSummaryCard,
  IOrdersAtRiskDataRequestBody,
  IOrdersAtRiskMaterialTableData,
  ISOEChartsUIDetials,
  IOrdersAtRiskDataResponse,
  INameValue,
  IClickedBarDetails,
} from '../../interfaces/orders-at-risk.interface';
import { OrdersAtRiskService } from '../../services/orders-at-risk.service';
import { combineLatest, Subscription } from 'rxjs';
import { FilterDataService } from 'src/app/services/filter-data/filter-data.service';
import { debounceTime } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-orders-at-risk-one-container',
  templateUrl: './orders-at-risk-one-container.component.html',
  styleUrls: ['./orders-at-risk-one-container.component.scss'],
})
export class OrdersAtRiskOneContainerComponent implements OnInit, OnDestroy {
  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  displayLoader: boolean = true;
  selectedToggle: boolean = true;
  asISOrRecommendationToggle: boolean = true;
  isFiltersAvailable: boolean = false;
  isApiError: boolean = false;
  showDetailsBtn: boolean = false;
  ordersAtRiskFilters: IOrdersAtRiskFilters;
  ordersAtRiskSummaryCardData: IOrdersAtRiskSummaryCard[];
  selectedFilters: IOrdersAtRiskDataRequestBody;
  projectedOTIFBySKUData: IOrdersAtRiskMaterialTableData[];
  projectedOTIFByCustomerResponse: ISOEChartsUIDetials;
  projectedOTIFByRDDResponse: ISOEChartsUIDetials;
  projectedOTIFByLocationResponse: ISOEChartsUIDetials;
  chartData: ISOEChartsUIDetials[] = [];
  plantIdLabel = PLANT_ID_LABEL;
  skuTableLabel = SKU_TABLE_NAME.QUANTITY;
  graphName = ORDERS_AT_RISK_GRAPH_NAME;
  chartColorList = ORDERS_AT_RISK_CHART_COLORS;
  minimumXAxisLimit = MIN_X_AXIS_LIMIT;
  rddChartName = RDD_CHART_NAME;
  yLabelList = Y_LABEL;
  yAxisLabel: string = this.yLabelList.QUANTITY;
  selectedYear: number;
  selectedSKUCategory: string;
  selectedCustomerImportance: string;
  selectedPlantId: string;
  selectedMaterialID: string = null;
  selectedCustomerName: string = null;
  selectedRdd: Date = null;
  private subscriptions$: Array<Subscription> = new Array<Subscription>();
  ordersAtRiskDataSubscription: Subscription;
  lineChartName = ORDERS_AT_RISK_LINE_CHART_NAMES;
  allLabel = ALL_LABEL;
  private readonly SOE_FILTER_RESET_STATE = 'soeFilterResetState';
  constructor(
    private ordersAtRiskService: OrdersAtRiskService,
    private readonly toasterService: ToasterWrapperService,
    private filterDataService: FilterDataService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.getOrdersAtRiskFilter();
  }

  getOrdersAtRiskFilter() {
    this.ordersAtRiskDataSubscription = combineLatest([
      this.filterDataService.currentFilterData.pipe(debounceTime(300)),
      this.breadcrumbService.getToggleState.pipe(debounceTime(300)),
      this.breadcrumbService.getAsIsOrRecommendationToggle.pipe(
        debounceTime(300)
      ),
    ]).subscribe(([filterData, toggleState, asISOrRecommendationToggle]) => {
      if (filterData != null) {
        const filterResetSate =
          localStorage.getItem(this.SOE_FILTER_RESET_STATE) == 'true'
            ? true
            : false;
        if (filterResetSate) {
          this.selectedMaterialID = this.allLabel;
          this.selectedCustomerName = this.allLabel;
          this.selectedPlantId = this.allLabel;
          this.selectedRdd = null;
          this.showDetailsBtn = false;
          this.selectedFilters = {
            materialID: this.selectedMaterialID,
            skuName: filterData.skuName,
            plantID: this.selectedPlantId,
            dcName: filterData.dcName,
            customer: filterData.customerId,
            customerName: this.selectedCustomerName,
            skuCategory: filterData.skuCategory,
            customerPriority: filterData.customerPriority,
            deliveryDateMin: filterData.deliveryDateMin,
            deliveryDateMax: filterData.deliveryDateMax,
            rdd: this.selectedRdd,
          };
        } else {
          this.selectedFilters = {
            materialID:
              this.selectedMaterialID != this.allLabel
                ? this.selectedMaterialID
                : filterData.skuId,
            skuName: filterData.skuName,
            plantID:
              this.selectedPlantId != this.allLabel
                ? this.selectedPlantId
                : filterData.dcId,
            dcName: filterData.dcName,
            customer: filterData.customerId,
            customerName:
              this.selectedCustomerName != this.allLabel
                ? this.selectedCustomerName
                : filterData.customerName,
            skuCategory: filterData.skuCategory,
            customerPriority: filterData.customerPriority,
            deliveryDateMin: filterData.deliveryDateMin,
            deliveryDateMax: filterData.deliveryDateMax,
            rdd: this.selectedRdd,
          };
          this.showDetailsBtn =
            this.selectedFilters?.materialID != this.allLabel &&
            this.selectedMaterialID != null
              ? true
              : false;
        }
        this.selectedYear = new Date(filterData?.deliveryDateMin).getFullYear();
        this.selectedToggle = toggleState;
        this.yAxisLabel = this.selectedToggle
          ? this.yLabelList.QUANTITY
          : this.yLabelList.VALUE;
        this.skuTableLabel = this.selectedToggle
          ? SKU_TABLE_NAME.QUANTITY
          : SKU_TABLE_NAME.VALUE;
        this.asISOrRecommendationToggle = asISOrRecommendationToggle;
        this.loadOrdersAtRiskData(
          this.selectedFilters,
          this.selectedToggle,
          this.asISOrRecommendationToggle
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.selectedFilters = null;
    this.selectedSKUCategory = null;
    this.selectedCustomerImportance = null;
    this.selectedPlantId = null;
    this.selectedMaterialID = null;
    this.selectedCustomerName = null;
    this.selectedRdd = null;
    this.chartData = [];
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe();
    });
    if (this.ordersAtRiskDataSubscription) {
      this.ordersAtRiskDataSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {}

  loadOrdersAtRiskData(
    selectedFilters: IOrdersAtRiskDataRequestBody,
    selectedToggle: boolean,
    asISOrRecommendationToggle: boolean
  ): void {
    this.displayLoader = true;
    this.subscriptions$.push(
      this.ordersAtRiskService
        .getOrdersAtRiskAllData(
          selectedFilters,
          selectedToggle,
          asISOrRecommendationToggle
        )
        .subscribe(
          (data: IOrdersAtRiskDataResponse) => {
            this.ordersAtRiskSummaryCardData = data?.summaryCard?.data;
            this.projectedOTIFBySKUData = data?.materialTable;
            const soeChartsData: any = data?.soeCharts?.data;
            this.chartData = [];
            soeChartsData.map((data) => {
              const isRDDChartData =
                data.name == this.rddChartName.QUANTITY ||
                data.name == this.rddChartName.VALUE
                  ? true
                  : false;
              let singleChartData = {
                title: data?.name,
                xAxisLabel: data?.xAxisLabel,
                yAxisLabel: data.yAxisLabel,
                chartData: '',
                xAxisScrollable: false,
              };

              if (data.lineChartData) {
                const xAxisScrollable =
                  data.lineChartData[0].series?.length > this.minimumXAxisLimit
                    ? true
                    : false;
                singleChartData = {
                  ...singleChartData,
                  xAxisScrollable: xAxisScrollable,
                };

                singleChartData.chartData = data.lineChartData.map(
                  (el: any, i: number) => {
                    return {
                      type: this.lineChartName.includes(el.name)
                        ? 'line'
                        : 'column',
                      name: el.name,
                      color: this.chartColorList[i],
                      yAxis: this.lineChartName.includes(el.name) ? 1 : 0,
                      data: el.series.map((el: INameValue) => {
                        {
                          return isRDDChartData
                            ? [
                                new Date(el.name).toLocaleDateString('en-US', {
                                  month: 'short',
                                  day: 'numeric',
                                }),
                                el.value,
                              ]
                            : [el.name, el.value];
                        }
                      }),
                    };
                  }
                );
              }
              this.chartData.push(singleChartData);
            });

            // Data to plot Projected OTIF by Customer
            this.projectedOTIFByCustomerResponse = this.chartData[0];
            // Data to plot Projected OTIF by RDD
            this.projectedOTIFByRDDResponse = this.chartData[1];
            // Data to plot Projected OTIF by Location
            this.projectedOTIFByLocationResponse = this.chartData[2];

            this.displayLoader = false;
            this.isApiError = false;
          },
          (error) => {
            // Handle errors
            console.error('Error fetching data:', error);
            this.toasterService.error(error?.error);
            this.isApiError = true;
            // Stop the loader even in case of errors
            this.displayLoader = false;
          }
        )
    );
  }

  getSelectedGraphFilter(data: IClickedBarDetails) {
    switch (data?.graphName) {
      case this.graphName.OTIF_BY_CUSTOMER: {
        this.selectedCustomerName = data?.xAxisLabel;
        this.selectedFilters = {
          ...this.selectedFilters,
          customerName: this.selectedCustomerName,
        };
        break;
      }
      case this.graphName.OTIF_BY_RDD: {
        this.selectedRdd = this.formatRequiredDeliveryDate(data?.xAxisLabel);
        this.selectedFilters = {
          ...this.selectedFilters,
          rdd: this.selectedRdd,
        };
        break;
      }
      case this.graphName.OTIF_BY_LOCATION: {
        this.selectedPlantId = data?.xAxisLabel;
        this.selectedFilters = {
          ...this.selectedFilters,
          plantID: this.selectedPlantId,
        };
        break;
      }
      default: {
        this.selectedMaterialID = data?.xAxisLabel;
        this.showDetailsBtn = true;
        this.selectedFilters = {
          ...this.selectedFilters,
          materialID: this.selectedMaterialID,
        };
        break;
      }
    }
    this.loadOrdersAtRiskData(
      this.selectedFilters,
      this.selectedToggle,
      this.asISOrRecommendationToggle
    );
  }

  formatRequiredDeliveryDate(rdd: string) {
    const rddDate = new Date(rdd.concat(' ', this.selectedYear.toString()));
    const year = rddDate.getFullYear();
    const month = String(rddDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(rddDate.getDate()).padStart(2, '0');
    return new Date(`${year}-${month}-${day}`);
  }
}

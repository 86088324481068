import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IClickedBarDetails,
  IModalApiParam,
  INameValue,
  IOrdersAtRiskDataRequestBody,
  IOrdersAtRiskMaterialTableData,
  IOrdersAtRiskSummaryCard,
  ISOEChartsUIDetials,
} from '../../interfaces/orders-at-risk.interface';
import {
  ORDERS_AT_RISK_GRAPH_NAME,
  PLANT_ID_LABEL,
} from '../../constants/orders-at-risk.constant';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { DetailsModalComponent } from '../details-modal/details-modal.component';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';

@Component({
  selector: 'app-orders-at-risk',
  templateUrl: './orders-at-risk.component.html',
  styleUrls: ['./orders-at-risk.component.scss'],
})
export class OrdersAtRiskComponent implements OnInit {
  @Input() ordersAtRiskSummaryCardData: IOrdersAtRiskSummaryCard[];
  @Input() projectedOTIFBySKUData: IOrdersAtRiskMaterialTableData[];
  @Input() projectedOTIFByCustomerResponse: ISOEChartsUIDetials;
  @Input() projectedOTIFByRDDResponse: ISOEChartsUIDetials;
  @Input() projectedOTIFByLocationResponse: ISOEChartsUIDetials;
  @Input() skuTableLabel: string;
  @Input() yAxisLabel: string;
  @Input() selectedToggle: boolean = true;
  @Input() availablePlantIdList: INameValue[];
  @Input() selectedPlantId: string;
  @Input() showDetailsBtn: boolean = false;
  @Input() selectedFilters: IOrdersAtRiskDataRequestBody;
  @Input() asISOrRecommendationToggle: boolean = true;

  @Output() emitSelectedGraphFilter = new EventEmitter<object>();

  plantIdLabel = PLANT_ID_LABEL;
  graphName = ORDERS_AT_RISK_GRAPH_NAME;

  modalData: any;
  modalApiParam: IModalApiParam;
  loadOrdersAtRiskView: boolean = false;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.hasOwnProperty('ordersAtRiskSummaryCardData') &&
        this.ordersAtRiskSummaryCardData) ||
      (changes.hasOwnProperty('projectedOTIFBySKUData') &&
        this.projectedOTIFBySKUData) ||
      (changes.hasOwnProperty('projectedOTIFByCustomerResponse') &&
        this.projectedOTIFByCustomerResponse) ||
      (changes.hasOwnProperty('projectedOTIFByRDDResponse') &&
        this.projectedOTIFByRDDResponse) ||
      (changes.hasOwnProperty('projectedOTIFByLocationResponse') &&
        this.projectedOTIFByLocationResponse) ||
      (changes.hasOwnProperty('yAxisLabel') && this.yAxisLabel) ||
      (changes.hasOwnProperty('selectedToggle') && this.selectedToggle) ||
      (changes.hasOwnProperty('availablePlantIdList') &&
        this.availablePlantIdList) ||
      (changes.hasOwnProperty('showDetailsBtn') && this.showDetailsBtn)
    ) {
      this.loadOrdersAtRiskView = true;
    }
  }

  /**
   * function to get colors.
   * @param index card number as index
   * @returns color
   */
  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  /**
   * function to get selected plant id
   * @param event selected plant id
   */
  onPlantIdDropdownChange(event: MatSelectChange): void {
    this.selectedPlantId = event?.value;
    const dataToEmit: IClickedBarDetails = {
      graphName: this.graphName.OTIF_BY_LOCATION,
      xAxisLabel: this.selectedPlantId,
    };
    this.getClickedBarDetails(dataToEmit);
  }

  getSelectedCustomerId(materialId: string): void {
    const dataToEmit: IClickedBarDetails = {
      graphName: this.graphName.OTIF_BY_SKU,
      xAxisLabel: materialId,
    };
    this.getClickedBarDetails(dataToEmit);
  }

  getClickedBarDetails(dataToEmit: IClickedBarDetails) {
    this.emitSelectedGraphFilter.emit(dataToEmit);
  }

  openDialog() {
    const dialogRef = this.dialog.open(DetailsModalComponent, {
      width: '100%',
      //maxWidth: '400px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '1000px',
      panelClass: 'sku-details-dialog',
      disableClose: true,
      data: {
        filter: {
          value: this.selectedFilters,
          asISOrRecommendationToggle: this.asISOrRecommendationToggle,
        },
      },
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
}

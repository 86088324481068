<div *ngIf="breadcrumbs.length > 0" class="breadcrumb-wrapper">
    <nav class="breadcrumb-container">
        <ol class="breadcrumb">
            <li *ngFor="let breadcrumb of breadcrumbs; let last = last"
                [ngClass]="{ 'breadcrumb-item': true, 'active': last }">
                <a class="breadcrumb-lbl" *ngIf="!last"
                    [routerLink]="breadcrumb.routerLink ? breadcrumb.routerLink : null">
                    {{ breadcrumb.label }}
                </a>
                <span class="breadcrumb-lbl last-breadcrumb" *ngIf="last"
                    [routerLink]="breadcrumb.routerLink ? breadcrumb.routerLink : null"
                    routerLinkActive="active-route">
                    {{ breadcrumb.label }}</span>
            </li>
        </ol>
    </nav>
    <div class="actions-container">
        <div class="left-actions">
            <button class="redeployment-btn" mat-raised-button [routerLink]="['/home/soe/redeployment-approval-impact']"
                routerLinkActive="active-route" *ngIf="isApprovalsButtonVisible">
                Redeployment Approval
            </button>
            <button class="production-btn" mat-raised-button [routerLink]="['/home/soe/production-approval']"
            routerLinkActive="active-route" *ngIf="isApprovalsButtonVisible">
                Production Approval
            </button>
        </div>
        <div class="right-actions">
            <mat-button-toggle-group *ngIf="showQuantityValueToggle" class="toggle-btn"
                (change)="getSelectedToggle($event)">
                <mat-button-toggle value="true" [checked]="true"><span class="label">Quantity</span></mat-button-toggle>
                <mat-button-toggle value="false"><span class="label">Value</span></mat-button-toggle>
            </mat-button-toggle-group>

            <mat-button-toggle-group *ngIf="showAsIsOrRecommendationToggle" class="toggle-btn"
                (change)="getAsIsOrRecommendationToggle($event)">
                <mat-button-toggle value="true" [checked]="true"><span class="label">As is</span></mat-button-toggle>
                <mat-button-toggle value="false"><span class="label">With accepted
                        recommendations</span></mat-button-toggle>
            </mat-button-toggle-group>

            <button class="filter-btn" mat-raised-button (click)="toggleRightSidebar()" *ngIf="isButtonVisible">
                <mat-icon class="filter-icon" matTooltip="Open Filter">filter_alt</mat-icon>
            </button>

            <div class="documents-link" *ngIf="isVisibleDocLink">
                <a class="document-link" (click)="openDocuments()">
                    Documents
                </a>
            </div>
        </div>
    </div>
    <!-- <div class="approval-btns">
        <button mat-raised-button>
            Redeployment Approval
        </button>
        <button mat-raised-button>
            Production Approval
        </button>
    </div>
    <mat-button-toggle-group *ngIf="showQuantityValueToggle" class="toggle-btn" (change)="getSelectedToggle($event)">
        <mat-button-toggle value="true" [checked]="true">
            <span class="label">Quantity</span>
        </mat-button-toggle>
        <mat-button-toggle value="false">
            <span class="label">Value</span>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group *ngIf="showAsIsOrRecommendationToggle" class="toggle-btn"
        (change)="getAsIsOrRecommendationToggle($event)">
        <mat-button-toggle value="true" [checked]="true">
            <span class="label">As is</span>
        </mat-button-toggle>
        <mat-button-toggle value="false">
            <span class="label">With accepted recommendations</span>
        </mat-button-toggle>
    </mat-button-toggle-group>
    <button class="filter-btn" mat-raised-button (click)="toggleRightSidebar()" *ngIf="isButtonVisible">
        <mat-icon class="filter-icon" matTooltip="Open Filter">filter_alt</mat-icon>
    </button> -->
</div>
<div class="main-container">
  <div *ngIf="displayLoader" class="spinner-overlay">
    <app-loader [isLoading]="displayLoader" [message]="loadingMessage"></app-loader>
  </div>
  <div *ngIf="isApiError" class="spinner-overlay">
    <app-loader [isLoading]="isApiError" [message]="apiErrorMessage"></app-loader>
  </div>
  <div class="orders-at-risk-container" *ngIf="!displayLoader && !isApiError">
    <app-orders-at-risk [ordersAtRiskSummaryCardData]="ordersAtRiskSummaryCardData"
      [projectedOTIFBySKUData]="projectedOTIFBySKUData"
      [projectedOTIFByCustomerResponse]="projectedOTIFByCustomerResponse"
      [projectedOTIFByRDDResponse]="projectedOTIFByRDDResponse"
      [projectedOTIFByLocationResponse]="projectedOTIFByLocationResponse" [skuTableLabel]="skuTableLabel"
      [yAxisLabel]="yAxisLabel" [selectedToggle]="selectedToggle" [availablePlantIdList]="availablePlantIdList"
      [selectedPlantId]="selectedPlantId" [showDetailsBtn]="showDetailsBtn" [selectedFilters]="selectedFilters"
      [asISOrRecommendationToggle]="asISOrRecommendationToggle"
      (emitSelectedGraphFilter)="getSelectedGraphFilter($event)"></app-orders-at-risk>
  </div>
</div>
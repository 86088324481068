import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IClickedBarDetails,
  IQtyRiskDetailsTableModel,
} from '../../interfaces/scheduling-interface';

@Component({
  selector: 'app-expandable-table',
  templateUrl: './expandable-table.component.html',
  styleUrls: ['./expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ExpandableTableComponent implements OnInit {
  @Input() dataSource: IQtyRiskDetailsTableModel[];
  @Input() displayedColumns: string[];
  @Input() graphName: string;
  @Output() rowClicked = new EventEmitter<any>();
  expandedElement: IQtyRiskDetailsTableModel | null;
  innerDisplayedColumns = [
    'SKU',
    'Qty at Risk',
    'Requested Delivery Date',
    'Next Production Batch',
    'Plant ID',
    'Available Production Capacity'
  ];

  ngOnInit(): void {}

  onRowClick(row: any) {
    const dataToEmit: IClickedBarDetails = {
      graphName: this.graphName,
      xAxisLabel: row['SKU'],
    };
    this.rowClicked.emit(dataToEmit);
  }

  getFormattedData(element: any, columnName: string) {
    if (columnName == 'Qty at Risk' || columnName == 'Next Production Batch') {
      return Number(element[columnName]).toLocaleString();
    } else return element[columnName];
  }
}

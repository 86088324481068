export const VISIBLE_FILTERPANE_ROUTES = [
  '/home/soe/orders-at-risk',
  '/home/soe/weekly-truck-availability',
  '/home/soe/projected-shortages',
  '/home/soe/inventory-health',
  '/home/soe/redeployment-approval-impact',
  '/home/soe/weekly-warehouse-utilization',
  '/home/soe/production-scheduling', ,
  '/home/soe/forecast-consumption',
  '/home/forecast-review',
  '/home/output-template',
  '/home/executive-view',
  '/home/soe/production-attainment'
];

export const SHOW_QUANTITY_VALUE_TOGGLE_ROUTES = ['/home/soe/orders-at-risk'];

export const SHOW_ASIS_OR_RECOMMENDATION_TOGGLE_ROUTES = [
  '/home/soe/orders-at-risk',
  '/home/soe/redeployment-approval-impact',
];

export const SHOW_DAILY_BUTTON_ROUTES = [
  '/home/soe/orders-at-risk',
  '/home/soe/redeployment-approval-impact',
  '/home/soe/soe-monitor',
  '/home/soe/production-scheduling',
];

export const SHOW_WEEKLY_BUTTON_ROUTES = [
  '/home/soe/projected-shortages',
  '/home/soe/inventory-health',
  '/home/soe/weekly-warehouse-utilization',
  '/home/soe/weekly-truck-availability',
  '/home/soe/forecast-consumption',
];

export const RECOMMENDATION_TAB_NAME = 'Recommendation';

export const SOE_ROUTES = {
  ORDERS_AT_RISK: '/home/soe/orders-at-risk',
  REDEPLOYMENT_APPROVAL_IMPACT_ROUTE: '/home/soe/redeployment-approval-impact',
};


export const SHOW_EXECUTIVE_VIEW_BUTTON_ROUTES = [
  '/home/forecast-review',
  '/home/executive-view',
  '/home/output-template'
];

export const SHOW_DOC_LINK_BUTTON_ROUTES = [
  '/home/tms-assistant',
  '/home/soe/soe-chatbot'
];

export const SHOW_RESILIENCY_ALERT_BTN_ROUTES = [
  '/home/alerts-details'
];
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/constants/api.urls';
import {
  IWMSDataRequestBody,
  IWMSDataResponse,
  IWMSFilterList,
  IWMSWeeklyDataRequestBody,
  IWMSWeeklyFilterList,
} from '../interfaces/warehouse-utilization.interface';

@Injectable({
  providedIn: 'root',
})
export class WarehouseUtilizationService {
  constructor(private http: HttpClient) {}

  getDailyWMSFilters(): Observable<IWMSFilterList> {
    return this.http.get<IWMSFilterList>(API_URLS.getDailyWMSFilters);
  }

  getWeeklyWMSFilters(): Observable<IWMSWeeklyFilterList> {
    return this.http.get<IWMSWeeklyFilterList>(API_URLS.getDailyWMSFilters);
  }

  getWMSAllData(
    selectedFilters: IWMSDataRequestBody,
    selectedToggle: boolean
  ): Observable<IWMSDataResponse> {
    const params = new HttpParams().set('toggle', selectedToggle);
    return this.http.post<IWMSDataResponse>(
      API_URLS.getWMSAllData,
      selectedFilters,
      {
        params: params,
      }
    );
  }

  getIOShipmentData(
    selectedFilters: IWMSDataRequestBody,
    selectedToggle: boolean
  ): Observable<any> {
    const params = new HttpParams().set('toggle', selectedToggle);
    return this.http.post<any>(API_URLS.getIOShipmentData, selectedFilters, {
      params: params,
    });
  }

  getWeeklyWMSData(
    selectedFilters: IWMSWeeklyDataRequestBody
  ): Observable<IWMSDataResponse> {
    return this.http.post<IWMSDataResponse>(
      API_URLS.getWeeklyWMSData,
      selectedFilters
    );
  }
  
  getWMSPopupData(
    selectedFilters: IWMSWeeklyDataRequestBody
  ): Observable<any> {
    return this.http.post<any>(API_URLS.getWMSPopupData, selectedFilters);
  }

  getWeeklyIOShipmentData(
    selectedFilters: IWMSWeeklyDataRequestBody,
  ): Observable<any> {
        return this.http.post<any>(API_URLS.getIOShipmentData, selectedFilters, {
          });
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IO_Y_AXIS_LABEL,
  UTILIZATION_COLOR_CLASS,
  UTILIZATION_COLORS,
  WAREHOUSE_UTILIZATION_GRAPH_NAME,
} from '../../constants/warehouse-utilization.constant';
import {
  IModalTable,
  IWMSSummaryCard,
} from '../../interfaces/warehouse-utilization.interface';
import { SOE_VIEWS } from '../../../constants/soe.constants';
import { IClickedBarDetails } from '../../../orders-at-risk/interfaces/orders-at-risk.interface';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';

@Component({
  selector: 'app-warehouse-utilization',
  templateUrl: './warehouse-utilization.component.html',
  styleUrls: ['./warehouse-utilization.component.scss'],
})
export class WarehouseUtilizationComponent implements OnInit, OnChanges {
  @Input() wmsSummaryCardData: IWMSSummaryCard[];
  @Input() utilizedDCCapacityChartData: any; //
  @Input() spaceUtilizationData: IWMSSummaryCard[];
  @Input() forecastedDCUtilizationTableData: IModalTable;
  @Input() ioShipmentChartData: any = null; // check model
  @Input() showIOShipmentChart: boolean = false;
  @Input() showIOShipmentChartApiError: boolean = false;
  @Input() isWeekly: boolean;
  @Input() selectedDC: string;
  utilizationColors = UTILIZATION_COLORS;
  viewPage: string = SOE_VIEWS.WAREHOUSE_UTILIZATION;
  utilizationTableColorClassList = UTILIZATION_COLOR_CLASS;
  graphName = WAREHOUSE_UTILIZATION_GRAPH_NAME;
  yAxisLabel = IO_Y_AXIS_LABEL;
  yAxisMin: number = 0;
  yAxisMax: number = 100000;
  yAxisMaxWeekly: number = 50000;
  previousSelectedDc: string;
  displayIOShipmentLoader: boolean = false;

  @Output() emitSelectedDCEvent = new EventEmitter<string>();
  @Output() emitUtilizationTableRowClickEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.hasOwnProperty('wmsSummaryCardData') &&
        this.wmsSummaryCardData) ||
      (changes.hasOwnProperty('utilizedDCCapacityChartData') &&
        this.utilizedDCCapacityChartData) ||
      (changes.hasOwnProperty('spaceUtilizationData') &&
        this.spaceUtilizationData) ||
      (changes.hasOwnProperty('forecastedDCUtilizationTableData') &&
        this.forecastedDCUtilizationTableData) ||
      (changes.hasOwnProperty('ioShipmentChartData') &&
        this.ioShipmentChartData)
    ) {
      this.displayIOShipmentLoader =
        this.ioShipmentChartData == null && this.showIOShipmentChart
          ? true
          : false;
    }
  }

  /**
   * function to get colors.
   * @param index card number as index
   * @returns color
   */
  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  getClickedBarDetails(event: IClickedBarDetails) {
    this.ioShipmentChartData = null;
    this.displayIOShipmentLoader = true;
    this.emitSelectedDCEvent.emit(event.xAxisLabel);
  }

  closeIOShipmentChart(): void {
    this.showIOShipmentChart = false;
    this.ioShipmentChartData = null;
  }

  getSelectedDc(data: any) {
    this.closeIOShipmentChart();
    const dc = data['Plant Id'];
    this.emitUtilizationTableRowClickEvent.emit(dc);
  }
}

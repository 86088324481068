<div class="table-container" [style.height.px]="customHeight">
    <!-- search bar start -->
    <div class="table-actionbar" *ngIf="showSearch">
        <div class="search-box">
            <mat-form-field class="search-field">
                <mat-label class="search-label">Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" class="search-placeholder" #input />
            </mat-form-field>
        </div>
    </div>
    <!-- search bar end -->

    <!-- Projected OTIF by SKU table start -->

    <table mat-table #table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort
        class="custom-table">

        <!-- Material ID Column -->
        <ng-container matColumnDef="Material_ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
            <td mat-cell *matCellDef="let element; let i=index"> {{element.Material_ID}}</td>
        </ng-container>

        <!-- Forecast Qty Column -->
        <ng-container matColumnDef="Forecast Qty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Forecast {{headerSuffix}} </th>
            <td mat-cell *matCellDef="let element"> {{element['Forecast Qty'] | number: '1.0-0'}} </td>
        </ng-container>

        <!-- Sales Order Qty Column -->
        <ng-container matColumnDef="Sales Order Qty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sales Order {{headerSuffix}} </th>
            <td mat-cell *matCellDef="let element"> {{element['Sales Order Qty'] | number: '1.0-0' }} </td>
        </ng-container>

        <!-- Incoming Inventory Qty Column -->
        <ng-container matColumnDef="Incoming Shipments Qty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Incoming Inv {{headerSuffix}} </th>
            <td mat-cell *matCellDef="let element"> {{element['Incoming Shipments Qty'] | number: '1.0-0' }} </td>
        </ng-container>

        <!-- Inventory Qty Column -->
        <ng-container matColumnDef="Inventory Qty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Inventory {{headerSuffix}} </th>
            <td mat-cell *matCellDef="let element"> {{element['Inventory Qty'] | number: '1.0-0' }} </td>
        </ng-container>

        <!-- Projected OTIF Column -->
        <ng-container matColumnDef="Projected Otif">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Projected OTIF </th>
            <td mat-cell *matCellDef="let element">
                <div class="otif-progress-group d-flex align-items-center">
                    <div class="otif-progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.px]="element['Projected Otif']==0 ? 0 : (element['Projected Otif'])/2">
                        </div>
                    </div>
                    <div>{{element['Projected Otif']}}%</div>
                </div>
            </td>
        </ng-container>

        <!-- Projected Fill Rate Column -->
        <ng-container matColumnDef="Projected Fill Rate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Projected Fill Rate </th>
            <td mat-cell *matCellDef="let element">
                <div class="otif-progress-group d-flex align-items-center">
                    <div class="fill_rate">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.px]="element['Projected Fill Rate'] == 0 ? 0 : (element['Projected Fill Rate'])/2">
                        </div>
                    </div>
                    <div>{{element['Projected Fill Rate']}}%</div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></tr>
    </table>
    <!-- Projected OTIF by SKU table end -->

    <!-- No Record found template start -->
    <div *ngIf="!dataSource?.data.length || !dataSource?.filteredData.length">
        <table>
            <tr *ngIf="!dataSource?.data.length || !dataSource?.filteredData.length">
                <td class="no-record-data" [attr.colspan]="6">No Records Found!</td>
            </tr>
        </table>
    </div>
    <!-- No Record found template end -->

    <!-- table pagination start -->
    <ng-container *ngIf="showPagination">
        <mat-paginator [pageSize]="defaultPageSize" aria-label="Select page" [pageSizeOptions]="paginationSize"
            showFirstLastButtons></mat-paginator>
    </ng-container>
    <!-- table pagination end -->
</div>
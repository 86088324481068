// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    //production: true, //if ng build --prod
    msalConfig: {
        clientId: '0f526d43-0616-4d2b-a649-6a2b01d65363',
        tenantId: '5b973f99-77df-4beb-b27d-aa0c70b8482c',
        redirectUri: "https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/",
        //redirectUri: "http://localhost:4200/",
        consentScopes: ['user.read', 'openid', 'profile']
    },
    signalRUrl: 'https://euwdsrg02rafapp02.azurewebsites.net/',
    apiUrl: 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/',
    // Modify the ADT URL
    digitalTwinUrl: "https://explorer.digitaltwins.azure.net/?tid=5b973f99-77df-4beb-b27d-aa0c70b8482c&eid=EUWDSRG03RADT01.api.weu.digitaltwins.azure.net",
    microservicesBaseUrl: "https://sphereiot-dev-fa.azurewebsites.net/api/device/",
    operationAutoRefreshPeriodInSeconds: 5,
    tsiRealTimeFeedAutoRefreshPeriodInSeconds: 10,
    serviceTicketsAutoRefreshPeriodInSeconds: 15,
    azureMapAutoRefreshPeriodInSeconds: 10,
    evOperationAutoRefreshPeriodInSeconds: 10,
    opcSimulatorStatusCheckPeriodInSeconds: 10,
    GetAssetsURL: 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/Simulator/GetAllAssetList',
    GetAssetTypesURL: 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/Simulator/GetAssetTypes',
    SaveAssetDetailsURL: 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/Simulator/Save/AssetDetails',
    SaveProcessConfigurationURL: 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/Simulator/Save/ProcessConfiguration',
    SaveSensorConfigurationURL: 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/Simulator/Save/SensorConfiguration',
    SubmitAssetCreationURL: 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/Simulator/SubmitAssetCreation'
};
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GRAPH_COLORS, SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';

@Component({
  selector: 'app-truck-availability',
  templateUrl: './truck-availability.component.html',
  styleUrls: ['./truck-availability.component.scss'],
})
export class TruckAvailabilityComponent implements OnInit {
  @Input() tblTitles: string[];
  isLoading: boolean = false;
  apiDataLoaded: boolean = false;
  summaryCardData: any;
  truckPlantSeries: any;
  lTLCostSeries: any;
  capacityPlannedbyWeekTbl: any[];
  capacityPlannedbyLaneWeekTbl: any[];
  capacityPlannedbyWeekColumns: string[];
  capacityPlannedbyWeekHeaders: { [key: string]: string; };
  capacityPlannedbyLaneWeekColumns: string[];
  capacityPlannedbyLaneWeekHeaders: { [key: string]: string; };
  xAxisScrollable: boolean;
  minimumXAxisLimit = 14;
  truckLaneSeries: any;
  @Input() apiData: any;
  @Output() rowClicked = new EventEmitter<any>();
  @Output() chartClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.getWeeklyTMSData(this.apiData);
  }

  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  handleRowClick(row: any): void {
    this.rowClicked.emit(row);
  }

  transformData(inputData: any[], dynamicKey?: string): any[] {
    const singleObj = inputData[0]; // take single object from array
    // Extract date keys (exclude dynamicKey only if it exists)
    const dateKeys = Object.keys(singleObj).filter(key => dynamicKey ? key !== dynamicKey : true);
    return inputData.map((item, index) => {      
      // Create cellColors object with conditional colors based on values
      const cellColors = dateKeys.reduce((acc, key) => {
        const value = parseInt(item[key], 10);  // Convert value to a number
        let color: string;
        // Apply color conditions
        if (value <= 50) {
          color = 'yellow';
        } else if (value > 50 && value < 100) {
          color = 'green';
        } else if (value >= 100) {
          color = 'red';
        }
        acc[key] = color;  // Set the color for each date field
        return acc;
      }, {});
      // Return the transformed object with id, cellColors, and original data
      return {
        id: index + 1,
        cellColors: cellColors,
        ...(dynamicKey ? { [dynamicKey]: item[dynamicKey] } : {}),  // Dynamically assign the key if present
        ...dateKeys.reduce((acc, key) => {
          acc[key] = parseInt(item[key], 10) + '%';;  // Convert string to number for each date field
          return acc;
        }, {})
      };
    });
  }

  formatNumber(currentYear: number): string {
    if (currentYear >= 1_000_000) {
        return (currentYear / 1_000_000).toFixed(1) + 'M';
    } else if (currentYear >= 1_000) {
        return (currentYear / 1_000).toFixed(1) + 'K';
    }
    return currentYear.toString();
}

  getWeeklyTMSData(data) {
    if (data) {
      this.summaryCardData = data?.summaryCard?.data;
      this.truckPlantSeries = data?.tmsCharts?.data[0].lineChartData.map((el: any, i: number) => {
        return {
          type: 'bar',
          name: el.name,
          color: GRAPH_COLORS[i % GRAPH_COLORS.length],
          data: el.series.map((ele) => {
            return {
              name: ele.name, y: ele.value, drilldown: ele.name
            }
          }),
        };
      });
      this.xAxisScrollable =
        data?.tmsCharts?.data[1].lineChartData[0].series?.length >
          this.minimumXAxisLimit
          ? true
          : false;
      this.truckLaneSeries = data?.tmsCharts?.data[1].lineChartData.map((el: any, i: number) => {
        return {
          type: 'bar',
          name: el.name,
          color: GRAPH_COLORS[i % GRAPH_COLORS.length],
          turboThreshold: 2000,
          data: el.series.map((ele) => {
            return {
              name: ele.name, y: ele.value, drilldown: ele.name
            }
          }),
        };
      });
      const stackChartDatas: { [key: string]: string | number }[] = data?.dynamicDatas[2].datas[0].columnValues;
      const uniqueDates = [...new Set(stackChartDatas.flatMap(item => Object.keys(item).slice(1)))];
      this.lTLCostSeries = stackChartDatas.map((item: any, i: number) => {
        const origin = item.Origin;
        const data = uniqueDates.map((date: any, index: number) => {
          const value = parseFloat(item[date] as string) || 0;
          return {
            name: date, y: value, drilldown: date
          }
        });
        return {
          type: 'column',
          color: GRAPH_COLORS[i % GRAPH_COLORS.length],
          name: origin,
          data: data
        };
      });

      const capacityPlannedbyWeekDatas = data?.dynamicDatas[0].datas[0].columnValues;
      this.capacityPlannedbyWeekTbl = this.transformData(capacityPlannedbyWeekDatas);
      if (this.capacityPlannedbyWeekTbl && this.capacityPlannedbyWeekTbl.length > 0 && this.capacityPlannedbyWeekTbl[0]) {
        this.capacityPlannedbyWeekColumns = Object.keys(this.capacityPlannedbyWeekTbl[0]).filter(key => key !== 'id' && key !== 'cellColors');
      }
      if (this.capacityPlannedbyWeekColumns && Array.isArray(this.capacityPlannedbyWeekColumns) && this.capacityPlannedbyWeekColumns.length > 0) {
        this.capacityPlannedbyWeekHeaders = this.capacityPlannedbyWeekColumns.reduce((acc: { [key: string]: string }, key: string) => {
          acc[key] = key;
          return acc;
        }, {});
      }

      const capacityPlannedbyLaneWeekDatas = data?.dynamicDatas[1].datas[0].columnValues;
      this.capacityPlannedbyLaneWeekTbl = this.transformData(capacityPlannedbyLaneWeekDatas, 'Origin-Destination');
      if (this.capacityPlannedbyLaneWeekTbl && this.capacityPlannedbyLaneWeekTbl.length > 0 && this.capacityPlannedbyLaneWeekTbl[0]) {
        this.capacityPlannedbyLaneWeekColumns = Object.keys(this.capacityPlannedbyLaneWeekTbl[0]).filter(key => key !== 'id' && key !== 'cellColors');
      }
      if (this.capacityPlannedbyLaneWeekColumns && Array.isArray(this.capacityPlannedbyLaneWeekColumns) && this.capacityPlannedbyLaneWeekColumns.length > 0) {
        this.capacityPlannedbyLaneWeekHeaders = this.capacityPlannedbyLaneWeekColumns.reduce((acc: { [key: string]: string }, key: string) => {
          acc[key] = key;
          return acc;
        }, {});
      }
      this.apiDataLoaded = true;
      this.isLoading = false;
    }
    else {
      this.apiDataLoaded = false;
      this.isLoading = false;
    }
  }

  private drilldownDataMap: Map<string, any[]> = new Map();
  onDrilldown(event: any, xAxisLabel): void {
    const drilldownId = event.point.drilldown;
    const selectedDrilldownData = {
      xAxisLabel: xAxisLabel,
      drilldownId: drilldownId
    };
    if (!this.drilldownDataMap.has(drilldownId)) {
      this.drilldownDataMap.set(drilldownId, [selectedDrilldownData]);
      this.chartClicked.emit(selectedDrilldownData);
    }
  }

}

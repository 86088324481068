<div class="main-container" *ngIf="!isLoading">
  <div class="row" *ngIf="summaryCardData?.length">
    <div class="col" *ngFor="let data of summaryCardData; let i = index">
      <div class="card card-stats mb-4 mb-xl-0 summary-card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="card-title">{{ data.name }}</span>
            </div>
          </div>
        </div>
        <div class="score-txt">
          <span class="score" [ngStyle]="{
              'background': gradientColors(i),
              'background-clip': 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }">
            {{ data.currentYear }}{{ data.unit || '' }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- Loader start -->
  <div *ngIf="displayLoader" class="d-flex justify-content-center loader">
    <mat-spinner class="loading"></mat-spinner>
  </div>
  <!-- Loader end -->

  <div class="table-container">
    <div class="mat-elevation-z8 redeployment-table">
      <table mat-table [dataSource]="filteredData" class="mat-table" [multiTemplateDataRows]="true"
        [class.disabled-table]="isInProgress">

        <!-- Source Location Column -->
        <ng-container matColumnDef="Source Location">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 7%;">
            <div class="header-text d-flex" (click)="toggleSearch('Source Location')">
              <p class="m-0">Source <br> Location</p>
              <mat-icon class="arrow-icon">
                {{ showSearch['Source Location'] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </mat-icon>
            </div>

            <!-- Dropdown Filter Menu -->
            <div class="dropdown-menu" [ngClass]="{ 'show': showSearch['Source Location'] }">
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="filterValues['Source Location']" placeholder="Search Source Lcation"
                  autocomplete="off">
              </mat-form-field>
              <button class="search-btn no-padding" mat-raised-button color="primary"
                (click)="applyFilter('Source Location', filterValues['Source Location'])" matTooltip="Search.."
                matTooltipPosition="above">
                <mat-icon matPrefix>search</mat-icon>
              </button>
              <button class="clear-btn no-padding" mat-raised-button color="primary"
                (click)="clearFilter('Source Location')" matTooltip="Clear.." matTooltipPosition="above">
                <mat-icon matPrefix>clear</mat-icon>
              </button>
            </div>
          </th>
          <td mat-cell *matCellDef="let row;let i = index" style="width: 7%;"> {{ row['Source Location'] }}</td>
        </ng-container>

        <!-- Destination Location Column -->
        <ng-container matColumnDef="Destination Location">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 9%;">
            <div class="header-text" (click)="toggleSearch('Destination Location')">
              <p class="m-0">Destination <br> Location</p>
              <mat-icon class="arrow-icon">
                {{ showSearch['Destination Location'] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </mat-icon>
            </div>

            <!-- Dropdown Filter Menu -->
            <div class="dropdown-menu" [ngClass]="{ 'show': showSearch['Destination Location'] }">
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="filterValues['Destination Location']"
                  placeholder="Search Destination Location" autocomplete="off">
              </mat-form-field>
              <button class="search-btn no-padding" mat-raised-button color="primary"
                (click)="applyFilter('Destination Location', filterValues['Destination Location'])"
                matTooltip="Search.." matTooltipPosition="above">
                <mat-icon matPrefix>search</mat-icon>
              </button>
              <button class="clear-btn no-padding" mat-raised-button color="primary"
                (click)="clearFilter('Destination Location')" matTooltip="Clear.." matTooltipPosition="above">
                <mat-icon matPrefix>clear</mat-icon>
              </button>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" style="width: 9%;"> {{ row['Destination Location'] }} </td>
        </ng-container>

        <!-- Transfer Date Column -->
        <ng-container matColumnDef="Transfer Date">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 9%;"> Transfer <br> Date </th>
          <td mat-cell *matCellDef="let row" style="width: 9%;"> {{ row['Transfer Date'] }} </td>
        </ng-container>

        <!-- Requirement Date Column -->
        <ng-container matColumnDef="Requirement Date">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 6%;"> Requirement<br> Date </th>
          <td mat-cell *matCellDef="let row" style="width: 6%;"> {{ row['Requirement Date'] }} </td>
        </ng-container>

        <!-- SKU Column -->
        <ng-container matColumnDef="SKU">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 12%;">
            <div class="header-text" (click)="toggleSearch('SKU')">
              <p class="m-0">{{ getHeader('SKU') }}</p>
              <mat-icon class="arrow-icon sku-icon">
                {{ showSearch['SKU'] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </mat-icon>
            </div>

            <!-- Dropdown Filter Menu -->
            <div class="dropdown-menu" [ngClass]="{ 'show': showSearch['SKU'] }">
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="filterValues['SKU']" placeholder="Search SKU" autocomplete="off">
              </mat-form-field>
              <button class="search-btn no-padding" mat-raised-button color="primary"
                (click)="applyFilter('SKU', filterValues['SKU'])" matTooltip="Search.." matTooltipPosition="above">
                <mat-icon matPrefix>search</mat-icon>
              </button>
              <button class="clear-btn no-padding" mat-raised-button color="primary" (click)="clearFilter('SKU')"
                matTooltip="Clear.." matTooltipPosition="above">
                <mat-icon matPrefix>clear</mat-icon>
              </button>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" style="width: 12%;"> {{ getFormattedSKU(row.SKU) }}</td>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="Quantity">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 6%;"> Quantity </th>
          <td mat-cell *matCellDef="let row" style="width: 6%;"> {{ row.Quantity }} </td>
        </ng-container>

        <!-- Transport Mode-->
        <ng-container matColumnDef="Tlane_Mode_Type">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 8%;">
            <div class="header-text" (click)="toggleSearch('Tlane_Mode_Type')">
              <p class="m-0">Transport <br> Mode</p>
              <mat-icon class="arrow-icon">
                {{ showSearch['Tlane_Mode_Type'] ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </mat-icon>
            </div>

            <!-- Dropdown Filter Menu -->
            <div class="dropdown-menu" [ngClass]="{ 'show': showSearch['Tlane_Mode_Type'] }">
              <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="filterValues['Tlane_Mode_Type']" placeholder="Search Transport Mode"
                  autocomplete="off">
              </mat-form-field>
              <button class="search-btn no-padding" mat-raised-button color="primary"
                (click)="applyFilter('ModeOfTransport', filterValues['Tlane_Mode_Type'])" matTooltip="Search.."
                matTooltipPosition="above">
                <mat-icon matPrefix>search</mat-icon>
              </button>
              <button class="clear-btn no-padding" mat-raised-button color="primary"
                (click)="clearFilter('Tlane_Mode_Type')" matTooltip="Clear.." matTooltipPosition="above">
                <mat-icon matPrefix>clear</mat-icon>
              </button>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" style="width: 8%;">{{ row['Tlane_Mode_Type'] }}</td>
        </ng-container>



        <!-- Revenue Column -->
        <ng-container matColumnDef="Revenue">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 7%;"> Revenue </th>
          <td mat-cell *matCellDef="let row" style="width: 7%;"> ${{ row.Revenue }} </td>
        </ng-container>

        <!-- Cost of Redeployment Column -->
        <ng-container matColumnDef="Cost of Redeployment">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 7%;"> Logistics <br> Cost </th>
          <td mat-cell *matCellDef="let row" style="width: 7%;"> ${{ row['Cost of Redeployment'] }} </td>
        </ng-container>

        <!-- Pallets Space Required Column -->
        <ng-container matColumnDef="Pallets Space Required">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 7%;"> Quantity <br> (Pallets) </th>
          <td mat-cell *matCellDef="let row" style="width: 7%;"> {{ row['Pallets Space Required'] }} </td>
        </ng-container>

        <!-- Trucks availability at Source -->
        <ng-container matColumnDef="Trucks availability at Source">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 8%;"> Trucks <br> (Pallets) </th>
          <td mat-cell *matCellDef="let row" style="width: 8%;"> {{ row['Trucks availability at Source'] }} </td>
        </ng-container>

        <!-- Pallets availability at Destination -->
        <ng-container matColumnDef="Pallets availability at Destination">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 6%;"> WH (Pallets) </th>
          <td mat-cell *matCellDef="let row; let i =index" style="width: 6%;"> {{row['Pallets availability at Destination']}} </td>
        </ng-container>
        
        <!-- Difference in USD-->
        <ng-container matColumnDef="Difference in USD">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 9%;"> $ Difference </th>
          <td mat-cell *matCellDef="let row" style="width: 9%;"> ${{ row['Difference in USD'] }} </td>
        </ng-container>


        <!--Details-->
        <ng-container matColumnDef="Details">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 7%;"> Details </th>
          <td mat-cell *matCellDef="let row" style="width: 7%;">
            <mat-icon class="add-remove-icon" (click)="toggleRow(row)" *ngIf="row.child.length > 0">{{ isExpanded(row) ?
              'remove' : 'add' }}</mat-icon>
          </td>
        </ng-container>

        <!-- Expanded Details Row -->


        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef sticky style="width: 6%;">
            <div class="header-with-dropdown">
              <span>Actions</span>

              <!-- Downward Arrow Button to Show Dropdown -->
              <button mat-icon-button (click)="toggleDropdown()" class="toggle-button">
                <span class="material-symbols-outlined">expand_more</span>
              </button>

              <!-- Dropdown Filter (Visible only if showDropdown is true) -->
              <div *ngIf="showDropdown" class="dropdown-container">
                <mat-form-field appearance="outline" class="flag-filter">
                  <mat-select [(ngModel)]="selectedFlag" (selectionChange)="applyFlagFilter(); toggleDropdown()">
                    <mat-option value="">All</mat-option>
                    <mat-option value="0">Rejected</mat-option>
                    <mat-option value="1">Approved</mat-option>
                    <mat-option value="2">Pending</mat-option>
                  </mat-select>
                  <!-- <mat-select [(ngModel)]="selectedFlag"
                  (selectionChange)="applyFlagFilter($event)">
                  <mat-option *ngFor="let option of statusDropdownList" [value]="option.value">
                      {{ option.name }}
                  </mat-option>
              </mat-select> -->
                </mat-form-field>
              </div>
            </div>
          </th>

          <td mat-cell *matCellDef="let row;" style="width: 6%;">
            <ng-container [ngSwitch]="row.Flag">
              <!-- Rejected Status -->
              <span *ngSwitchCase="0" class="status-rejected">Rejected</span>

              <!-- Approved Status -->
              <span *ngSwitchCase="1" class="status-approved">Approved</span>

              <!-- Pending Status with Actions -->
              <div *ngSwitchCase="2" class="actions-buttons d-flex">
                <button mat-button (click)="approve(row,'approve')" class="approve-button" matTooltip="Approve"
                  [ngClass]="selectedAction[row.id] === 'approve' ? 'highlighted-action-btn' : 'approve-button' ">
                  <span class="material-symbols-outlined approve-icon">check_circle</span>
                </button>

                <button mat-button (click)="reject(row,'reject')" class="reject-button" matTooltip="Reject"
                  [ngClass]="selectedAction[row.id] === 'reject' ? 'highlighted-action-btn': 'reject-button'">
                  <span class="material-symbols-outlined reject-icon">cancel</span>
                </button>
              </div>
            </ng-container>
          </td>
        </ng-container>



        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="childColumnsToDisplay.length">
            <div class="expanded-container" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
              <table mat-table [dataSource]="row.child" class="mat-elevation-z8 expanded-table">

                <!-- Dynamic Columns for Expanded Table -->
                <ng-container matColumnDef="{{ column }}" *ngFor="let column of childColumnsToDisplay">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ columnDisplayNames[column] || column }}
                  </th>
                  <td mat-cell *matCellDef="let child">
                    <ng-container
                      *ngIf="column === 'Revenue' || column === 'Cost of Redeployment' || column === 'Difference in USD'; else defaultCell">
                      $ {{ child[column] || '0.00' }}
                    </ng-container>
                    <ng-template #defaultCell>{{ child[column] || 'N/A' }}</ng-template>

                  </td>
                </ng-container>

                <!-- Header Row for Expanded Table -->
                <tr mat-header-row *matHeaderRowDef="childColumnsToDisplay"></tr>
                <!-- Data Row for Expanded Table -->
                <tr mat-row *matRowDef="let child; columns: childColumnsToDisplay"></tr>

              </table>
            </div>
          </td>
        </ng-container>



        <!-- Table Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class=""
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>


      <div class="floating-button-container">
        <button mat-raised-button color="primary" (click)="submitSelections()" [disabled]="!isRecordSelected"
          aria-label="Submit Selections" class="submit-button">
          <b>Submit</b>
        </button>
      </div>

    </div>
  </div>
</div>
<!-- Loader start -->
<div *ngIf="isLoading" class="spinner-overlay">
  <app-loader [isLoading]="isLoading" [message]="loadingMessage"></app-loader>
</div>
<!-- Loader end -->
<div class="scenario-container">
  <div class="scenario-header">
    <h2 class="scenario-heading">Scenario Comparison</h2>
    <button class="add-scenario-btn" routerLink="/home/network-model/simulate-scenario">+ Add more scenarios</button>
  </div>
  
    <table mat-table [dataSource]="tableData" class="mat-elevation-z8">
  
      <!-- Scenario Title Column -->
      <ng-container matColumnDef="scenarioTitle">
        <th mat-header-cell *matHeaderCellDef style="color: white; text-align: left !important;"> <b>Scenario Title</b> </th>
        <td mat-cell *matCellDef="let element" style="max-width: 150px; text-align: left !important;"> {{ element.scenarioTitle }} </td>
      </ng-container>
  
      <!-- Dynamically Render Other Columns -->
       <!-- font color change -->
      <ng-container *ngFor="let column of scenarioColumns" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef style="color: white; text-align: center;"><b> {{ column }} </b> </th>
        <td mat-cell *matCellDef="let element">
          <span
            [ngStyle]="{ 
              'color': shouldHighlight(column, element[column]) ? '#24FF4C' : 'inherit', 
              'font-weight': shouldHighlight(column, element[column]) ? 'bold' : 'normal'
            }" >
            {{ element[column] }}
          </span>
        </td>
      </ng-container>

      <!-- Table Header and Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
    </table>
  </div>
  
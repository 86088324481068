<div *ngIf="filtersLoaded; else loading">
    <div class="right-sidebar" id="right-sidebar">
        <button mat-raised-button class="refresh-btn" (click)="resetAllFilters()">
            <mat-icon class="cached-icon" matTooltip="Reset filters">cached</mat-icon>
        </button>
        <ng-container *ngFor="let filter of filters">
            <mat-form-field class="select-field" appearance="outline" *ngIf="filter.type === 'dropdown'">
                <mat-label>{{ filter.label }}</mat-label>
                <mat-select [(ngModel)]="filter.selectedValue" (selectionChange)="onDropdownChange(filter)">
                    <mat-option *ngFor="let option of filter.options" [value]="option.name">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="select-field" appearance="outline" *ngIf="filter.type === 'dateRange'">
                <mat-label>{{ filter.label }}</mat-label>
                <mat-date-range-input [rangePicker]="picker" [min]="minRequiredDeliveryDate"
                [max]="maxRequiredDeliveryDate">
                    <input matStartDate placeholder="Start date" [(ngModel)]="filter.startDate"
                        (dateChange)="onDateRangeChange(filter)" >
                    <input matEndDate placeholder="End date" [(ngModel)]="filter.endDate" 
                        (dateChange)="onDateRangeChange(filter)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </ng-container>
        <button mat-raised-button class="apply-btn" (click)="onApply()">Apply</button>
    </div>
</div>
<ng-template #loading>
    <p class="loadingFilterMsg">{{loadingMessage}}</p>
</ng-template>
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SOE_VIEWS } from 'src/app/features/home/components/soe/constants/soe.constants';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
})
export class MatTableComponent implements OnInit, AfterViewInit {
  @Input() displayedColumns: string[] = [];
  @Input() dataSource;
  @Input() headers: { [key: string]: string } = {}; // Dynamic headers for each column
  @Output() rowClicked = new EventEmitter<any>(); // Event emitter for row clicks
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() showPaginator: boolean = true;
  @Input() isStickyHeader: boolean = false;
  @Input() colorClassList: any;
  @Input() htmlView: string;
  @Input() editableColumns: string[] = [];
  @Output() valueChanged = new EventEmitter<{ row: any; column: string }>();
  @Input() reducedValues: { [column: string]: number };
  @Input() addedValues: { [column: string]: number };
  soeViews = SOE_VIEWS;
  originalColumnSums: { [key: string]: number } = {}; // Store original sums

  editableCell: { rowIndex: number | null; column: string | null } = { rowIndex: null, column: null };

  constructor(private toastr: ToastrService, ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.dataSource);
    this.calculateOriginalSums();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onRowClick(row: any) {
    this.rowClicked.emit(row);
  }

  /** Sets the clicked cell as editable */
  setEditable(rowIndex: number, column: string): void {
    this.editableCell = { rowIndex, column };
  }

  /** Saves the value when user exits input */
  onCellEditComplete(row: any, column: string): void {
    this.valueChanged.emit({ row, column });
    this.editableCell = { rowIndex: null, column: null }; // Reset after editing
  }

  getColumnTotal(column: string): number {
    return this.dataSource.data
      .map((row: any) => Number(row[column]) || 0) // Convert values to numbers, default to 0 if not a number
      .reduce((sum, value) => sum + value, 0); // Sum all values
  }

  // Function to calculate and store original column sums
  calculateOriginalSums(): void {
    this.displayedColumns.forEach((column) => {
      if (/^\d{2}-\d{2}-\d{4}$/.test(column)) {
        // Check if it's a date column
        this.originalColumnSums[column] = this.getColumnTotal(column);
      }
    });
  }

  // Function to handle the validation logic when a value is changed (blur or Enter key)
  onTableValueChange(event: { row: any; column: string }): void {
    const column = event.column;
    const newValue = Number(event.row[column]) || 0; // Convert to number
    const currentTotal = this.getColumnTotal(column);
    const originalTotal = this.originalColumnSums[column];

    // Prevent negative values
    if (newValue < 0) {
      event.row[column] = 0; // Reset to 0 if the value is negative
      this.toastr.warning('Negative values are not allowed.');
      return; // Stop further execution
    }

    // Check if the sum exceeds the original total
    if (currentTotal > originalTotal) {
      this.toastr.warning(`Total for ${column} cannot exceed ${originalTotal}.`);
      event.row[column] = originalTotal - (currentTotal - newValue); // Adjust the value to keep sum within the limit
      return; // Stop further execution
    }

    // If all checks pass, emit the value change event
    this.valueChanged.emit(event);
  }

  isInputDisabled(column: string): boolean {
    const nonEditableColumns = ['Material ID', 'Qty at Risk', 'Inventory', 'Avg Weekly Forecast', 'Production Plant'];
    return nonEditableColumns.includes(column);
  }
  

  // New function to validate if all values are valid and sum is equal to the original sum
  isSubmitDisabled(): boolean {
    let isInvalidValue = false;
    let isSumExceeded = false;
  
    // Check for negative values and sum issues
    this.dataSource.data.forEach(row => {
      this.displayedColumns.forEach(column => {
        const cellValue = row[column];
        if (cellValue < 0) {
          isInvalidValue = true;
        }
      });
    });
  
    this.displayedColumns.forEach(column => {
      const newSum = this.dataSource.data.reduce((sum, row) => sum + (row[column] || 0), 0);
      if (newSum !== this.originalColumnSums[column]) {
        isSumExceeded = true;
      }
    });
  
    //console.log('isInvalidValue:', isInvalidValue, 'isSumExceeded:', isSumExceeded);
  
    return isInvalidValue || isSumExceeded; // Disable submit button if any condition is violated
  }
  

  isEditable(column: string): boolean {
    const nonEditableColumns = ['Material ID', 'Qty at Risk', 'Inventory', 'Avg Weekly Forecast', 'Production Plant'];
    return !nonEditableColumns.includes(column); // Return false if the column is in the list of non-editable columns
  }

  // Submit button handler
  onSubmit(): void {
    // Display a success message using Toastr
    this.toastr.success('Changes saved successfully!.');
  
  }

  
  

  getFontColor(cellColor: string): string {
    return ['red', 'yellow', 'green'].includes(cellColor)
      ? '#181830'
      : '#D7D7DC';
  }

  getColor(value: number): string {
    switch (this.htmlView) {
      case this.soeViews.SKU_PROJECTIONS: {
        if (parseInt(value.toString().replace(/,/g, ''), 10)) {
          return parseInt(value.toString().replace(/,/g, ''), 10) < 0
            ? this.colorClassList?.LESS_THAN_ZERO
            : this.colorClassList?.GREATER_THAN_ZERO;
        }
        break;
      }
      case this.soeViews.WAREHOUSE_UTILIZATION: {
        if (value.toString().includes('%')) {
          return Number(value.toString().replace('%', '')) > 90
            ? this.colorClassList?.OVERUTILIZED
            : Number(value.toString().replace('%', '')) < 50
            ? this.colorClassList?.UNDERUTILIZED
            : this.colorClassList.OPTIMALLY_UTILIZED;
        }
        break;
      }
    }
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/constants/api.urls';
import {
  IOrdersAtRiskFilterList,
  IOrdersAtRiskDataRequestBody,
  IOrdersAtRiskDataResponse,
  IModalApiParam,
} from '../interfaces/orders-at-risk.interface';

@Injectable({
  providedIn: 'root',
})
export class OrdersAtRiskService {
  constructor(private http: HttpClient) {}

  getOrdersAtRiskFilters(): Observable<IOrdersAtRiskFilterList> {
    return this.http.get<IOrdersAtRiskFilterList>(
      API_URLS.getOrdersAtRiskFilters
    );
  }

  getOrdersAtRiskAllData(
    selectedFilters: IOrdersAtRiskDataRequestBody,
    selectedToggle: boolean,
    asISOrRecommendationToggle: boolean
  ): Observable<IOrdersAtRiskDataResponse> {
    const params = new HttpParams().set('toggle', selectedToggle);
    const API_URL = asISOrRecommendationToggle
      ? API_URLS.getOrdersAtRiskAllData
      : API_URLS.getOrdersAtRiskRecommendationData;
    return this.http.post<IOrdersAtRiskDataResponse>(API_URL, selectedFilters, {
      params: params,
    });
  }

  getModalDataForOrdersAtRisk(
    selectedFilters: IOrdersAtRiskDataRequestBody,
    param: IModalApiParam
  ): Observable<any> {
    const params = new HttpParams()
      .set('toggle', param.toggle)
      .set('page', param.page);
    return this.http.post<any>(
      API_URLS.getModalDataForOrdersAtRisk,
      selectedFilters,
      {
        params: params,
      }
    );
  }
}

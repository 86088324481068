export const MENU_DATA_DETAILS = {
  DASHBOARD: {
    id: 1,
    label: 'HOMEPAGE',
    icon: 'home',
    routerLink: '/home/mps-overview',
    children: [],
  },
  APPSTORE: {
    id: 2,
    label: 'APP STORE',
    icon: 'view_module',
    routerLink: '/home/app-store',
    children: [],
  },
  SC_ASSISTANT: {
    id: 14,
    label: 'SC ASSISTANT',
    icon: 'chat',
    routerLink: '/home/sc-assistant',
    children: [],
  },
  DATA_MGT: {
    id: 14,
    label: 'DATA MANAGEMENT',
    icon: 'database',
    routerLink: '/home/data-mgt',
    reportId: 'a7adf0a5-17e7-4748-bb5c-ca53d5728cd1',
    children: [],
  },
  OTIF: {
    id: 3,
    label: 'OTIF',
    icon: 'polymer',
    routerLink: '/home/otif-dashboard',
    children: [
      {
        id: 1,
        label: 'OTIF Monitor',
        icon: 'polymer',
        routerLink: '/home/otif-dashboard',
        children: [],
      },
      {
        id: 2,
        label: 'Demand Forecasting Tool',
        icon: 'batch_prediction',
        children: [
          {
            id: 1,
            label: 'Demand Forecast',
            icon: 'batch_prediction',
            routerLink: '/home/demand-forecast',
            reportId: 'b1a3a26c-b0ac-4159-a3ec-e88bcfc9d73e',
            children: [],
          },
          {
            id: 2,
            label: 'Forecast',
            icon: 'batch_prediction',
            routerLink: '/home/forecast',
            children: [],
          },
          {
            id: 3,
            label: 'Forecast Review',
            icon: 'batch_prediction',
            routerLink: '/home/forecast_review',
            reportId: '153bb89c-e1ab-4180-a7aa-e003ea4bff13',
            children: [],
          },
          {
            id: 4,
            label: 'SKU Review',
            icon: 'batch_prediction',
            routerLink: '/home/sku-review',
            children: [],
          },
        ],
      },
      {
        id: 3,
        label: 'Inventory Optimizer',
        icon: 'library_add_check',
        children: [
          {
            id: 1,
            label: 'SEIO',
            icon: 'library_add_check',
            children: [
              {
                id: 1,
                label: 'FG',
                icon: 'library_add_check',
                routerLink: '/home/inventory-optimizer',
                reportId: 'fcc2fc0c-9fe2-4b30-9c65-7f0407bb03cf',
                children: [],
              },
              {
                id: 2,
                label: 'MRO',
                icon: 'library_add_check',
                routerLink: '/home/inventory-optimizer-mro',
                reportId: 'f100f90c-174c-4d0c-9451-d4da3271b012',
                children: [],
              },
            ],
          },
          {
            id: 2,
            label: 'MEIO',
            icon: 'library_add_check',
            children: [
              {
                id: 1,
                label: 'Executive Summary',
                icon: 'library_add_check',
                routerLink: '/home/meio-executive-summary',
                children: [],
              },
              {
                id: 2,
                label: 'Parameters',
                icon: 'library_add_check',
                routerLink: '/home/meio-parameters',
                children: [],
              },
              {
                id: 3,
                label: 'Output',
                icon: 'library_add_check',
                routerLink: '/home/meio-output',
                children: [],
              },
              {
                id: 4,
                label: 'Scenario Comparison',
                icon: 'library_add_check',
                routerLink: '/home/scenario-comparison',
                children: [],
              },
              {
                id: 5,
                label: 'Inventory Simulation',
                icon: 'library_add_check',
                routerLink: '/home/meio-optimization',
                children: [],
              },
            ],
          },
        ]
      },
      {
        id: 4,
        label: 'Transportation Analyzer',
        icon: 'directions_boat',
        children: [
          {
            id: 1,
            label: 'Delivery Performance',
            icon: 'directions_boat',
            routerLink: '/home/delivery-performance',
            reportId: 'b1a3a26c-b0ac-4159-a3ec-e88bcfc9d73e',
            children: [],
          },
          {
            id: 2,
            label: 'Transit time deep-dive',
            icon: 'directions_boat',
            routerLink: '/home/deep-dive',
            reportId: 'ad12de86-e834-4d9f-8c5b-083b1ee12eac',
            children: [],
          },
          {
            id: 3,
            label: 'Truck Availability',
            icon: 'directions_boat',
            routerLink: '/home/truck-availability',
            reportId: '7737476f-abb6-4553-8335-91c844c1ee27',
            children: [],
          },
          {
            id: 4,
            label: 'Logistics Assistant',
            icon: 'directions_boat',
            routerLink: '/home/tms-assistant',
            children: [],
          }
        ],
      },
      {
        id: 5,
        label: 'Warehouse Analyzer',
        icon: 'warehouse',
        children: [
          {
            id: 1,
            label: 'On-Time',
            icon: 'warehouse',
            routerLink: '/home/warehouse-analyzer/on-time',
            reportId: 'ff0c7c7a-593f-44a9-9572-4ec730fce03f',
            children: [],
          },
          {
            id: 2,
            label: 'In Full',
            icon: 'warehouse',
            routerLink: '/home/warehouse-analyzer/in-full',
            reportId: 'af699d88-499a-4f60-bb67-ebef78cad689',
            children: [],
          },
          {
            id: 3,
            label: 'Recommendations',
            icon: 'warehouse',
            routerLink: '/home/warehouse-analyzer/recommendations',
            reportId: '0c4d3172-4cfd-47e2-9a8d-5b539db1202a',
            children: [],
          },
          {
            id: 4,
            label: 'Inbound Analysis',
            icon: 'warehouse',
            routerLink: '/home/warehouse-analyzer/inbound-analysis',
            reportId: '98c8e967-9129-4e9a-b9de-6604adb5dae9',
            children: [],
          },
          {
            id: 5,
            label: 'Pick Analysis',
            icon: 'warehouse',
            routerLink: '/home/warehouse-analyzer/pick-analysis',
            reportId: '3a7e4483-04e9-4ab9-aa63-73131d22bb25',
            children: [],
          },
          {
            id: 6,
            label: 'Item & Inventory Analysis',
            icon: 'warehouse',
            routerLink: '/home/warehouse-analyzer/inventory-analysis',
            reportId: '71453092-019a-4218-86d2-c0378dc16a2d',
            children: [],
          },
          {
            id: 7,
            label: 'Space Utilization',
            icon: 'warehouse',
            routerLink: '/home/warehouse-analyzer/space-utilization',
            reportId: '97cfdfc5-0271-48a8-ad18-9457a80385b2',
            children: [],
          },
          {
            id: 8,
            label: 'Labor Analysis',
            icon: 'warehouse',
            routerLink: '/home/warehouse-analyzer/labor-analysis',
            reportId: 'f7d008ed-fb90-4ef9-90f9-caf7386bd8c9',
            children: [],
          },
        ],
      },
      {
        id: 6,
        label: 'Segmentation',
        icon: 'conveyor_belt',
        routerLink: '/home/segmentation',
        reportId: 'e26cf7dd-b8a1-4bdf-9d86-75e495970a0b',
        children: [],
      },
      {
        id: 7,
        label: 'S & OE',
        icon: 'store',
        children: [
          {
            id: 1,
            label: 'S & OE Monitor',
            icon: 'store',
            routerLink: '/home/soe/soe-monitor',
            children: [],
          },
          {
            id: 2,
            label: 'Sales Order Management',
            icon: 'store',
            routerLink: '/home/soe/kpi',
            reportId: '939e574e-bec7-4f2d-90a4-8f0e3e179f84',
            children: [],
          },
          {
            id: 3,
            label: 'Production Capacity Management',
            icon: 'store',
            routerLink: '/home/soe/actionLog',
            reportId: '9ad782f9-77ed-4539-950a-3a0e78fc820f',
            children: [],
          },
          {
            id: 4,
            label: 'Inventory Management',
            icon: 'store',
            routerLink: '/home/soe/inventory',
            reportId: '33ea370e-c34a-463b-95af-3b4c7a1249cd',
            children: [],
          },
          {
            id: 5,
            label: 'Demand Control',
            icon: 'store',
            routerLink: '/home/soe/demand-control',
            reportId: '82b69fb2-ece2-4c7a-b5a8-471a30f36ece',
            children: [],
          },
          {
            id: 6,
            label: 'Network Rebalancing',
            icon: 'store',
            routerLink: '/home/soe/network-rebalancing',
            reportId: 'ab62407e-5990-43cf-b86d-d79daac94c70',
            children: [],
          },
          {
            id: 7,
            label: 'Forecast Consumption',
            icon: 'store',
            routerLink: '/home/soe/forecast-simulation',
            reportId: '2fc9b049-b0ea-4f1b-9515-9ca348d10e3d',
            children: [],
          },
          {
            id: 8,
            label: 'Truck Availability',
            icon: 'store',
            routerLink: '/home/soe/truck-availability',
            reportId: '7737476f-abb6-4553-8335-91c844c1ee27',
            children: [],
          },
          {
            id: 8,
            label: 'Warehouse Space Utilization',
            icon: 'store',
            routerLink: '/home/soe/warehouse-space',
            reportId: '97cfdfc5-0271-48a8-ad18-9457a80385b2',
            children: [],
          },
          {
            id: 9,
            label: 'Agile Production Scheduling',
            icon: 'store',
            routerLink: '/home/soe/re-scheduling',
            reportId: '79abcb7f-cd72-4f96-8b9b-b9861f62d0ca',
            children: [],
          },
          {
            id: 10,
            label: 'Warehouse Capacity Management',
            icon: 'store',
            routerLink: '/home/soe/warehouse-capacity',
            reportId: '082e1736-9b68-4510-8675-984a94dce448',
            children: [],
          },
          {
            id: 11,
            label: 'Schedule Adherence',
            icon: 'store',
            routerLink: '/home/soe/schedule-adherence',
            reportId: '4a028e5d-7fcb-4c49-b220-41e05b817144',
            children: [],
          },
          {
            id: 12,
            label: 'Production Optimizer',
            icon: 'store',
            children: [
              {
                id: 1,
                label: 'Baseline',
                icon: 'store',
                routerLink: '/home/soe/baseline',
                reportId: '72d5b965-b861-42cf-a258-f31526816769',
                children: [],
              },
              {
                id: 2,
                label: 'Scenario',
                icon: 'store',
                routerLink: '/home/soe/scenario',
                reportId: '88252e52-2a59-4834-a95e-fc63dcbe69b5',
                children: [],
              },
            ],
          },
          //     {
          //         id: 14,
          //         label: 'Orders at Risk',
          //         icon: 'store',
          //         routerLink: '/home/soe/orders-at-risk',
          //         children: [],
          //     },
          //     {
          //         id: 15,
          //         label: 'Redeployment Scenarios & Approvals',
          //         icon: 'store',
          //         routerLink: '/home/soe/redeployment-approvals',
          //         children: [],
          //     },
          //     {
          //         id: 16,
          //         label: 'Inventory Health',
          //         icon: 'store',
          //         routerLink: '/home/soe/inventory-health',
          //         children: [],
          //     },
          //     {
          //         id: 17,
          //         label: 'Redeployment Approval Impact',
          //         icon: 'store',
          //         routerLink: '/home/soe/redeployment-approval-impact',
          //         children: [],
          //     },
          //     {
          //         id: 18,
          //         label: 'Weekly Warehouse Space Utilization',
          //         icon: 'store',
          //         routerLink: '/home/soe/weekly-warehouse-utilization',
          //         children: [],
          //     },
          //     {
          //         id: 19,
          //         label: 'Weekly Truck Availability',
          //         icon: 'store',
          //         routerLink: '/home/soe/weekly-truck-availability',
          //         children: [],
          //     }
        ]
      },

    ],
  },
  OEE: {
    id: 4,
    label: 'OEE',
    icon: 'settings',
    routerLink: '/home/oee-dashboard',
    children: [
      {
        id: 1,
        label: 'OEE Monitor',
        icon: 'settings',
        routerLink: '/home/oee-dashboard',
        children: [],
      },
      {
        id: 2,
        label: 'Simulation & Optimization',
        icon: 'travel_explore',
        children: [
          {
            id: 1,
            label: 'Current State',
            icon: 'travel_explore',
            routerLink: '/home/network-model/currrent-state',
            reportId: '2b4e984e-7f13-4a96-a462-c24a7d64e107',
            children: [],
          },
          {
            id: 2,
            label: 'Network Simulation Model',
            icon: 'travel_explore',
            routerLink: '/home/network-model/network-overview',
            reportId: 'cfa3833b-4a24-48e1-9ed9-5f42c6d92ba7',
            children: []
          },
          {
            id: 3,
            label: 'Plant Simulation Model',
            icon: 'travel_explore',
            routerLink: '/home/plant-model/plant-overview',
            reportId: '52cee09a-a370-4402-9c3e-4b3aea281e63',
            children: []
          },
          {
            id: 4,
            label: 'Simulate Scenario',
            icon: 'travel_explore',
            routerLink: '/home/network-model/simulate-scenario',
            //reportId: 'bac0b441-8c2e-4d4d-bd33-faefd3222344',
            children: [],
          },
          {
            id: 5,
            label: 'Scenario 1',
            icon: 'travel_explore',
            routerLink: '/home/network-model/scenario1',
            reportId: '1bc6bd73-6982-478d-9816-f01d03b4357b',
            children: [],
          },
          {
            id: 6,
            label: 'Scenario 2',
            icon: 'travel_explore',
            routerLink: '/home/network-model/scenario2',
            reportId: 'a4f06e0a-ca6c-4188-963d-07c73862f1de',
            children: [],
          },
          {
            id: 7,
            label: 'Compare Scenario',
            icon: 'travel_explore',
            routerLink: '/home/network-model/compare-scenario',
            children:[],
          },
          {
            id: 8,
            label: 'mAItrix',
            icon: 'travel_explore',
            routerLink: '/home/network-model/manufacturing-Chatbot',
            dynamicUrl: 'https://euwdsrg03rwap02.azurewebsites.net/',
            children: [],
          },
          {
            id: 9,
            label: 'Simulation Assistant',
            icon: 'travel_explore',
            routerLink: '/home/network-model/simulation-assistant',
            children: []
          }
        ],
      },
      {
        id: 3,
        label: 'MxD Data Driven Insights',
        icon: 'network_intelligence_history',
        children: [
          {
            id: 1,
            label: 'Plant Digital Twin',
            icon: 'network_intelligence_history',
            routerLink: '/home/mxd/digital-twin',
            isDigitalTwinUrl: true,
            dynamicUrl: 'https://explorer.digitaltwins.azure.net/3DScenes/?sceneId=30ba3e63deb14d5f8992a00787bcffbc&selectedElementIds=1dfdddd4c20d434e8cf4620fcda8631a&selectedLayerIds=scene-layer-dropdown-unlayered-behaviors&mode=Viewer&adtUrl=https%3A%2F%2Feuwdsrg03radt01.api.weu.digitaltwins.azure.net&adtResourceId=%2Fsubscriptions%2F21be855a-14e1-4de5-b670-01ca21a7a46b%2FresourceGroups%2FEUWDSRG03RRSG01%2Fproviders%2FMicrosoft.DigitalTwins%2FdigitalTwinsInstances%2FEUWDSRG03RADT01&storageUrl=https%3A%2F%2Feuwdsrg03rsta03.blob.core.windows.net%2F3dscenes&tid=5b973f99-77df-4beb-b27d-aa0c70b8482c%27',
            children: []
          },
          {
            id: 2,
            label: 'Asset Monitoring',
            icon: 'network_intelligence_history',
            children: [
              {
                id: 1,
                label: 'KPI',
                icon: 'network_intelligence_history',
                routerLink: '/home/mxd/kpi',
                //dynamicUrl: 'https://euwdsrg03rwap10.azurewebsites.net/d/gVnrcoJVz/mxd-asset-summary?orgId=1',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/gVnrcoJVz/mxd-asset-summary?orgId=1',
                children: [],
              },
              {
                id: 2,
                label: 'Vitals',
                icon: 'network_intelligence_history',
                routerLink: '/home/mxd/vitals',
                //dynamicUrl: 'https://euwdsrg03rwap10.azurewebsites.net/d/ugZKJR74za/benchmill-asset-vital-dashboard?orgId=1',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/ugZKJR74za/benchmill-asset-vital-dashboard?orgId=1',
                children: [],
              }
            ]
          },
          {
            id: 3,
            label: 'Predictive Maintainance',
            icon: 'network_intelligence_history',
            children: [
              {
                id: 1,
                label: 'Anomaly Detection',
                icon: 'network_intelligence_history',
                routerLink: '/home/mxd/anomaly-detection',
                //dynamicUrl: 'https://euwdsrg03rwap10.azurewebsites.net/d/0HjTsXBVz/anomaly-prediction-maintenance-dashboard?orgId=1',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/n-0YMRa4k/predictive-maintenance-dashboard?orgId=1',
                children: [],
              },
              {
                id: 2,
                label: 'Asset Failure Detection',
                icon: 'network_intelligence_history',
                routerLink: '/home/mxd/asset-failure-detection',
                //dynamicUrl: 'https://euwdsrg03rwap10.azurewebsites.net/d/n-0YMRa4k/predictive-maintenance-dashboard?orgId=1',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/0HjTsXBVz/anomaly-prediction-maintenance-dashboard?orgId=1',
                children: [],
              },
            ]
          },
          {
            id: 4,
            label: 'ARCA',
            icon: 'network_intelligence_history',
            routerLink: '/home/mxd/arca',
            children: []
          },
        ]
      },
      {
        id: 4,
        label: 'Bulk Assessment',
        icon: 'token',
        routerLink: '/home/mxd/bulk-assessment',
        reportId: 'a22fbecc-a545-4eca-8a08-bb9502039c57',
        children: []
      },
      {
        id: 5,
        label: 'MTx',
        icon: 'diversity_2',
        routerLink: '/home/mxd/teaps',
        dynamicUrl: 'https://apps.powerapps.com/play/e/a3c669f6-ac2e-4e77-ad43-beab3e15bee7/a/8f32669b-c1c6-4066-8548-a73294c12d9c?tenantId=5b973f99-77df-4beb-b27d-aa0c70b8482c&source=AppSharedV3&sourcetime=1716442287188',
        children: []
      },
      {
        id: 6,
        label: 'Simulation - Short term',
        icon: 'display_settings',
        routerLink: '/home/mxd/simulation-short-term',
        reportId: '6bc3c377-96a8-437a-9f96-a0d9895e6fd8',
        children: []
      },
      {
        id: 7,
        label: 'Simulation F & B',
        icon: 'widgets',
        children: [
          {
            id: 1,
            label: 'Baseline',
            icon: 'widgets',
            routerLink: '/home/mxd/simulation-fb-baseline',
            reportId: '3a8fbd6a-03b9-470a-a4ac-256366184a37',
            children: [],
          },
          {
            id: 2,
            label: 'Scenario 1',
            icon: 'widgets',
            routerLink: '/home/mxd/simulation-fb-scenario1',
            reportId: 'efd25a84-fccf-4f59-a8cb-f42a42ef8ebb',
            children: [],
          },
          {
            id: 3,
            label: 'Scenario 2',
            icon: 'widgets',
            routerLink: '/home/mxd/simulation-fb-scenario2',
            reportId: '012391f2-6375-4b67-a7a3-bc39332c2207',
            children: [],
          },
        ]
      }
    ],
  },
  TRACEABILITY: {
    id: 5,
    label: 'TRACEABILITY',
    icon: 'monitoring',
    routerLink: '/home/traceability',
    children: [],
  },
  SC_COSTING: {
    id: 6,
    label: 'SUPPLY CHAIN COST',
    icon: 'payments',
    routerLink: '/home/supply-chain-cost',
    children: [
      {
        id: 1,
        label: 'SC Cost Monitor',
        icon: 'payments',
        routerLink: '/home/supply-chain-cost',
        children: []
      },
      {
        id: 2,
        label: 'Transportation Analyzer',
        icon: 'sailing',
        children: [
          {
            id: 1,
            label: 'Scorecard',
            icon: 'cards',
            children: [
              {
                id: 1,
                label: 'Carrier Scorecard',
                icon: 'cards',
                routerLink: '/home/carrier-scorecard',
                reportId: 'b495b69c-bd5b-4ca0-8333-00a8dc399264',
                children: []
              },
              {
                id: 2,
                label: 'Lane Scorecard',
                icon: 'cards',
                routerLink: '/home/lane-scorecard',
                reportId: '8d980b7d-e4b8-4e0d-b5a6-9c18eb062f11',
                children: []
              },
            ]
          },
          {
            id: 1,
            label: 'Transportation Cost Overview',
            icon: 'sailing',
            routerLink: '/home/finance-overview',
            reportId: '83d7456c-3fdf-42e3-8109-c4053fbb2d36',
            children: []
          },
          {
            id: 2,
            label: 'Cost Improvement',
            icon: 'sailing',
            children: [
              {
                id: 1,
                label: 'Fill Rate',
                icon: 'sailing',
                routerLink: '/home/fill-rate',
                reportId: '6b3d9f5e-6761-4309-b363-38e921371567',
                children: []
              },
              {
                id: 2,
                label: 'Freight Leakage',
                icon: 'sailing',
                routerLink: '/home/freight-leakage',
                reportId: 'a39a46be-60f5-4262-bc81-ec25353baa43',
                children: []
              },
              {
                id: 3,
                label: 'Contract Compliance',
                icon: 'sailing',
                routerLink: '/home/contract-compliance',
                reportId: '93f7ab18-b79f-4779-9535-5caf6268746c',
                children: []
              },
              {
                id: 4,
                label: 'Mode Shift',
                icon: 'sailing',
                routerLink: '/home/mode-shift',
                reportId: 'c905b1b5-b39d-43ee-9a95-9c25088e80db',
                children: []
              },
            ]
          },
          {
            id: 3,
            label: 'Freight Sourcing',
            icon: 'sailing',
            children: [
              {
                id: 1,
                label: 'Bid Optimizer',
                icon: 'sailing',
                routerLink: '/home/bid-optimizer',
                reportId: 'a19b252e-507a-4297-a38b-3b03d4192140',
                children: []
              },
              {
                id: 2,
                label: 'Rate Benchmarking',
                icon: 'sailing',
                routerLink: '/home/rate-benchmarking',
                reportId: 'b520e974-3ada-4d14-acba-d500be69a630',
                children: []
              },
            ]
          },
          // {
          //     id: 8,
          //     label: 'Carrier',
          //     icon: 'sailing',
          //     routerLink: '/home/carrier',
          //     reportId: 'b495b69c-bd5b-4ca0-8333-00a8dc399264',
          //     children: []
          // }
        ]
      },
      {
        id: 3,
        label: 'Procurement Spend',
        icon: 'shopping_cart_checkout',
        children: [
          {
            id: 1,
            label: 'Spend Management',
            icon: 'shopping_cart_checkout',
            routerLink: '/home/procurement-spend',
            reportId: '96ce9d01-409d-4fc8-ac97-b5c5b7422982',
            children: []
          },
          {
            id: 2,
            label: 'ILSE',
            icon: 'shopping_cart_checkout',
            routerLink: '/home/ilse-overview',
            reportId: '7eed55ea-b276-4f14-ad4e-de1f8137a6ae',
            children: []
          }
        ]
      },
      {
        id: 4,
        label: 'KPI Benchmarking',
        icon: 'waterfall_chart',
        routerLink: '/home/kpi-benchmarking',
        reportId: 'dfa540bf-9cae-4ebe-9e92-1387174b68a8',
        children: []
      }
    ],
  },
  // TRANSPORTATION: {
  //     id: 7,
  //     label: 'TRANSPORTATION',
  //     icon: 'transportation',
  //     routerLink: '/home/carrier-scorecard',
  //     reportId: 'b495b69c-bd5b-4ca0-8333-00a8dc399264',
  //     children: [
  //         {
  //             id: 1,
  //             label: 'Scorecard',
  //             icon: 'cards',
  //             children: [
  //                 {
  //                     id: 1,
  //                     label: 'Carrier Scorecard',
  //                     icon: 'cards',
  //                     routerLink: '/home/carrier-scorecard',
  //                     reportId: 'b495b69c-bd5b-4ca0-8333-00a8dc399264',
  //                     children: []
  //                 },
  //                 {
  //                     id: 2,
  //                     label: 'Lane Scorecard',
  //                     icon: 'cards',
  //                     routerLink: '/home/lane-scorecard',
  //                     reportId: '8d980b7d-e4b8-4e0d-b5a6-9c18eb062f11',
  //                     children: []
  //                 },
  //             ]
  //         },
  //         {
  //             id: 2,
  //             label: 'Service',
  //             icon: 'commute',
  //             children: [
  //                 {
  //                     id: 1,
  //                     label: 'Delivery Performance',
  //                     icon: 'commute',
  //                     routerLink: '/home/delivery-performance',
  //                     reportId: 'b1a3a26c-b0ac-4159-a3ec-e88bcfc9d73e',
  //                     children: [],
  //                 },
  //                 {
  //                     id: 2,
  //                     label: 'Transit time deep-dive',
  //                     icon: 'commute',
  //                     routerLink: '/home/deep-dive',
  //                     reportId: 'ad12de86-e834-4d9f-8c5b-083b1ee12eac',
  //                     children: [],
  //                 },
  //                 {
  //                     id: 3,
  //                     label: 'Truck Availability',
  //                     icon: 'commute',
  //                     routerLink: '/home/truck-availability',
  //                     reportId: '7737476f-abb6-4553-8335-91c844c1ee27',
  //                     children: [],
  //                 },
  //             ]
  //         },
  //         {
  //             id: 3,
  //             label: 'Transportation Cost Overview',
  //             icon: 'transit_ticket',
  //             routerLink: '/home/finance-overview',
  //             reportId: '83d7456c-3fdf-42e3-8109-c4053fbb2d36',
  //             children: []
  //         },
  //         {
  //             id: 4,
  //             label: 'Cost Improvement',
  //             icon: 'price_change',
  //             children: [
  //                 {
  //                     id: 1,
  //                     label: 'Fill Rate',
  //                     icon: 'price_change',
  //                     routerLink: '/home/fill-rate',
  //                     reportId: '6b3d9f5e-6761-4309-b363-38e921371567',
  //                     children: []
  //                 },
  //                 {
  //                     id: 2,
  //                     label: 'Freight Leakage',
  //                     icon: 'price_change',
  //                     routerLink: '/home/freight-leakage',
  //                     reportId: 'a39a46be-60f5-4262-bc81-ec25353baa43',
  //                     children: []
  //                 },
  //                 {
  //                     id: 3,
  //                     label: 'Contract Compliance',
  //                     icon: 'price_change',
  //                     routerLink: '/home/contract-compliance',
  //                     reportId: '93f7ab18-b79f-4779-9535-5caf6268746c',
  //                     children: []
  //                 },
  //                 {
  //                     id: 4,
  //                     label: 'Mode Shift',
  //                     icon: 'price_change',
  //                     routerLink: '/home/mode-shift',
  //                     reportId: 'c905b1b5-b39d-43ee-9a95-9c25088e80db',
  //                     children: []
  //                 },
  //             ]
  //         },
  //         {
  //             id: 5,
  //             label: 'Freight Sourcing',
  //             icon: 'source_environment',
  //             children: [
  //                 {
  //                     id: 1,
  //                     label: 'Bid Optimizer',
  //                     icon: 'source_environment',
  //                     routerLink: '/home/bid-optimizer',
  //                     reportId: 'a19b252e-507a-4297-a38b-3b03d4192140',
  //                     children: []
  //                 },
  //                 {
  //                     id: 2,
  //                     label: 'Rate Benchmarking',
  //                     icon: 'source_environment',
  //                     routerLink: '/home/rate-benchmarking',
  //                     reportId: 'b520e974-3ada-4d14-acba-d500be69a630',
  //                     children: []
  //                 },
  //             ]
  //         },
  //     ],
  // },
  INVENTORY: {
    id: 8,
    label: 'INVENTORY',
    icon: 'inventory_2',
    routerLink: '/home/inventory',
    reportId: 'a4fc91e7-b93c-4e6d-8ee7-d5a22f05c0e0',
    children: [],
  },
  PROCUREMENT: {
    id: 9,
    label: 'PROCUREMENT',
    icon: 'shopping_cart',
    routerLink: '/home/spend-analytics',
    reportId: 'bb96444e-8cd7-4f92-8612-95161294861f',
    children: [
      {
        id: 1,
        label: 'Spend Analytics',
        icon: 'query_stats',
        routerLink: '/home/spend-analytics',
        // reportId: 'ff601c8f-9ac4-41ea-9c8d-7e356e9eabf6',
        //https://app.powerbi.com/groups/24be496d-bc00-4324-999c-50433f497caa/reports/bb96444e-8cd7-4f92-8612-95161294861f?ctid=5b973f99-77df-4beb-b27d-aa0c70b8482c&pbi_source=linkShare
        reportId: 'bb96444e-8cd7-4f92-8612-95161294861f',
        children: []
      },
      {
        id: 2,
        label: 'Delivery Assurance',
        icon: 'package_2',
        routerLink: '/home/delivery-assurance',
        reportId: '3c03a8ac-3d2b-443c-8fdb-65d430c3caf2',
        children: []
      },
      {
        id: 3,
        label: 'ILSE',
        icon: 'planner_review',
        routerLink: '/home/ilse-overview',
        reportId: '7eed55ea-b276-4f14-ad4e-de1f8137a6ae',
        children: []
      },
      {
        id: 4,
        label: 'Supplier Ecosystem Performance',
        icon: 'captive_portal',
        routerLink: '/home/supplier-ecosystem',
        reportId: 'acb7e19f-5bd6-46f0-acab-bf940a350e8f',
        children: []
      },
      {
        id: 13,
        label: 'Category Assistant',
        icon: 'store',
        routerLink: '/home/category-assistant',
        children: [],
      },
    ]
  },
  SOE: {
    id: 7,
    label: 'S & OE',
    icon: 'settings_applications',
    routerLink: '/home/soe/soe-monitor',
    children: [
      {
        id: 1,
        label: 'Daily View',
        icon: 'calendar_view_day',
        routerLink: '',
        children: [
          {
            id: 1,
            label: 'Orders at Risk',
            icon: 'calendar_view_day',
            routerLink: '/home/soe/orders-at-risk',
            children: [],
          },
          // {
          //     id: 2,
          //     label: 'Redeployment Scenarios & Approvals',
          //     icon: 'calendar_view_day',
          //     routerLink: '/home/soe/redeployment-approvals',
          //     children: [],
          // },
          // {
          //     id: 3,
          //     label: 'Redeployment Approval Impact',
          //     icon: 'calendar_view_day',
          //     routerLink: '/home/soe/redeployment-approval-impact',
          //     children: [],
          // },
          {
            id: 4,
            label: 'Network Rebalancing',
            icon: 'calendar_view_day',
            routerLink: '/home/soe/network-rebalancing',
            reportId: 'ab62407e-5990-43cf-b86d-d79daac94c70',
            children: [],
          }
        ]
      },
      {
        id: 2,
        label: 'Weekly View',
        icon: 'calendar_view_week',
        routerLink: '/home/soe/projected-shortages',
        children: []
      },
      {
        id: 3,
        label: 'S & OE Chatbot',
        icon: '3p',
        routerLink: '/home/soe/soe-chatbot',
        children: [],
      },
    ]
  },
  SUPPLY_CHAIN_RESILIENCY: {
    id: 10,
    label: 'SUPPLY CHAIN RESILIENCY',
    icon: 'local_police',
    routerLink: '/home/supply-chain-resiliency',
    dynamicUrl: 'https://euwdsrg03rwap12.azurewebsites.net/',
    children: [
      {
        id: 1,
        label: 'Alert Details',
        icon: 'report',
        routerLink: '/home/alerts-details',
        reportId: '',
        children: []
      },
      // {
      //     id: 2,
      //     label: 'Supply Impact PowerBi',
      //     icon: 'track_changes',
      //     routerLink: '/home/supply-impact-powerBi',
      //     reportId: '3a8a7742-bb7b-4c12-b7ba-d12eee58ec57',
      //     children: []
      // },
      {
        id: 2,
        label: 'Supply Impact',
        icon: 'track_changes',
        routerLink: '/home/supply-impact',
        reportId: '',
        children: []
      },
      {
        id: 3,
        label: 'Resolutions',
        icon: 'fact_check',
        routerLink: '/home/resolutions',
        reportId: '',
        children: []
      }
    ],
  },
  CHANGE_MANAGEMENT: {
    id: 11,
    label: 'CHANGE MANAGEMENT',
    icon: 'change_circle',
    routerLink: '/home/engineering-change-management',
    reportId: '72e24b61-e3d6-4188-95c5-da6908c188e4',
    children: [
      {
        id: 1,
        label: 'Engineering Change Management',
        icon: 'track_changes',
        routerLink: '/home/engineering-change-management',
        reportId: '72e24b61-e3d6-4188-95c5-da6908c188e4',
        children: []
      },
    ],
  },
  ESG: {
    id: 12,
    label: 'ESG',
    icon: 'cloud_circle',
    routerLink: '/home/esg',
    reportId: 'be94b458-b5fb-4882-88f9-c3c0449d9147',
    children: [
      {
        id: 1,
        label: 'Product Circularity',
        icon: 'production_quantity_limits',
        routerLink: '/home/product-circularity',
        dynamicUrl: 'https://apps.powerapps.com/play/e/2e36eec8-5838-e04b-9bbc-1a26f5c7ee53/a/1c21d4fc-a78e-4214-8192-ec2b5570e9e0?tenantId=5b973f99-77df-4beb-b27d-aa0c70b8482c&source=AppSharedV3&sourcetime=1724760412396',
        children: []
      },
      {
        id: 2,
        label: 'Carbon Footprint Analyzer',
        icon: 'lightbulb',
        routerLink: '/home/carbon-footprint-analyzer',
        reportId: 'be94b458-b5fb-4882-88f9-c3c0449d9147',
        children: []
      },
    ],
  },
  FORECAST_REVIEW: {
    id: 1,
    label: 'FORECAST REVIEW',
    icon: 'table_chart_view',
    routerLink: '/home/forecast-review',
    reportId: '',
    children: [],
  },
  RETAIL: {
    id: 13,
    label: 'RETAIL',
    icon: 'storefront',
    routerLink: '/home/retail',
    reportId: '1373f3cc-024e-49d4-a961-546801c43e5d',
    children: [],
  },
  ASSISTANT2: {
    id: 13,
    label: 'SC ASSISTANT 2',
    icon: 'chat',
    routerLink: '/home/assistant2',
    reportId: '',
    children: [],
  },
  ASSISTANT3: {
    id: 13,
    label: 'SC ASSISTANT 3',
    icon: 'chat',
    routerLink: '/home/sc-chatbot',
    reportId: '',
    children: [],
  },
  // ASTERISK: {
  //     id: 15,
  //     label: 'ASTERISK',
  //     icon: 'emergency',
  //     routerLink: '/home/asterisk',
  //     dynamicUrl: 'https://asterisk-digital-solutions.ey.com/auth/login',
  //     children: [],
  // },
  // ASTERISK_BRIDGESTONE: {
  //     id: 16,
  //     label: 'ASTERISK - BRIDGESTONE',
  //     icon: 'star',
  //     routerLink: '/home/asterisk-bridgestone',
  //     dynamicUrl: 'https://asterisk-digital-solutions-bsro.ey.com/app/asterVision',
  //     children: [],
  // }
};

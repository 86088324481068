<div class="main-container">
    <div class="row wh-tms-container">
        <mat-tab-group class="wh-tms-tab-group" mat-align-tabs="left" dynamicHeight="true"
            [selectedIndex]="getSelectedIndex()" (selectedTabChange)="onTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <div class="tab-header">Recommendations</div>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <app-redeployment-approvals></app-redeployment-approvals>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <div class="tab-header">Warehouse Utilization</div>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <app-daily-warehouse-container></app-daily-warehouse-container>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <div class="tab-header">Truck Utilization</div>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <app-daily-truck-container></app-daily-truck-container>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
export const TABLE_DATA = [
    {
        scenarioTitle: 'Description', Baseline: 'As-is scenario, siloed demand allocation',
        'Network level consolidation': 'Demand allocation at network level based on plant vs capability matrix',
        'Scenario 01': 'Shutting down PL3 processing line in Florida', 'Scenario 02': 'Shutting down Arizona plant'
    },

    {
        scenarioTitle: 'Annual demand (in million pounds)', Baseline: 760.2,
        'Network level consolidation': 760.2, 'Scenario 01': 760.2, 'Scenario 02': 760.2
    },

    {
        scenarioTitle: 'Total packed (in million pounds)', Baseline: 697.9,
        'Network level consolidation': 757.1, 'Scenario 01': 756.0, 'Scenario 02': 757.1
    },
    {
        scenarioTitle: 'Shortfall (in million pounds)', Baseline: 62.3,
        'Network level consolidation': 3.1, 'Scenario 01': 4.2, 'Scenario 02': 3.1,
    },
    {
        scenarioTitle: 'Demand attainment (%)', Baseline: '92.3%',
        'Network level consolidation': '99.9%', 'Scenario 01': '99.0%', 'Scenario 02': '99.9%'
    },

    {
        scenarioTitle: 'Total SKUs', Baseline: 342,
        'Network level consolidation': 342, 'Scenario 01': 342, 'Scenario 02': 342
    },

    {
        scenarioTitle: 'Shortfall SKUs', Baseline: 173,
        'Network level consolidation': 105, 'Scenario 01': 104, 'Scenario 02': 113
    },

    {
        scenarioTitle: 'Non allocated SKUs', Baseline: 15,
        'Network level consolidation': 11, 'Scenario 01': 8, 'Scenario 02': 13
    },

    {
        scenarioTitle: 'Reallocated SKUs', Baseline: '-',
        'Network level consolidation': 35, 'Scenario 01': 37, 'Scenario 02': 35
    },
];

export const DISPLAYED_COLUMNS = [
    'scenarioTitle', 'Baseline', 'Network level consolidation', 'Scenario 01', 'Scenario 02'
];

export const SCENARIO_COLUMNS = [
    'Baseline', 'Network level consolidation', 'Scenario 01', 'Scenario 02'
];

import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-expandable-table-with-action-btn',
  templateUrl: './expandable-table-with-action-btn.component.html',
  styleUrls: ['./expandable-table-with-action-btn.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ExpandableTableWithActionBtnComponent implements OnInit {
  @Input() dataSource;
  @Input() expandedElement;
  @Input() columnsToDisplay;
  @Input() childColumnsToDisplay;
  @Input() columnHeaderMap;

  constructor() { }

  ngOnInit(): void {
  }

  toggleRow(row: any) {
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  isExpanded(row: any): boolean {
    return this.expandedElement === row;
  }

  approveRow(row: any): void {
    console.log('Row approved:', row);
  }

  rejectRow(row: any): void {
    console.log('Row rejected:', row);
  }

}

import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SideNavService } from 'src/app/services/side-nav/side-nav.service';
import { RECOMMENDATION_GROUP_TAB_NAME } from '../../constants/warehouse-utilization.constant';

@Component({
  selector: 'app-daily-wh-tms-impact-container',
  templateUrl: './daily-wh-tms-impact-container.component.html',
  styleUrls: ['./daily-wh-tms-impact-container.component.scss'],
})
export class DailyWhTmsImpactContainerComponent implements OnInit, OnDestroy {
  currentTabIndex: number;
  @Output() tabChanged = new EventEmitter<number>();
  availableTabs = RECOMMENDATION_GROUP_TAB_NAME;
  constructor(private sideNavService: SideNavService) {}
  ngOnInit(): void {
    this.currentTabIndex=0;
    this.sideNavService.setSelectedTabName(
      this.availableTabs.RECOMMENDATION
    );
  }

  getSelectedIndex(): number {
    return this.currentTabIndex;
  }
  getNewTab(newTab: number) {
    this.currentTabIndex = newTab;
  }
  ngOnDestroy(): void {
    this.currentTabIndex = 0;
  }

  onTabChange(event: any): void {
    const selectedTabIndex = event.index;
    this.sideNavService.setSelectedIndex(selectedTabIndex);
    switch (selectedTabIndex) {
      case 0:
        this.sideNavService.setSelectedTabName(
          this.availableTabs.RECOMMENDATION
        );
        break;
      case 1:
        this.sideNavService.setSelectedTabName(this.availableTabs.WAREHOUSE);
        break;
      case 2:
        this.sideNavService.setSelectedTabName(this.availableTabs.TRUCK);
        break;
    }
  }
}

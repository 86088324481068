export const SUMMARYCARD_COLORS = [
    '#0e4bac',
    '#4bac0e',
    '#DA5BB3',
    '#ac6f0e',
    '#9D7CD4',
    '#FF5A5F',
    '#188CE5',
    '#FF736A',
    '#229B9A',
    '#FDBC84',
    '#FFC107',
    '#5AC8FA',
    '#A4E866',
    '#D8BFD8',
    '#40E0D0',
    '#800000',
    '#FFD700',
    '#FF7F50',
    '#4169E1',
    '#B76E79',
    '#FF00FF',
    '#2E8B57',
    '#A9A9A9'
];

export const GRAPH_COLORS = [
    '#0e4bac',
    '#4bac0e',
    '#DA5BB3',
    '#ac6f0e',
    '#9D7CD4',
    '#FF5A5F',
    '#188CE5',
    '#FF736A',
    '#229B9A',
    '#FDBC84',
    '#FFC107',
    '#5AC8FA',
    '#A4E866',
    '#D8BFD8',
    '#40E0D0',
    '#800000',
    '#FFD700',
    '#FF7F50',
    '#4169E1',
    '#B76E79',
    '#FF00FF',
    '#2E8B57',
    '#A9A9A9'
]

export const OUTPUT_TEMP_GRAPH1_COLORS = [
    '#DA5BB3',
    '#D07200'
];

export const OUTPUT_TEMP_GRAPH2_COLORS = [
    '#8965CC',
    '#1F893F'
];

export const EXE_VIEW_GRAPH_COLORS = [
    '#D8BFD8',
    '#40E0D0',
    '#800000',
    '#FFD700',
    '#FF7F50',
    '#4169E1',
    '#B76E79',
    '#FF00FF',
    '#2E8B57',
    '#A9A9A9'
];
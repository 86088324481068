import { Component, OnInit } from '@angular/core';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';
import { ISchedulingDetailsSummaryCard } from '../../interfaces/scheduling-interface';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';

export interface ShippingTblData {
  orderId: string;
  sku: string;
  customerPriority: string;
  originalPlant: string;
  proposedPlant: string;
  quantityAtRisk: string;
  quantitySatisfied: string;
  dueDate: string;
  currentRecoveryDate: string;
  proposedRecoveryDate: string;
  costImpact: string;
  childData: childData[];
}

export interface childData {
  orderId: string;
  sku: string;
  customerPriority: string;
  originalPlant: string;
  proposedPlant: string;
  quantityAtRisk: string;
  quantitySatisfied: string;
  dueDate: string;
  currentRecoveryDate: string;
  proposedRecoveryDate: string;
  costImpact: string;
}
@Component({
  selector: 'app-prod-scheduling-approvals',
  templateUrl: './prod-scheduling-approvals.component.html',
  styleUrls: ['./prod-scheduling-approvals.component.scss']
})
export class ProdSchedulingApprovalsComponent implements OnInit {

  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  isLoading: boolean = true;
  displayLoader: boolean = false;
  isApiError: Boolean = false;
  loadView: boolean = false;

  summaryCardData: ISchedulingDetailsSummaryCard;

  expandedElement: ShippingTblData;
  detailsTable: any[] = [];
  columnsToDisplay = [
    'expandIcon',
    //'orderId',
    'SKU',
    //'customerPriority',
    'OriginalPlant',
    'RecommendedPlant',
    'QuantityAtRisk',
    'QuantitySatisfied',
    'DueDate',
    'CurrentRecoveryDate',
    'ProposedRecoveryDate',
    'costImpact',
    'approveReject'
  ];
  childColumnsToDisplay = [
    'expandIcon',
    'orderId',
    'sku',
    'customerPriority',
    'originalPlant',
    'proposedPlant',
    'quantityAtRisk',
    'quantitySatisfied',
    'dueDate',
    'currentRecoveryDate',
    'proposedRecoveryDate',
    'costImpact',
    'approveReject'
  ];

  columnHeaderMap = {
    'expandIcon': 'Details',
    //'orderId': 'Order ID',
    'SKU': 'SKU',
    //'customerPriority': 'Customer Priority',
    'OriginalPlant': 'Original Plant',
    'RecommendedPlant': 'Recommended Plant',
    'QuantityAtRisk': 'Quantity At Risk',
    'QuantitySatisfied': 'Quantity Satisfied',
    'DueDate': 'Due Date',
    'CurrentRecoveryDate': 'Current Recovery Date',
    'ProposedRecoveryDate': 'Proposed Recovery Date',
    'CostImpact': 'Cost Impact',
    'approveReject': 'Actions'
  };

  constructor(
    private schedulingDetailsService: SchedulingDetailsService,
    private readonly toasterService: ToasterWrapperService,
  ) { }

  ngOnInit(): void {
    this.getPSAData();
  }

  /**
   * function to get colors.
   * @param index card number as index
   * @returns color
   */
  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  getPSAData(): void {

    this.displayLoader = true;
    this.loadView = false;
    this.isLoading = true;

    this.schedulingDetailsService.getPSAData().subscribe(
      (response) => {
        if (response) {
          this.summaryCardData = response?.summaryCard.data

          this.detailsTable = response.detailsTable;
        }
        (error) => {
          this.toasterService.error('Error occurred while fetching data');
        }

        this.isLoading = false;
          this.displayLoader = false;
          this.loadView = true;
      }
    )
  }


}

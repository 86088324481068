import { Injectable } from '@angular/core';
import { MENU_DATA_DETAILS } from '../../constants/menu-constant-deta';
import { Breadcrumb } from '../../common/models/breadcrumb.model';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();
  breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router) { }

  navigateToPage(path: string) {
    this.router.navigate([path]).then(() => {
      this.breadcrumbs = this.getBreadcrumbs(path);
      this.breadcrumbsSubject.next(this.breadcrumbs);
    }).catch((error) => {
      console.error("Navigation failed:", error);
    });
  }

  getBreadcrumbs(path: string): Breadcrumb[] {
    this.breadcrumbs = [];
    return this.findBreadcrumbs(MENU_DATA_DETAILS, path);
  }

  findBreadcrumbs(menu: any, path: string): Breadcrumb[] {
    for (const key in menu) {
      const menuItem = menu[key];
      if (Array.isArray(menuItem.routerLink)) {
        // Match if path matches any of the routes in the array
        if (menuItem.routerLink.includes(path)) {
          this.breadcrumbs.push({ label: menuItem.label, routerLink: menuItem.routerLink[0] });
          return this.breadcrumbs;
        }
      } else {
        // Match single route path
        this.breadcrumbs.push({ label: menuItem.label, routerLink: menuItem.routerLink });
        if (menuItem.routerLink === path) {
          return this.breadcrumbs;
        }
      }
      if (menuItem.children?.length) {
        const result = this.findBreadcrumbs(menuItem.children, path);
        if (result.length) {
          return result;
        }
      }
      this.breadcrumbs.pop();
    }
    return [];
  }

  private toggleDataSource = new BehaviorSubject<any>(null);
  getToggleState = this.toggleDataSource.asObservable();

  private asISOrRecommendationToggleDataSource = new BehaviorSubject<any>(null);
  getAsIsOrRecommendationToggle = this.asISOrRecommendationToggleDataSource.asObservable();

  setToggleState(data: any) {
    this.toggleDataSource.next(data);
  }

  setasISOrRecommendationToggleState(data: any) {
    this.asISOrRecommendationToggleDataSource.next(data);
  }
}
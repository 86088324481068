import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/constants/api.urls';

@Injectable({
  providedIn: 'root'
})
export class ForecastReviewService {

  constructor(private http: HttpClient) { }

  getOutputTemplateData(payload): Observable<any> {
    return this.http.post(API_URLS.getOutputTemplateData, payload)
  }

  getOutputTemplateFilters(): Observable<any> {
    return this.http.get(API_URLS.getOutputTemplateFilters);
  }

  updateOutputTemplateData(payload): Observable<any> {
    return this.http.post(API_URLS.updateOutputTemplateData, payload, { observe: 'response' })
  }

  getExecutiveViewData(payload): Observable<any> {
    return this.http.post(API_URLS.getExecutiveViewData, payload)
  }
}

<div class="qty-at-risk-table-container">

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="qtyRiskTable mat-elevation-z8">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
      <ng-container *ngIf="column !== 'action'; else action">
        <th mat-header-cell *matHeaderCellDef>{{column=='Plant ID' ? 'Plant' : column}}</th>
        <ng-container *ngIf="column=='SKU'">
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)"><a
              class="sku-link">{{element[column]}}</a></td>
        </ng-container>
        <ng-container *ngIf="column!=='SKU'">
          <td mat-cell *matCellDef="let element">{{element[column]}}</td>
          <!-- <td mat-cell *matCellDef="let element">{{getFormattedData(element,column)}}</td> -->
        </ng-container>

      </ng-container>
      <ng-template #action>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon (click)="expandedElement = expandedElement === element ? null : element">{{expandedElement ===
            element
            ? 'remove' :
            'add'}}</mat-icon>
        </td>
      </ng-template>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <table mat-table [dataSource]="element.Values" class="inner-table mat-elevation-z8">
            <ng-container matColumnDef="{{ column }}" *ngFor="let column of innerDisplayedColumns">
              <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
              <ng-container *ngIf="(column=='SKU')">
                <td mat-cell *matCellDef="let element"><span style="visibility: hidden;">11111</span></td>
              </ng-container>
              <ng-container *ngIf="(column!=='SKU')">
                <td mat-cell *matCellDef="let element"><span>{{element[column]}}</span></td>
              </ng-container>
            </ng-container>
            <tr mat-row *matRowDef="let element; columns: innerDisplayedColumns"></tr>
          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
      [class.expanded-row]="expandedElement === element"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>

  <!-- No Record found template start -->
  <div *ngIf="!dataSource?.length">
    <table>
      <tr *ngIf="!dataSource?.length">
        <td class="no-record-data d-flex align-items-center" [attr.colspan]="6">No records found for the applied
          filter!</td>
      </tr>
    </table>
  </div>
  <!-- No Record found template end -->
</div>
<div class="modal-container">
    <div class="title-container">
        <h2 mat-dialog-title class="title">{{selectedToggle ? toggleBtn?.ORDERS_AT_RISK :
            toggleBtn?.SKU_PLANT_PROJECTIONS }} Details</h2>
        <span class="close-icon"><mat-icon mat-dialog-close>close</mat-icon></span>
    </div>
    <mat-dialog-content class="mat-typography">
        <div class="content-container">
            <div class="content-header d-flex align-items-center">
                <div class="header d-flex col-md-8" *ngIf="selectedFilters?.length > 0">
                    <div *ngFor="let item of selectedFilters; let lastRecord=last">
                        <span class="sub-header" *ngIf="item.value">{{item.name}}<span
                                class="column-separator">:</span>{{ item.name!=rddLabel? item.value : item.value | date}}</span>
                        <span class="separator" *ngIf="!lastRecord && item.value">,</span>
                    </div>
                </div>
                <div class="action-item col-md-4 d-flex">
                    <mat-button-toggle-group (change)="getSelectedToggle($event)" class="orders-toggle-btn">
                        <mat-button-toggle value="true" [checked]="true">
                            {{toggleBtn?.ORDERS_AT_RISK}}
                        </mat-button-toggle>
                        <mat-button-toggle value="false"> {{toggleBtn?.SKU_PLANT_PROJECTIONS}} </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
            <div class="content">
                <div class="sku-table" *ngIf="modalTableData?.data?.length > 0">
                    <app-mat-table [dataSource]="modalTableData?.data" [displayedColumns]="modalTableData?.columns"
                        [headers]="modalTableData?.headers" [isStickyHeader]="true" [htmlView]="viewPage" [colorClassList]="skuTableColorClass"
                        [showPaginator]="showPaginator"
                        ></app-mat-table>
                </div>
                <div class="sku-table d-flex justify-content-center error-container" *ngIf="noTableData">
                    <div class="row error-label"> No Data found for the selected filters.</div>
                </div>
                <div *ngIf="displayLoader" class="d-flex justify-content-center loader">
                    <mat-spinner class="loading"></mat-spinner>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
<div *ngIf="filtersLoaded; else loading">
    <div class="right-sidebar" id="right-sidebar">
        <button mat-icon-button class="refresh-btn" (click)="resetAllFilters()">
            <mat-icon class="cached-icon" matTooltip="Reset filters">cached</mat-icon>
        </button>
        <ng-container *ngFor="let filter of filters">
            <mat-form-field class="select-field" appearance="outline"
                *ngIf="(filter.type === 'dropdown' && filter.dropdownType!='multiselect')">
                <mat-label>{{ filter.label }}</mat-label>
                <mat-select [(ngModel)]="filter.selectedValue" (selectionChange)="onDropdownChange(filter)">
                    <!-- Search Input -->
                    <mat-form-field class="select-search" appearance="outline">
                        <mat-icon matPrefix>search</mat-icon>
                        <input matInput placeholder="Search {{ filter.label }}" [(ngModel)]="filter.searchValue"
                            (input)="filterOptions(filter)" />
                    </mat-form-field>

                    <mat-option *ngFor="let option of filter.filteredOptions" [value]="option.value">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- code added for multiselect dropdown start -->
            <mat-form-field class="select-field" appearance="outline"
                *ngIf="(filter.type === 'dropdown' && filter.dropdownType==='multiselect')">
                <mat-label>{{ filter.label }}</mat-label>
                <mat-select [(ngModel)]="filter.selectedValue" (closed)="onDropdownChange(filter)" multiple>
                    <mat-select-trigger>
                        {{filter.selectedValue ? filter.selectedValue[0] : ''}}
                        <span *ngIf="filter.selectedValue?.length > 1" class="example-additional-selection">
                            (+{{filter.selectedValue.length - 1}} {{filter.selectedValue?.length === 2 ? 'other'
                            :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <!-- Search Input -->
                    <mat-form-field class="select-search" appearance="outline">
                        <mat-icon matPrefix>search</mat-icon>
                        <input matInput placeholder="Search {{ filter.label }}" [(ngModel)]="filter.searchValue"
                            (input)="filterOptions(filter)" />
                    </mat-form-field>
                    <mat-option *ngFor="let option of filter.options" [value]="option.value">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- code added for multiselect dropdown end -->

            <mat-form-field class="select-field" appearance="outline" *ngIf="filter.type === 'dateRange'">
                <mat-label>{{ filter.label }}</mat-label>
                <mat-date-range-input [rangePicker]="picker" [min]="minRequiredDeliveryDate"
                    [max]="maxRequiredDeliveryDate">
                    <input matStartDate placeholder="Start date" [(ngModel)]="filter.startDate"
                        (dateChange)="onDateRangeChange(filter)">
                    <input matEndDate placeholder="End date" [(ngModel)]="filter.endDate"
                        (dateChange)="onDateRangeChange(filter)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </ng-container>
        <button mat-raised-button class="apply-btn" (click)="onApply()">Apply</button>
    </div>
</div>
<ng-template #loading>
    <p class="loadingFilterMsg">{{loadingMessage}}</p>
</ng-template>
<div class="container">
    <div class="row">
      <div class="col-12">
        <div class="map-card">
            <div class="header-div">
              <div class="title">
                Selected Filters
              </div>
            </div>
            <div class="chart-container">
            </div>
          </div>
        <!-- Content for the selected scenario -->
      </div>
      <div class="col-12">
        <div class="map-card">
            <div class="header-div">
                <div class="title">
                    Scenario
                  </div>
            </div>
            <div class="chart-container">
            </div>
          </div>
        <!-- Content for the scenario heading -->
      </div>
      <div class="col-md-6">
        <div class="map-card">
          <div class="header-div">
            <div class="title">
              Demand Allocation: Network model vs Scenario
            </div>
          </div>
          <div class="chart-container">
            <high-chart [enableLegend]="true" [enabledExporting]="false"
              [seriesData]="demandAllocationData" [chartHeight]="280" [enableXAxisScrollbar]="false"
              [isBarClickable]="false" >
            </high-chart>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="map-card">
            <div class="header-div">
              <div class="title">
                PL Allocation details
              </div>
            </div>
            <div class="chart-container">
            </div>
          </div>
        <!-- Placeholder for Graph 2 -->
      </div>
      <div class="col-md-6">
        <div class="map-card">
            <div class="header-div">
              <div class="title">
                Processing line output
              </div>
            </div>
            <div class="chart-container">
            </div>
          </div>
        <!-- Placeholder for Graph 3 -->
      </div>
      <div class="col-md-6">
        <div class="map-card">
            <div class="header-div">
              <div class="title">
                Packing line output
              </div>
            </div>
            <div class="chart-container">
            </div>
          </div>
        <!-- Placeholder for Graph 4 -->
      </div>
    </div>
  </div>

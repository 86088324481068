import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AssistantService } from 'src/app/services/assistant/assistant.service';
import { ProfilePicService } from 'src/app/services/profile-picture/profile-picture.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    loggedInUser: string;
    userRoles = ['Admin'];
    defaultuserRole: any;
    userInput: string = '';
    isDisabled: boolean = false;
    myControl = new FormControl();
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
    filteredOptions: Observable<string[]> = new Observable<string[]>();
    msgOptions: any;
    hasOptions: boolean = false;
    searchKeys = [
        "shortfall",
        "allocation plan",
        "impact",
        "SKUs",
        "processing line",
        "utilization",
        "reallocation plan",
        "shutdown",
        "demand",
        "CMOs",
        "backlog",
        "SKU",
        "unfulfilled backlog",
        "revised backlog",
        "delayed shipment",
        "delays",
        "lane",
        "root causes",
        "loading delay",
        "unloading delay",
        "Non - primary carrier",
        "Capacity constraint",
        "sales order",
        "deficit",
        "Qty",
        "carrier",
        "delay reasons",
        "recommended solution"
    ];
    @Input() title: string;
    loggedInEmail: string;
    profilePicUrl: any;
    msalAccount: any;
    userInitials: any;

    constructor(
        private msalService: MsalService,
        private router: Router,
        private assistantService: AssistantService,
        private profilePicService: ProfilePicService,
        private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.initializeFilterOptions();
        this.assistantService.getMsgOptions().subscribe((response) => {
            if (response && response.questions) {
                this.msgOptions = response.questions;
            } else {
                this.msgOptions = [];
                console.error('No data received for msgOptions.');
            }
        });
        const activeAccount = this.msalService.instance.getActiveAccount();
        if (activeAccount) {
            this.msalAccount = activeAccount;
        }
        this.defaultuserRole = localStorage.getItem('role') ?? this.userRoles[0];
        this.loggedInUser = this.msalAccount.name.split('.').join(' ');
        this.loggedInEmail = this.msalAccount.username;
        this.getProfilePic();
    }

    logout() {
        sessionStorage.removeItem('tsqExpressions');
        localStorage.removeItem('role');
        this.router.navigate(["/logout"]);
    }
    userDefinedTabs(role = 'VP of Operations') {
        localStorage.setItem('role', role);
        location.reload()

    }

    gotoMainPage(): void {
        this.router.navigate(['/home/mps-overview']);
    }

    initializeFilterOptions() {
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
    }
    private _filter(value: string | null): string[] {
        if (!value) {
            this.hasOptions = false;
            return [];
        }
        const filterValue = value.toLowerCase();
        const containsAnyKeyword = this.searchKeys.filter(key => filterValue.includes(key));
        var filtered = [];
        if (containsAnyKeyword.length > 0) {
            filtered = this.msgOptions.filter(option => option.toLowerCase().includes(containsAnyKeyword));
        } else {
            filtered = this.msgOptions.filter(option => option.toLowerCase().includes(filterValue));
        }
        this.hasOptions = (this.myControl.dirty || this.myControl.touched) && filtered.length > 0;
        return this.hasOptions ? filtered : [];
    }

    sendMessage() {
        if (this.myControl.value) {
            this.router.navigate(['/home/sc-assistant'], { queryParams: { msg: this.myControl.value } });
        }
    }

    getProfilePic(): void {
        this.profilePicService.getProfilePic(this.msalAccount.username)
            .subscribe((response) => {
                if (response instanceof Blob && response.size > 0 && response.type === 'image/jpeg') {
                    const imageUrl = URL.createObjectURL(response);
                    this.profilePicUrl = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
                } else {
                    this.userInitials = this.msalAccount.name.split(' ').map(word => word[0]).join('');
                }
            },
                (error: Error) => {
                    console.error('Error fetching profile picture:', error.message);
                    console.error(error);
                }
            );
    }
}

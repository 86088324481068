import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { Subscription } from 'rxjs';
import { IPSPopUpRequest } from '../../interfaces/scheduling-interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plant-details-model',
  templateUrl: './plant-details-model.component.html',
  styleUrls: ['./plant-details-model.component.scss']
})
export class PlantDetailsModelComponent implements OnInit {
  displayLoader: boolean = false;
  showPaginator: boolean = false;
  modalTableData = { data: null, columns: null, headers: null };
  subscriptions$: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private schedulingDetailsService: SchedulingDetailsService,
    private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.getModalTableData();
  }

  getModalTableData() {
    this.displayLoader = true;
    this.modalTableData = { data: null, columns: null, headers: null };
    const payload: IPSPopUpRequest = {
      //materialID: this.data['materialID'],
      plantID: this.data['plantID'],
    };
    this.subscriptions$.push(
      this.schedulingDetailsService.getPSPopUpData(payload).subscribe(
        (response: any[]) => {
          if (response && response.length) {
            let plantsHeaders: { [key: string]: string } = {};
            const plantsColumns = Object.keys(response[0]);
            plantsColumns.forEach(column => {
              plantsHeaders[column] = column;
            });
            this.modalTableData = {
              data: this.transformData(response),
              columns: plantsColumns,
              headers: plantsHeaders,
            };
          }
          else {
            this.modalTableData = { data: [], columns: [], headers: [] };
            this.toasterService.info('No data available for the selected plant');
          }
          this.displayLoader = false;
        },
        (error) => {
          this.toasterService.error(
            'Error occurred while fetching api response'
          );
          this.displayLoader = false;
        }
      )
    );
  }

  transformData(inputData: any[]): any[] {
    return inputData.map((item, index) => {
      const cellColors: { [key: string]: string } = {};
      const skuPlant = item.SKU_Plant || "";
      const extractedPlantId = skuPlant.split('-')[1];
      for (const key in item) {
        if (extractedPlantId === this.data.plantID) {
          cellColors[key] = "green";
        }
      }
      return {
        id: index + 1,
        cellColors: cellColors,
        ...Object.keys(item).reduce((acc, key) => {
          acc[key] = item[key];
          return acc;
        }, {})
      };
    });
  }
}
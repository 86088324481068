export const SKU_TABLE_NAME = {
  QUANTITY: 'Forecast Qty Vs Order Qty, Projected OTIF by SKU',
  VALUE: 'Forecast Value Vs Order value, Projected OTIF by SKU',
};

export const RDD_CHART_NAME = {
  QUANTITY: 'Forecast Qty Vs Order Qty, Projected OTIF by RDD',
  VALUE: 'Forecast Value Vs Order Value, Projected OTIF by RDD',
};

export const ALL_LABEL = 'All';

export const ORDERS_AT_RISK_LINE_CHART_NAMES = [
  'Projected Otif',
  'Projected Fill Rate',
];

export const PLANT_ID_LABEL = 'DC ID';

export const ORDERS_AT_RISK_CHART_COLORS = [
  '#082C65',
  '#1777CF',
  '#DA5BB3',
  '#9D7CD4',
  '#E79ECD',
  '#FF736A',
];

export const MIN_X_AXIS_LIMIT = 14;

export const Y_LABEL = {
  QUANTITY: 'Quantity',
  VALUE: 'Value',
};

export const SKU_TABLE_HEADER = {
  QUANTITY: 'Qty',
  VALUE: 'Value',
};

export const ORDERS_AT_RISK_GRAPH_NAME = {
  OTIF_BY_CUSTOMER: 'OTIF by Customer',
  OTIF_BY_RDD: 'OTIF by RDD',
  OTIF_BY_LOCATION: 'OTIF by Location',
  OTIF_BY_SKU: 'materialID',
};

export const SKU_QUANTITY_TABLE_COLUMN = [
  {
    columnDef: 'Material_ID',
    header: 'SKU',
  },
  {
    columnDef: 'Forecast Qty',
    header: 'Forecast Qty',
  },
  {
    columnDef: 'Sales Order Qty',
    header: 'Sales Order Qty',
  },
  {
    columnDef: 'Inventory Qty',
    header: 'Inventory Qty',
  },
  {
    columnDef: 'Projected Otif',
    header: 'Projected OTIF',
  },

  {
    columnDef: 'Projected Fill Rate',
    header: 'Projected Fill Rate',
  },
];

export const SKU_VALUE_TABLE_COLUMN = [
  {
    columnDef: 'Material_ID',
    header: 'SKU',
  },
  {
    columnDef: 'Forecast Qty',
    header: 'Forecast Value',
  },
  {
    columnDef: 'Sales Order Qty',
    header: 'Sales Order Value',
  },
  {
    columnDef: 'Inventory Qty',
    header: 'Inventory Value',
  },
  {
    columnDef: 'Projected Otif',
    header: 'Projected OTIF',
  },
  {
    columnDef: 'Projected Fill Rate',
    header: 'Projected Fill Rate',
  },
];

export const PLANT_LIST = [
  { name: 'All', value: 'all' },
  { name: 'Arizona (CMO1)', value: 'arizona' },
  { name: 'Ohio (CMO2)', value: 'ohio' },
  { name: 'California', value: 'california' },
  { name: 'Texas', value: 'texas' },
  { name: 'Florida', value: 'florida' },
  { name: 'Michigan', value: 'michigan' },
];

export const SKU_CATEGORY = [
  { name: 'All', value: 'all' },
  { name: 'A', value: 'A' },
  { name: 'B', value: 'B' },
  { name: 'C', value: 'C' },
];

export const CUSTOMER_IMPORTANCE = [
  { name: 'All', value: 'all' },
  { name: 'High Priority', value: 'High Priority' },
  { name: 'Low Priority', value: 'Low Priority' },
  { name: 'Medium Priority', value: 'Medium Priority' },
];

export const PROJECTED_OTIF_BY_CUSTOMER = [
  {
    name: 'Forecast Qty',
    type: 'column',
    color: '#DA5BB3',
    data: [
      ['C0000001', 56531],
      ['C0000002', 79602],
      ['C0000003', 79602],
      ['C0000004', 79602],
      ['C0000005', 55068],
      ['C0000006', 48598],
      ['C0000007', 50424],
      ['C0000008', 42738],
      ['C0000011', 16531],
      ['C0000012', 19602],
      ['C0000013', 19602],
      ['C0000014', 19602],
      ['C0000015', 15068],
      ['C0000016', 18598],
      ['C0000017', 10424],
      ['C0000018', 12738],
      ['C0000021', 56531],
      ['C0000022', 79602],
      ['C0000023', 79602],
      ['C0000024', 79602],
      ['C0000025', 55068],
      ['C0000026', 48598],
      ['C0000027', 50424],
      ['C0000028', 42738],
      ['C0000031', 16531],
      ['C0000032', 19602],
      ['C0000033', 19602],
      ['C0000034', 19602],
      ['C0000035', 15068],
      ['C0000036', 18598],
      ['C0000037', 10424],
      ['C0000038', 12738],
    ],
    graphName: ORDERS_AT_RISK_GRAPH_NAME.OTIF_BY_CUSTOMER,
  },
  {
    name: 'Sales Order Qty',
    type: 'column',
    color: '#8965CC',
    data: [
      ['C0000001', 57326],
      ['C0000002', 67791],
      ['C0000003', 42551],
      ['C0000004', 44584],
      ['C0000005', 44504],
      ['C0000006', 48498],
      ['C0000007', 50624],
      ['C0000008', 42838],
      ['C0000011', 16531],
      ['C0000012', 19602],
      ['C0000013', 19602],
      ['C0000014', 19602],
      ['C0000015', 15068],
      ['C0000016', 18598],
      ['C0000017', 10424],
      ['C0000018', 12738],
    ],
    graphName: ORDERS_AT_RISK_GRAPH_NAME.OTIF_BY_CUSTOMER,
  },

  {
    name: 'Projected Otif',
    type: 'line',
    yAxis: 1,
    color: '#FFFFFF',
    data: [
      ['C0000001', 57],
      ['C0000002', 67],
      ['C0000003', 42],
      ['C0000004', 44],
      ['C0000005', 41],
      ['C0000006', 48],
      ['C0000007', 50],
      ['C0000008', 78],
      ['C0000011', 16],
      ['C0000012', 96],
      ['C0000013', 2],
      ['C0000014', 60],
      ['C0000015', 18],
      ['C0000016', 98],
      ['C0000017', 24],
      ['C0000018', 38],
    ],
    graphName: ORDERS_AT_RISK_GRAPH_NAME.OTIF_BY_CUSTOMER,
  },
];

// export const PROJECTED_OTIF_BY_SKU = [
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029278',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '12.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '99%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '56%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '38.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '32.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '22.66%',
//   },
//   {
//     Material_ID: 'M80029279',
//     'Forecast Qty': '19,01,989',
//     'Sales Order Qty': '20,41,241',
//     'Inventory Qty': '1,04,698',
//     Projected_OTIF: '42.66%',
//   },
// ];

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';
import { FilterDataService } from 'src/app/services/filter-data/filter-data.service';
import { SideNavService } from 'src/app/services/side-nav/side-nav.service';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  menuList: any[] = [];
  openSidebar: boolean = false;
  isSubmenuOpen: boolean = false;
  searchTerm: string = '';
  filteredMenuList: any[] = [];
  isHovered: boolean = false;
  isRightSidenavOpen = false;
  private routerSubscription: Subscription;
  selectedIndex: number;
  currentRoute: string = '';
  currentTabName: string = 'tab1';

  @Input() set menuData(data: any) {
    if (data) {
      this.menuList = data;
    }
  }

  constructor(private router: Router,
    private sideNavService: SideNavService,
    private breadcrumbService: BreadcrumbService,
    private filterDataService: FilterDataService) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_event: NavigationEnd) => {
        this.menuList;

        this.currentRoute = _event.urlAfterRedirects;
        this.updateFilters();
      });
  }

  ngOnInit() {
    if (this.menuList && Array.isArray(this.menuList)) {
      this.filteredMenuList = [...this.menuList];
    } else {
      console.error('MenuList is not an array or is undefined.');
    }

    this.sideNavService.isRightSidenavOpen$.subscribe(state => {
      this.isRightSidenavOpen = state;
    });

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Close the right sidebar on route change
        this.sideNavService.closeRightSidebar();
      }
    });

    this.sideNavService.selectedIndex$.subscribe((tabIndex) => {
      this.currentTabName = `tab${tabIndex + 1}`;
      this.updateFilters();
    });
  }

  private updateFilters(): void {
    this.sideNavService.setFiltersForCurrentRoute(this.currentRoute, this.currentTabName);
  }

  toggleRightSidebar(): void {
    this.isRightSidenavOpen = !this.isRightSidenavOpen;
  }

  filterMenuList() {
    const term = this.searchTerm.toLowerCase();
    if (term.trim() === '') {
      // Reset to original menu list if search term is cleared
      this.filteredMenuList = [...this.menuList];
    } else {
      // Apply filtering
      this.filteredMenuList = this.includeParents(this.menuList, term);
    }
  }

  includeParents(items: any[], term: string): any[] {
    const result: any[] = [];
    function findMatchingItems(itemList: any[]): boolean {
      let hasMatch = false;
      for (const item of itemList) {
        const itemMatches = item.label.toLowerCase().includes(term);
        const childrenMatch = item.children ? findMatchingItems(item.children) : false;
        if (itemMatches || childrenMatch) {
          // Add only leaf nodes (nodes without children) to the result
          if (!item.children || item.children.length === 0) {
            result.push(item);
          }
          hasMatch = true;
        }
      }
      return hasMatch;
    }
    findMatchingItems(items);
    return result;
  }

  toggleSubmenuOpen(open: boolean) {
    this.isSubmenuOpen = open;
    this.openSidebar = open;
  }

  toggleSidebar(open: boolean) {
    if (!this.isSubmenuOpen) {
      this.openSidebar = open;
    } else {
      this.openSidebar = open;
    }
  }

  onMouseLeave() {
    // Close sidebar only if no submenu is open
    if (!this.isSubmenuOpen) {
      this.openSidebar = false;
    }
  }

  ngOnDestroy() {
    // Unsubscribe from the router events
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  onFilterApplied(filteredData: any) {
    this.filterDataService.updateFilterData(filteredData);
  }

  onToggleApplied($event) {
    this.breadcrumbService.setToggleState($event);
  }

}
<div [ngClass]="isStickyHeader ? 'sticky-table-container' : 'table-container'">
    <table mat-table [dataSource]="dataSource">
        <!-- Dynamic Column Generation -->
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef>{{ headers[column] }}</th>

            <!-- Apply dynamic background color based on cellColors -->
            <ng-container *ngIf="
          htmlView != soeViews?.SKU_PROJECTIONS &&
          htmlView != soeViews?.WAREHOUSE_UTILIZATION
        ">
                <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.cellColors?.[column] || 'inherit', 
                'color': getFontColor(element.cellColors?.[column]) || 'inherit'}">
                    {{ element[column] }}
                </td>
            </ng-container>
            <ng-container *ngIf="
          htmlView == soeViews?.SKU_PROJECTIONS ||
          htmlView == soeViews?.WAREHOUSE_UTILIZATION
        ">
                <td mat-cell *matCellDef="let element" [ngClass]="getColor(element[column])">
                    {{ element[column] }}
                </td>
            </ng-container>
        </ng-container>

        <!-- Header Row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: isStickyHeader"></tr>

        <!-- Data Rows -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></tr>
    </table>

    <!-- table pagination start -->
    <ng-container *ngIf="showPaginator">
        <mat-paginator [pageSize]="5"></mat-paginator>
    </ng-container>
    <!-- table pagination end -->
</div>
<div class="main-container">
    <!-- Loader start -->
    <div *ngIf="displayLoader" class="spinner-overlay">
        <app-loader [isLoading]="displayLoader" [message]="loadingMessage"></app-loader>
    </div>
    <!-- Loader end -->

    <!-- Error message container start -->
    <div *ngIf="isApiError" class="spinner-overlay">
        <app-loader [isLoading]="isApiError" [message]="apiErrorMessage"></app-loader>
    </div>
    <!-- Error message container end -->

    <!-- Daily warehouse chart representation start -->
    <div class="daily-wh-chart-container" *ngIf="!displayLoader && !isApiError">
        <app-warehouse-utilization [wmsSummaryCardData]="wmsSummaryCardData"
            [spaceUtilizationData]="spaceUtilizationData" [utilizedDCCapacityChartData]="utilizedDCCapacityChartData"
            [forecastedDCUtilizationTableData]="forecastedDCUtilizationTableData"
            [ioShipmentChartData]="ioShipmentChartData" [showIOShipmentChart]="showIOShipmentChart"
            [showIOShipmentChartApiError]="showIOShipmentChartApiError" [selectedDC]="selectedDC"
            (emitSelectedDCEvent)="getIOShipmentData($event)"
            (emitUtilizationTableRowClickEvent)="loadSelectedDcData($event)"></app-warehouse-utilization>
    </div>
    <!-- Daily warehouse chart representation start -->
</div>
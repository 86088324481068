<div class="chat-container">
    <div class="message-container">
        <div *ngFor="let message of messages">
            <div class="message-row">
                <ng-container *ngIf="message.sender === 'user'">
                    <div class="circle">
                        <img *ngIf="profilePicUrl" [src]="profilePicUrl" class="profile-pic" alt="Profile Picture" />
                        <span *ngIf="!profilePicUrl" class="initials">{{ userInitials }}</span>
                    </div>
                    <div class="user-message">
                        <div class="user-name">{{ loggedInUser }}</div>
                        <div class="message"> {{message.text }} </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="message.sender === 'bot'">
                    <div class="circle">
                        <img src="../../../../assets/images/smart-toy.jpg">
                    </div>
                    <mat-expansion-panel class="bot-message" hideToggle [@.disabled]="true"
                        (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <div class="user-name">Agent</div>
                            <ng-container *ngIf="!isTableDataEmpty(message.tableData); else noTableData">
                                <div [innerHTML]="message.text"></div>
                                <table mat-table [dataSource]="message.tableData.rows" class="mat-elevation-z8"
                                    style="width: 100%;">
                                    <ng-container [matColumnDef]="column"
                                        *ngFor="let column of message.displayedColumns">
                                        <th mat-header-cell *matHeaderCellDef>{{ transformHeader(column) }}</th>
                                        <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="message.displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: message.displayedColumns;"></tr>
                                </table>
                            </ng-container>
                            <ng-template #noTableData>
                                <div [innerHTML]="message.text"></div>
                            </ng-template>
                            <div class="more-info"
                                *ngIf="message.showPowerBi || message.showChart || message.showAngularScreen">
                                <mat-icon class="arrow-icon" *ngIf="!panelOpenState">arrow_right</mat-icon>
                                <mat-icon class="arrow-icon" *ngIf="panelOpenState">arrow_drop_down</mat-icon>
                                <span class="expansion-text">
                                    {{panelOpenState ?
                                    'More Info - Click to Close' :
                                    'More Info - Click to Open'}}</span>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="row" *ngIf="message.showPowerBi">
                            <div class="col" class="powerBi-dashboard"
                                *ngFor="let data of message.powerBiData; let i = index">
                                <powerBi [embedUrl]="data.embedUrl" [id]="data.id" [accessToken]="data.accessToken"
                                    [reportSection]="data.reportSection"></powerBi>
                            </div>
                        </div>
                        <div class="row" *ngIf="message.showChart">
                            <div class="col" *ngFor="let data of chartDataArray; let i = index">
                                <div class="chrt-card">
                                    <div class="header-div">
                                        <span class="title">{{data.chartTitle}}</span>
                                    </div>
                                    <div class="chart-div">
                                        <high-chart [enableLegend]="true" [yAxisTickAmount]="5"
                                            [xAxisLabel]="data.xAxisLabel" [yAxisLabel]="data.yAxisLabel"
                                            [seriesData]="data.chartData" [chartHeight]="250"
                                            [stacking]="data.stacking">
                                        </high-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="angular-screen" *ngIf="message.showAngularScreen">
                            <ng-container *ngComponentOutlet="message.angularComponent"></ng-container>
                        </div>
                    </mat-expansion-panel>
                </ng-container>
            </div>
            <div class="buffer-wrapper">
                <ng-container *ngIf="message.showProgressBar">
                    <div class="circle">
                        <img src="../../../../assets/images/smart-toy.jpg">
                    </div>
                    <div class="assistant-loader-container">
                        <p class="assistant-loader-text">Processing </p>
                        <div class="assistant-loader">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="input-container">
        <mat-form-field class="sendMsg-input" appearance="outline">
            <input matInput [(ngModel)]="userInput" (keyup.enter)="sendMessage()" autocomplete="off"
                [disabled]="isDisabled" placeholder="Type a message...">
            <button matSuffix mat-icon-button class="chat-send" (click)="sendMessage()">
                <mat-icon>send</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>
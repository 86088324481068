import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
    selector: 'app-dynamic-iframe-loader',
    templateUrl: './dynamic-iframe-loader.component.html',
    styleUrls: ['./dynamic-iframe-loader.component.scss']
})
export class DynamicIframeLoaderComponent implements OnInit {
    dynamicSource: string;
    isDigitalTwinUrl: boolean;
    isLoading: boolean = true;
    showIframe: boolean = true;

    constructor(private router: Router,
        private utilsService: UtilsService,
        private authService: AuthService) {
        this.displayIframe();
    }

    ngOnInit(): void {
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
            if (this.router.url.indexOf('digital-twin') > -1) {
                window.open(this.dynamicSource, '_blank', 'toolbar=0,location=0,menubar=0');
            }
        }, 1000);
    }

    displayIframe() {
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
        }, 4500);
        const menuData = this.utilsService.menuData;
        const currentRoute = this.router.url;
        const dynamicData = this.getDynamicUrl(menuData, currentRoute);
        this.isDigitalTwinUrl = dynamicData.isDigitalTwinUrl;
        this.dynamicSource = dynamicData.dynamicUrl;
    }

    getDynamicUrl(menuData, currentRoute): any {
        let dynamicUrl = '';
        let isDigitalTwinUrl = '';
        const accessToken = this.authService.token;
        for (const menu of menuData) {
            if (menu.routerLink === currentRoute) {
                dynamicUrl = menu.dynamicUrl;
                isDigitalTwinUrl = menu.isDigitalTwinUrl;
                // if (menu.routerLink === '/home/asterisk') {
                //     this.showIframe = false;
                //     window.open("https://asterisk-digital-solutions.ey.com/auth/login?token=" + accessToken, '_blank');
                //     this.router.navigate(['/home/mps-overview']);
                // }
                // else if (menu.routerLink === '/home/asterisk-bridgestone') {
                //     this.showIframe = false;
                //     window.open('https://asterisk-digital-solutions-bsro.ey.com/app/asterVision', '_blank');
                //     this.router.navigate(['/home/mps-overview']);
                // }
                // else 
                if (menu.routerLink === '/home/supply-chain-resiliency') {
                    this.showIframe = false;
                    window.open(menu.dynamicUrl, '_blank');
                    this.router.navigate(['/home/mps-overview']);
                }
                this.showIframe = true;

                break;
            } else {
                if (menu.children) {
                    for (const subMenu of menu.children) {
                        if (subMenu.routerLink === currentRoute) {
                            dynamicUrl = subMenu.dynamicUrl;
                            isDigitalTwinUrl = subMenu.isDigitalTwinUrl;
                            if (subMenu.routerLink === '/home/demand-forecasting-tool') {
                                this.showIframe = false;
                                window.open(subMenu.dynamicUrl, '_blank');
                                this.router.navigate(['/home/otif-dashboard']);
                            }
                            this.showIframe = true;
                            break;
                        } else {
                            if (subMenu.children) {
                                for (const child of subMenu.children) {
                                    if (child.routerLink === currentRoute) {
                                        dynamicUrl = child.dynamicUrl;
                                        isDigitalTwinUrl = child.isDigitalTwinUrl;
                                        break;
                                    }
                                    else {
                                        if (child.children) {
                                            for (const children of child.children) {
                                                if (children.routerLink === currentRoute) {
                                                    dynamicUrl = children.dynamicUrl;
                                                    isDigitalTwinUrl = children.isDigitalTwinUrl;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return { dynamicUrl, isDigitalTwinUrl };
    }

}

import { Component, OnInit } from '@angular/core';
import { DISPLAYED_COLUMNS, SCENARIO_COLUMNS, TABLE_DATA } from './constants/tabledata.constant';

@Component({
  selector: 'app-compare-scenario',
  templateUrl: './compare-scenario.component.html',
  styleUrls: ['./compare-scenario.component.scss']
})
export class CompareScenarioComponent implements OnInit {

  displayedColumns = DISPLAYED_COLUMNS;
  scenarioColumns = SCENARIO_COLUMNS;
  tableData = TABLE_DATA;
  
  constructor() { }

  ngOnInit(): void {
  }

  shouldHighlight(column: string, value: any): boolean {
    const scenario02Values = [757.1, 3.1, '99.9%', 35]; // Values for Scenario 02
    const scenario01Values = [104, 8]; // Values for Scenario 01
  
    return (column === 'Scenario 02' && scenario02Values.includes(value)) ||
           (column === 'Scenario 01' && scenario01Values.includes(value));
  }
  
  

}

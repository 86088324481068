<div *ngIf="displayLoader" class="spinner-overlay">
    <app-loader [isLoading]="displayLoader" [message]="loadingMessage"></app-loader>
</div>
<div *ngIf="isApiError" class="spinner-overlay">
    <app-loader [isLoading]="isApiError" [message]="apiErrorMessage"></app-loader>
</div>

<div class="main-container" *ngIf="loadView">

    <div class="row">
        <div class="col" *ngFor="let data of productionSummaryCardData; let i = index">
            <div class="card card-stats mb-4 mb-xl-0 summary-card production-card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <span class="card-title">{{ data.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="score-txt">
                    <div class="score" [ngStyle]="{
                background: gradientColors(i),
                'background-clip': 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
              }">
                        <span>{{ data.currentYear }}</span>
                    </div>
                    <div class="bench-score" [style.visibility]="data.lastYear ? 'visible' : 'hidden'">
                        ({{ data.lastYear }})
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-md-6">
            <div class="map-card">
                <div class="header-div">
                    <div class="map-legendTbl">
                        <table class="map-legendTable" style="width: 100%">
                            <tr>
                                <td>
                                    <span class="title">Quantity at Risk</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <ng-container *ngIf="QtyAtRiskChartData?.chartData?.length">
                    <high-chart [enableLegend]="true" [enabledExporting]="false" [yAxisLabelFormat]="'{value}'"
                        [secondaryYAxisLabelFormat]="'{value}%'" [yAxisTickAmount]="6"
                        [xAxisLabel]="QtyAtRiskChartData?.xAxisLabel" [yAxisLabel]="'Order Quality At Risk'"
                        [seriesData]="QtyAtRiskChartData?.chartData" [chartHeight]="280" [enableXAxisScrollbar]="
                QtyAtRiskChartData?.xAxisScrollable
                " [isBarClickable]="true" [showSecondaryYAxis]="true" [graphName]="graphName?.QTY_AT_RISK"
                        (emitClickedBarDetails)="getClickedBarDetails($event)">
                    </high-chart>
                </ng-container>
            </div>
        </div>

        <div class="col-md-6">
            <div class="map-card">
                <div class="header-div">
                    <div class="title">Total Packed Vs Excess Capacity</div>
                </div>
                <ng-container>
                    <div *ngIf="packedAndExcessCapacityData?.chartData?.length > 0" class="capacity">
                        <high-chart [enableLegend]="true" [enabledExporting]="false" [yAxisLabelFormat]="'{value}'"
                            [secondaryYAxisLabelFormat]="'{value}%'" [yAxisTickAmount]="6"
                            [xAxisLabel]="packedAndExcessCapacityData?.xAxisLabel" [yAxisLabel]="'Planned Production'"
                            [seriesData]="packedAndExcessCapacityData?.chartData" [chartHeight]="280"
                            [enableXAxisScrollbar]="
                packedAndExcessCapacityData?.xAxisScrollable" [graphName]="graphName?.EXCESS_CAPACITY"
                            [isBarClickable]="true" [stacking]="'normal'"
                            (emitClickedBarDetails)="getClickedBarDetails($event)">
                        </high-chart>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="chart-card cursor-pointer">
                <div class="header-div">
                    <div class="title">Quantity at Risk Details</div>
                </div>
                <div class="qty-at-risk-table-container">
                    <app-expandable-table [dataSource]="QtyRiskDetailsTableData?.data"
                        [displayedColumns]="QtyRiskDetailsTableData?.columns"
                        [headers]="QtyRiskDetailsTableData?.headers" [graphName]="graphName?.QTY_AT_RISK_DETAILS_TBL"
                        (rowClicked)="getClickedBarDetails($event)"></app-expandable-table>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="chart-card">
                <div class="header-div">
                    <div class="title">Critical Excess Inventory</div>
                </div>
                <div class="critical-excess-inventory-table">
                    <app-mat-table [dataSource]="inventoryTableData?.data"
                        [displayedColumns]="inventoryTableData?.columns" [headers]="inventoryTableData?.headers"
                        [isStickyHeader]="true" [showPaginator]="showPaginator"
                        (rowClicked)="getSelectedSKU($event)"></app-mat-table>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterDataService } from 'src/app/services/filter-data/filter-data.service';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';
import {
  IClickedBarDetails,
  IInventoryTableModel,
  IModalTable,
  INameValue,
  IQtyRiskDetailsTableModel,
  ISchedulingChartsUIDetails,
  ISchedulingDetailsFilters,
  ISchedulingDetailsSummaryCard,
} from '../../interfaces/scheduling-interface';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import {
  CAPACITY_CHART_COLORS,
  CAPACITY_Y_AXIS_LABEL,
  FILL_RATE_LINE_CHART_NAME,
  INVENTORY_COLUMNS,
  INVENTORY_HEADER_OBJ,
  INVENTORY_HEADERS,
  MIN_X_AXIS_LIMIT,
  PRODUCTION_SCHEDULING_GRAPH_NAME,
  QTY_RISK_DETAILS_HEADER,
  QTY_Y_AXIS_LABEL,
  QUANTITY_COLUMNS,
  QUANTITY_HEADERS,
  SERIES_CHART_COLORS,
} from '../../constants/prod-scheduling.constant';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';
import { MatDialog } from '@angular/material/dialog';
import { PlantDetailsModelComponent } from '../plant-details-model/plant-details-model.component';

@Component({
  selector: 'app-prod-scheduling-details',
  templateUrl: './prod-scheduling-details.component.html',
  styleUrls: ['./prod-scheduling-details.component.scss'],
})
export class ProdSchedulingDetailsComponent implements OnInit {
  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  displayLoader: boolean = true;
  isApiError: Boolean = false;
  private subscriptions$: Array<Subscription> = new Array<Subscription>();
  selectedFilters: ISchedulingDetailsFilters = {
    plantID: '',
    materialID: '',
    customer: '',
    deliveryDateMin: undefined,
    deliveryDateMax: undefined,
  };
  productionSummaryCardData: ISchedulingDetailsSummaryCard[] = [];
  QtyAtRiskChartData: ISchedulingChartsUIDetails;
  packedAndExcessCapacityData: ISchedulingChartsUIDetails;
  inventoryTableData: IModalTable;
  QtyRiskDetailsTableData: IModalTable;
  selectedYear: number;
  lineChartName = FILL_RATE_LINE_CHART_NAME;
  chartColorList = SERIES_CHART_COLORS;
  capacityChartColorList = CAPACITY_CHART_COLORS;
  minimumXAxisLimit = MIN_X_AXIS_LIMIT;
  inventoryHeaders = INVENTORY_HEADER_OBJ;
  inventoryColumns = INVENTORY_COLUMNS;
  quantityHeaders = QUANTITY_HEADERS;
  quantityColumns = QUANTITY_COLUMNS;
  QtyRiskDetailsHeaderList = QTY_RISK_DETAILS_HEADER;
  qtyYAxisLabel = QTY_Y_AXIS_LABEL;
  capacityYAxisLabel = CAPACITY_Y_AXIS_LABEL;
  graphName = PRODUCTION_SCHEDULING_GRAPH_NAME;
  loadView: boolean = false;
  lastClickedElement: any = null;
  //inventoryHeaderList=INVENTORY_HEADERS;
  constructor(
    private readonly toasterService: ToasterWrapperService,
    private filterDataService: FilterDataService,
    private schedulingDetailsService: SchedulingDetailsService,
    private dialog: MatDialog
  ) {
    this.getProductionSchedulingFilters();
  }

  ngOnInit(): void { }
  getProductionSchedulingFilters() {
    this.subscriptions$.push(
      this.filterDataService.currentFilterData
        .pipe(debounceTime(300))
        .subscribe((filterData: ISchedulingDetailsFilters) => {
          if (filterData != null) {
            this.selectedYear = new Date(
              filterData?.deliveryDateMin
            ).getFullYear();
            this.selectedFilters = filterData;
            this.loadProductionSchedulingData();
          }
        })
    );
  }

  loadProductionSchedulingData() {
    this.displayLoader = true;
    this.loadView = false;
    this.subscriptions$.push(
      this.schedulingDetailsService
        .getProductionSchedulingData(this.selectedFilters)
        .subscribe(
          (data) => {
            this.productionSummaryCardData = data?.summaryCard?.data;

            //remove this if the data is corrected in the api
            this.productionSummaryCardData = this.productionSummaryCardData.map((item: any) => {
              if (item.name === 'Available Production Capacity') {
                item.currentYear = '2.1 M';  // Set the required value for Available Production Capacity
              }
              if (item.name === 'Capacity Utilization') {
                item.currentYear = '96%';  // Set the required value for Capacity Utilization
              }
              return item;
            });
            //till here

            const quantityChart = data?.quantityCharts?.data[0];
            this.getQuantityAtRiskChartData(quantityChart);
            const capacityChart = data?.quantityCharts?.data[1];
            this.getCapacityChartDetails(capacityChart);
            this.inventoryTableData = this.getRequiredTableData(
              data?.inventoryTable,
              this.graphName?.INVENTORY_TABLE
            );
            this.QtyRiskDetailsTableData = this.getRequiredTableData(
              data?.quantityTable,
              this.graphName?.QTY_AT_RISK_DETAILS_TBL
            );

            this.displayLoader = false;
            this.loadView = true;
          },
          (error) => {
            this.toasterService.error('Error occurred while fetching data');
            this.displayLoader = false;
            this.loadView = true;
          }
        )
    );
  }

  getQuantityAtRiskChartData(quantityChart) {
    this.QtyAtRiskChartData = {
      title: quantityChart?.name,
      xAxisLabel: quantityChart?.xAxisLabel,
      yAxisLabel: quantityChart.yAxisLabel,
      chartData: '',
      xAxisScrollable: false,
    };

    if (quantityChart.lineChartData) {
      const xAxisScrollable =
        quantityChart.lineChartData[0].series?.length > this.minimumXAxisLimit
          ? true
          : false;
      this.QtyAtRiskChartData = {
        ...this.QtyAtRiskChartData,
        xAxisScrollable: xAxisScrollable,
      };

      this.QtyAtRiskChartData.chartData = quantityChart.lineChartData.map(
        (el: any, i: number) => {
          return {
            type: this.lineChartName == el.name ? 'line' : 'column',
            name: el.name,
            color: this.chartColorList[i],
            yAxis: this.lineChartName == el.name ? 1 : 0,
            data: el.series.map((el: INameValue) => {
              {
                return [
                  new Date(el.name).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    //year:'numeric',
                  }),
                  el.value,
                ];
              }
            }),
          };
        }
      );
    }
  }

  getCapacityChartDetails(capacityChart) {
    const seriesData = capacityChart?.lineChartData;
    this.packedAndExcessCapacityData = {
      title: capacityChart?.name,
      xAxisLabel: capacityChart?.xAxisLabel,
      yAxisLabel: capacityChart.yAxisLabel,
      chartData: '',
      xAxisScrollable: false,
    };
    this.packedAndExcessCapacityData.chartData = seriesData.map(
      (el: any, i: number) => {
        return {
          type: 'column',
          name: el.name,
          color: this.capacityChartColorList[i],
          data: el.series.map((el: INameValue) => {
            {
              return { name: el.name, y: el.value };
            }
          }),
        };
      }
    );
  }

  getRequiredTableData(data: any, tableName: string) {
    let requiredData = null;
    return (requiredData = {
      data: this.getFormattedTableData(data) || [],
      columns:
        tableName == this.graphName?.INVENTORY_TABLE
          ? this.inventoryColumns
          : this.quantityColumns,
      headers:
        tableName == this.graphName?.INVENTORY_TABLE
          ? this.inventoryHeaders
          : this.quantityHeaders,
      showPaginator: false,
    });

  }


  getFormattedTableData(tableData: any[]) {
    return tableData.map((item) => {
      for (let key in item) {
        if (typeof item[key] === 'number') {
          item[key] = item[key].toLocaleString();
        }
      }
      return item;
    });
  }


  // getInventoryTableData(inventoryData: IInventoryTableModel[]) {
  //   if (inventoryData?.length > 0) {
  //     const inventoryColumns = Object.keys(inventoryData[0]);
  //     const inventoryHeaders = inventoryColumns.reduce((obj, key, index) => {
  //       obj[key] = this.inventoryHeaderList[index];
  //       return obj;
  //     }, {});
  //     this.inventoryTableData = {
  //       data: this.getFormattedTableData(inventoryData),
  //       columns: inventoryColumns,
  //       headers: inventoryHeaders,
  //       showPaginator: false,
  //     };
  //   }
  // }

  // getQuantityTableData(quantityTable: IQtyRiskDetailsTableModel[]) {
  //   if (quantityTable?.length > 0) {
  //     const columns = Object.keys(quantityTable[0]).filter(
  //       (key) => key !== 'Values'
  //     );
  //     let quantityColumns = ['action', ...columns];
  //     const quantityHeaders = quantityColumns.reduce((obj, key, index) => {
  //       obj[key] = this.QtyRiskDetailsHeaderList[index];
  //       return obj;
  //     }, {});
  //     this.QtyRiskDetailsTableData = {
  //       data: this.getFormattedTableData(quantityTable),
  //       columns: quantityColumns,
  //       headers: quantityHeaders,
  //       showPaginator: false,
  //     };
  //   }
  // }

  /**
   * function to get colors.
   * @param index card number as index
   * @returns color
   */
  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  getClickedBarDetails(data: IClickedBarDetails) {
    switch (data?.graphName) {
      case this.graphName.QTY_AT_RISK: {
        const selectedRdd = this.formatRequiredDeliveryDate(data?.xAxisLabel);
        this.selectedFilters = {
          ...this.selectedFilters,
          deliveryDateMin: selectedRdd,
          deliveryDateMax: selectedRdd,
        };
        this.loadProductionSchedulingData();
        break;
      }
      case this.graphName.EXCESS_CAPACITY: {
        // this.selectedFilters = {
        //   ...this.selectedFilters,
        //   plantID: data?.xAxisLabel,
        // };
        if (this.lastClickedElement != null) {
          this.openPlantDetailsDialog(data?.xAxisLabel);
        }
        break;
      }
      case this.graphName.QTY_AT_RISK_DETAILS_TBL: {
        if (this.lastClickedElement !== data?.xAxisLabel) {
          this.lastClickedElement = data?.xAxisLabel;
          this.selectedFilters = {
            ...this.selectedFilters,
            materialID: data?.xAxisLabel,
          };
          this.loadProductionSchedulingData();
        } else {
          this.lastClickedElement = null;
        }
        break;
      }
    }
  }

  formatRequiredDeliveryDate(rdd: string) {
    const rddDate = new Date(rdd.concat(' ', this.selectedYear.toString()));
    const year = rddDate.getFullYear();
    const month = String(rddDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(rddDate.getDate()).padStart(2, '0');
    return new Date(`${year}-${month}-${day}`);
  }

  getSelectedSKU(data: any) {
    this.selectedFilters = {
      ...this.selectedFilters,
      materialID: data['Material_ID'],
    };
    this.loadProductionSchedulingData();
  }

  openPlantDetailsDialog(plantID: any) {
    this.dialog.open(PlantDetailsModelComponent, {
      width: '100%',
      maxWidth: '1400px',
      //height: 'auto',
      hasBackdrop: true,
      maxHeight: '1200px',
      panelClass: 'plant-details-dialog',
      disableClose: true,
      data: {
        plantID: plantID,
        materialID: this.lastClickedElement
      },
    });
  }
}

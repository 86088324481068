import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';
import { FilterDataService } from 'src/app/services/filter-data/filter-data.service';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';

@Component({
  selector: 'app-daily-truck-container',
  templateUrl: './daily-truck-container.component.html',
  styleUrls: ['./daily-truck-container.component.scss']
})
export class DailyTruckContainerComponent implements OnInit {
  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  tblTitles = [
    '% Capacity Planned By Day',
    '% Capacity Planned By Plant & Day'
  ];
  selectedOrigin = ['All'];
  selectedDestinations = ['All'];
  dailyTruckAvailFilters: any
  deliveryDateFormGroup = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
  });
  dataFromApi: any;
  isLoading = true;
  selectedToggle: boolean = true;
  selectedYear: number;
  minOrderDate: Date;
  maxOrderDate: Date;
  selectedFilters: { origin: any; destination: any; orderDateMin: any; orderDateMax: any; };
  dataLoaded: boolean = false;
  selectedRow: any;
  filters: any[];
  isFiltersAvailable: boolean = false;
  convertedPayload: {
    origin: string[];
    destination: string[];
    orderDateMax: string;
    orderDateMin: string;
  } = {
      origin: null,
      destination: null,
      orderDateMax: null,
      orderDateMin: null,
    };
  private dailyTruckSubscriptions$: Subscription;
  dailyTruckFilterDataSubscription: Subscription;
  private readonly SOE_FILTER_RESET_STATE = 'soeFilterResetState';

  constructor(private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToasterWrapperService,
    private cd: ChangeDetectorRef,
    private filterDataService: FilterDataService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.getTMSFiltersDaily();
  }

  ngOnInit(): void { }

  getTMSFiltersDaily() {
    this.dailyTruckFilterDataSubscription = combineLatest([
      this.filterDataService.currentFilterData.pipe(debounceTime(300)),
      this.breadcrumbService.getAsIsOrRecommendationToggle.pipe(debounceTime(300)),
    ]).subscribe(([filterData, asISOrRecommendationToggle]) => {
      this.selectedToggle = asISOrRecommendationToggle;
      console.log(filterData);
      if (filterData != null) {
        const filterResetSate =
          localStorage.getItem(this.SOE_FILTER_RESET_STATE) == 'true'
            ? true
            : false;
        if (filterResetSate) {
          this.convertedPayload = {
            ...this.selectedFilters,
            origin: ['All'],
            destination: ['All'],
            orderDateMin: "2022-10-01T18:30:00.000Z",
            orderDateMax: "2022-08-10T18:30:00.000Z"
          };
        };
        this.convertedPayload = {
          ...this.selectedFilters,
          ...(filterData.origin ? { origin: [filterData.origin] } : {}),
          ...(filterData.destination ? { destination: [filterData.destination] } : {}),
          ...(filterData.deliveryDateMin ? { orderDateMin: filterData.deliveryDateMin } : {}),
          ...(filterData.deliveryDateMax ? { orderDateMax: filterData.deliveryDateMax } : {})
        };
        this.selectedFilters = {
          ...this.selectedFilters,
          ...this.convertedPayload
        };
        const cleanedPayload = Object.fromEntries(
          Object.entries(this.selectedFilters).filter(
            ([_, value]) => { return value != null && (Array.isArray(value) ? value.length > 0 : true); }
          )
        );
        this.getDataFromApi(cleanedPayload, asISOrRecommendationToggle);
      }
    });
  }

  getDataFromApi(selectedFilters, selectedToggle) {
    this.isLoading = true;
    this.dataLoaded = false;
    const { origin, destination, orderDateMin, orderDateMax } = this.selectedFilters;
    if (origin && destination && orderDateMin && orderDateMax) {
      this.dailyTruckSubscriptions$ = this.apiService.getDailyTMSData(selectedFilters, selectedToggle).subscribe(
        (getDailyTMSData) => {
          this.dataFromApi = getDailyTMSData;
          this.isLoading = false;
          this.cd.detectChanges();
          this.dataLoaded = true;
        },
        (error) => {
          this.toasterService.error(error?.error);
          this.isLoading = false;
          this.dataLoaded = true;
        });
    }
  }

  handleRowClick(row: any) {
    this.selectedRow = row;
    const originDestination = row['Origin-Destination'];
    const [origin, destination] = originDestination.split('-');
    this.selectedFilters = {
      origin: [origin],
      destination: [destination],
      orderDateMin: this.deliveryDateFormGroup.get('startDate').value,
      orderDateMax: this.deliveryDateFormGroup.get('endDate').value
    };
    this.getDataFromApi(this.selectedFilters, this.selectedToggle);
  }

  handleChartClick(selectedDrilldownData: any) {
    switch (selectedDrilldownData.xAxisLabel) {
      case "Origin":
        this.selectedFilters = {
          ...this.selectedFilters,
          origin: [selectedDrilldownData.drilldownId],
        }
        break;
      case "Origin->Destination":
        const originDestination = selectedDrilldownData.drilldownId;
        const [origin, destination] = originDestination.split('-');
        this.selectedFilters = {
          ...this.selectedFilters,
          origin: [origin],
          destination: [destination]
        };
        break;
      case "LTL Cost":
        this.selectedFilters = {
          ...this.selectedFilters,
          orderDateMin: this.convertDateToISO(selectedDrilldownData.drilldownId),
          orderDateMax: this.convertDateToISO(selectedDrilldownData.drilldownId)
        }
        break;
      default:
        this.selectedFilters = {
          ...this.selectedFilters,
        };
        break;
    }
    this.getDataFromApi(this.selectedFilters, this.selectedToggle);
  }

  convertDateToISO(inputDate: string): string {
    const [day, month, year] = inputDate.split('/').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day, 18, 30, 0, 0));
    return date.toISOString();
  }

  ngOnDestroy(): void {
    if (this.dailyTruckSubscriptions$) {
      this.dailyTruckSubscriptions$.unsubscribe();
    };
    if (this.dailyTruckFilterDataSubscription) {
      this.dailyTruckFilterDataSubscription.unsubscribe();
    }
  }
}

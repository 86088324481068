import { environment } from "@env";

export const THIRD_PARTY_CONFIGURATIONS = {
    TOASTR_CONFIG: {
        preventDuplicates: false,
        extendedTimeOut: 0,
        newestOnTop: true,
        maxOpened: 0,
        closeButton: true,
        tapToDismiss: true
    },
    NG_CIRCLE_PROGRESS_CONFIG: {
        radius: 100,
        "titleColor": "#1fdbce",
        unitsColor: "#1fdbce",
        outerStrokeWidth: 16,
        innerStrokeWidth: 8,
        outerStrokeColor: "#78C000",
        innerStrokeColor: "#C7E596",
        animationDuration: 300
    }
};
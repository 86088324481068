import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { API_URLS } from '../constants/api.urls';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  getMoverOptions() {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }


  getSummaryCardData(): Observable<any> {
    return this.http.get(API_URLS.getSummaryCardData);
  }

  getIscoOverviewPageData(): Observable<any> {
    return this.http.get(API_URLS.getIscoOverviewPageData);
  }

  getLogisticsList(): Observable<any> {
    return this.http.get(API_URLS.getLogisticsCard);
  }

  getMxDData(): Observable<any> {
    return this.http.get(API_URLS.getMxDData);
  }

  getLandingOeeData(): Observable<any> {
    return this.http.get(API_URLS.getLandingOeeData);
  }

  getLandingInventoryData(): Observable<any> {
    return this.http.get(API_URLS.getLandingInventoryData);
  }

  getOTIFLandingCard(): Observable<any> {
    return this.http.get(API_URLS.getOTIFLandingCard);
  }

  getOverallOTIFCard(): Observable<any> {
    return this.http.get(API_URLS.getOverallOTIFCard);
  }

  getOTIFSummaryCard(): Observable<any> {
    return this.http.get(API_URLS.getOTIFSummaryCard);
  }

  getOTIFCharts(): Observable<any> {
    return this.http.get(API_URLS.getOTIFCharts);
  }

  getOTIFPerCustomer(): Observable<any> {
    return this.http.get(API_URLS.getOTIFPerCustomer);
  }

  getFactorsEffOTIF(): Observable<any> {
    return this.http.get(API_URLS.getFactorsEffOTIF);
  }

  getOtifMapData(): Observable<any> {
    return this.http.get(API_URLS.getOtifMapData);
  }

  getProdDowntimeData(): Observable<any> {
    return this.http.get(API_URLS.getProdDowntimeData);
  }

  getOEESummaryCard(): Observable<any> {
    return this.http.get(API_URLS.getOEESummaryCard);
  }
  getDAPQCharts(): Observable<any> {
    return this.http.get(API_URLS.getDAPQCharts);
  }
  getOEETrendData(): Observable<any> {
    return this.http.get(API_URLS.getOEETrendData);
  }
  getOeeMapData(): Observable<any> {
    return this.http.get(API_URLS.getOeeMapData);
  }

  getPBIToken(reportId: string): Observable<any> {
    return this.http.get(API_URLS.getPBIToken + `?reportId=${reportId}`);
  }

  getDigitalTwinModelData(): Observable<any> {
    return this.http.get(API_URLS.getDigitalTwinModelData);
  }

  getDemandAttainmentData(): Observable<any> {
    return this.http.get(API_URLS.getDemandAttainmentData);
  }

  getUtilDemandChartData(): Observable<any> {
    return this.http.get(API_URLS.getUtilDemandChartData);
  }

  getSCCSummaryCard(): Observable<any> {
    return this.http.get(API_URLS.getSCCSummaryCard);
  }

  getSCCCharts(): Observable<any> {
    return this.http.get(API_URLS.getSCCCharts);
  }

  getPlannedDowntimePopup(): Observable<any> {
    return this.http.get(API_URLS.getPlannedDowntimePopup);
  }

  getUnplannedDowntimePopup(): Observable<any> {
    return this.http.get(API_URLS.getUnplannedDowntimePopup);
  }

  getDropdownProductSegment(): Observable<any> {
    return this.http.get(API_URLS.getProductSegmentDropdown)
  }

  getDropdownProdSubtype(subType: any): Observable<any> {
    const url = API_URLS.getProductSubTypeDropdown + '?productSegmentType=' + subType;
    return this.http.get(url);
  }

  getExecutiveSummaryCards(): Observable<any> {
    return this.http.get(API_URLS.getExecutiveSummaryMEIOCards);
  }

  getExecutiveSummaryProduct(type, subtype): Observable<any> {
    const url = API_URLS.getExecutiveSummaryProduct + '?productSegmentType=' + type + '&kpi=' + subtype;
    return this.http.get(url);
  }

  getExecutiveSummaryTopologyMap(invType: any): Observable<any> {
    const url = API_URLS.getExecutiveSummaryTopologyMap + '?invType=' + invType;
    return this.http.get(url);
  }

  getExecutiveStockingStrategyTable(segmentType: any, segmentSubType: any): Observable<any> {
    const url = API_URLS.getExecutiveSummaryStocking + '?productSegmentType=' + segmentType + '&productSegmentSubType=' + segmentSubType;
    return this.http.get(url);
  }

  getOutputPageScenarios(): Observable<any> {
    return this.http.get(API_URLS.getOutputPageScenarios);
  }

  getMeioOutputCards(name): Observable<any> {
    const url = API_URLS.getOutputPageCards + '?scenarioName=' + name;
    return this.http.get(url);
  }

  getOutputCurrentVSOptimisedChart(subSegmentType: any): Observable<any> {
    const url = API_URLS.getMEIOOutputOptimizedBar + '?scenarioName=' + subSegmentType;
    return this.http.get(url);
  }

  getOutputStockingStrategyTable(type: any, segmentType: any, segmentSubType: any): Observable<any> {
    const url = API_URLS.getMEIOOutputStockingStrategy + '?scenarioName=' + type + '&productSegmentType=' + segmentType + '&productSegmentSubType=' + segmentSubType;
    return this.http.get(url);
  }

  getOutputProductSegmentGraph(scenarioName: any, productType: any): Observable<any> {
    const url = API_URLS.getMEIOOutputPrdouctSegment + '?scenarioName=' + scenarioName + '&productSegmentType=' + productType;
    return this.http.get(url);
  }

  getParameterTableData(data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(API_URLS.getParameterTableData, data, { headers })
  }

  getMEIOSimulationPageDropdown(scenarioName: string): Observable<any> {
    return this.http.get(API_URLS.getMEIOSimulationPageDropdown + '?scenarioName=' + scenarioName);
  }


  getMEIOInventorySimulation(scenarioName: any, skuPlantType: any): Observable<any> {
    let url: string;
    if (skuPlantType == undefined || skuPlantType === null || skuPlantType === '') {
      url = API_URLS.getMEIOInventorySimulation + '?scenarioName=' + scenarioName;
    } else {
      url = API_URLS.getMEIOInventorySimulation + '?scenarioName=' + scenarioName + '&skuPlantType=' + skuPlantType;
    }
    return this.http.get(url);
  }

  validateAccess(email: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      "email": email
    };
    return this.http.post(API_URLS.ValidateAccess, body, { headers })
  }

  getLots(): Observable<any> {
    return this.http.get(API_URLS.getLots);
  }

  getOverallView(lotId: String): Observable<any> {
    return this.http.get(API_URLS.getOverallView + `?lot=${lotId}`);
  }

  getGraphDropDown1(): Observable<any> {
    return this.http.get(API_URLS.getGraphDropDown1);
  }

  getGraph(selectedFirstOption, selectedSecondOption, selectedThirdOption, selectedFourthOption): Observable<any> {
    const payload = {
      "dropdown1": selectedFirstOption,
      "dropdown2": selectedSecondOption,
      "dropdown3": selectedThirdOption,
      "dropdown4": selectedFourthOption
    }
    return this.http.post(API_URLS.getGraph, payload);
  }

  updateSupplynetwork(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(API_URLS.getUpdateSupplynetwork, payload, { headers }).pipe(
      catchError(this.handleError)
    );
  }

  updateInvPolicies(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(API_URLS.getUpdateInvPolicies, payload, { headers }).pipe(
      catchError(this.handleError)
    );
  }

  requestAccess(payload): Observable<any> {
    return this.http.post(API_URLS.requestAccess, payload)
  }

  allowparamateredits(): Observable<any> {
    return this.http.get(API_URLS.getAllowparamateredits)
  }

  submitScenario(payload): Observable<any> {
    return this.http.post(API_URLS.getSubmitScenario, payload)
  }

  getScenarioComparisonTable(): Observable<any> {
    return this.http.get(API_URLS.getScenarioComparisonTable)
  }

  getsafetystockdata(scenarioName: any): Observable<any> {
    return this.http.get(API_URLS.getsafetystockdata + `?scenarioName=${scenarioName}`);
  }

  getInventoryHealthData(payload): Observable<any> {
    return this.http.post(API_URLS.getInventoryHealthData, payload)
  }

  getInventoryFilters(): Observable<any> {
    return this.http.get(API_URLS.getInventoryFilters);
  }

  getWeeklyTMSData(payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(API_URLS.getWeeklyTMSData, JSON.stringify(payload),
      { headers });
  }

  getDailyTMSData(payload: any, selectedToggle: boolean): Observable<any> {
    const params = new HttpParams().set('toggle', selectedToggle);
    return this.http.post(API_URLS.getDailyTMSData, payload, { params: params });
  }

  getTMSFiltersWeekly(): Observable<any> {
    return this.http.get(API_URLS.getTMSFiltersWeekly);
  }

  getTMSFiltersDaily(): Observable<any> {
    return this.http.get(API_URLS.getTMSFiltersDaily);
  }

  getPSFilters(): Observable<any> {
    return this.http.get(API_URLS.getPSFilters);
  }

  getPSData(payload): Observable<any> {
    return this.http.post(API_URLS.getPSData, payload);
  }

  getFCFilters(): Observable<any> {
    return this.http.get(API_URLS.getFCFilters);
  }

  getFCData(payload): Observable<any> {
    return this.http.post(API_URLS.getFCData, payload);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}`);
    }
    return throwError(`Empty or invalid response. Please try again later.`);
  }
}

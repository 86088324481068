import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { API_URLS } from 'src/app/constants/api.urls';


@Injectable({
  providedIn: 'root',
})
export class ProfilePicService {
  constructor(private http: HttpClient) { }

  getProfilePic(emailId: string) {
    const params = new HttpParams().set('emailId', emailId);
    const headers = new HttpHeaders({
      'Accept': 'image/jpeg',
    });
    return this.http.get(API_URLS.getProfilePic, {
      headers: headers,
      params: params,
      responseType: 'blob'
    }).pipe(
      catchError((error) => {
        if (error.error instanceof Blob && error.error.type === 'application/json') {
          return error.error.text().then((errorText: string) => {
            const parsedError = JSON.parse(errorText);
            if (parsedError?.error?.code === 'ImageNotFound') {
              return throwError(() => new Error('Image not found for the given user.'));
            }
            return throwError(() => new Error('An unknown error occurred.'));
          });
        }
        return throwError(() => new Error(error.message || 'An unknown error occurred.'));
      })
    );
  }
}

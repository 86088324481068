import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { API_URLS } from "../constants/api.urls";

@Injectable({
  providedIn: 'root',
})

export class KeyVaultService {

  constructor(private http: HttpClient, private authService: MsalService) { }

  getAllKeySecrets() {
    const body = [
      {
        'keyName': 'tsienvironmentid',
      },
      {
        'keyName': 'coreapibaseurl',
      },
      {
        'keyName': 'azuremapsubscriptionkey',
      },
      {
        'keyName': 'azuredigitaltwinurl',
      },
      {
        'keyName': 'redirecturi',
      },
      {
        'keyName': 'evalertlogicappendpoint',
      },
      {
        'keyName': 'pdalertlogicappendpoint'
      }
    ];
    let apiUrl =
      API_URLS.keyVaultKeys;
    return this.http.post(apiUrl, body);
  }
}
import { ApiService } from "src/app/services/api.service";
import { OrdersAtRiskService } from "../orders-at-risk/services/orders-at-risk.service";
import { map, shareReplay } from "rxjs/operators";
import { WarehouseUtilizationService } from "../warehouse-truck-utilization/services/warehouse-utilization.service";
import { RedeploymentApprovalsService } from "src/app/services/redeployment-approvals.service";
import { data } from "azure-maps-control";

export const FILTER_LABELS: { [key: string]: { type: string; label: string; selectedValue?: string; payloadLabel?: any; startDate?: Date; endDate?: Date; }[] | { [key: string]: { type: string; label: string; selectedValue?: string; payloadLabel?: any; startDate?: Date; endDate?: Date; }[] } } = {
    '/home/soe/orders-at-risk': {
        'tab1': [
            { type: 'dropdown', label: 'SKU ID', payloadLabel: 'skuId', selectedValue: 'All' },
            { type: 'dropdown', label: 'SKU Name', payloadLabel: 'skuName', selectedValue: 'All' },
            { type: 'dropdown', label: 'DC ID', payloadLabel: 'dcId', selectedValue: 'All' },
            { type: 'dropdown', label: 'DC Name', payloadLabel: 'dcName', selectedValue: 'All' },
            { type: 'dropdown', label: 'Customer ID', payloadLabel: 'customerId', selectedValue: 'All' },
            { type: 'dropdown', label: 'Customer Name', payloadLabel: 'customerName', selectedValue: 'All' },
            { type: 'dropdown', label: 'SKU Category', payloadLabel: 'skuCategory', selectedValue: 'All' },
            { type: 'dropdown', label: 'Customer Importance', payloadLabel: 'customerPriority', selectedValue: 'All' },
            { type: 'dateRange', label: 'Requested Delivery Date', payloadLabel: ['deliveryDateMin', 'deliveryDateMax'], startDate: new Date('10/01/2022 00:00:00'), endDate: new Date('10/15/2022 00:00:00') }
        ]
    },
    '/home/soe/redeployment-approval-impact': {
        'tab1': [
            { type: 'dropdown', label: 'Source Location', payloadLabel: 'Source', selectedValue: 'All' },
            { type: 'dropdown', label: 'Destination Location', payloadLabel: 'Destination', selectedValue: 'All' },
            { type: 'dateRange', label: 'Transfer Date', payloadLabel: ['tranferDateMin', 'tranferDateMax'], startDate: new Date('10/01/2022 00:00:00'), endDate: new Date('10/13/2022 00:00:00') },
            { type: 'dropdown', label: 'SKU', payloadLabel: 'SKU', selectedValue: 'All' },
            { type: 'dropdown', label: 'Transport Mode', payloadLabel: 'ModeOfTransport', selectedValue: 'All' },
           // { type: 'dropdown', label: '$ Difference', payloadLabel: 'orderWeek', selectedValue: 'All' }, //ask on how this should be
            { type: 'dropdown', label: 'Actions', payloadLabel: 'orderWeek', selectedValue: 'All' } 
        ],
        "tab2": [
            { type: 'dropdown', label: 'Distribution Center', selectedValue: 'All' },
            { type: 'dateRange', label: 'Requested Delivery Date', payloadLabel: ['deliveryDateMin', 'deliveryDateMax'], startDate: new Date('10/01/2022 00:00:00'), endDate: new Date('10/15/2022 00:00:00') }
        ],
        "tab3": [
            { type: 'dropdown', label: 'Origin', payloadLabel: 'origin', selectedValue: 'All' },
            { type: 'dropdown', label: 'Destinations', payloadLabel: 'destination', selectedValue: 'All' },
            { type: 'dateRange', label: 'Ordered Week', payloadLabel: ['deliveryDateMin', 'deliveryDateMax'], startDate: new Date('10/01/2022 00:00:00'), endDate: new Date('10/08/2022 00:00:00'), selectedValue: '' }
        ]
    },
    // '/home/soe/redeployment-approval-impact': {
    //     "tab1": [
    //         { type: 'dropdown', label: 'Distribution Center', selectedValue: 'All' },
    //         { type: 'dateRange', label: 'Requested Delivery Date', payloadLabel: ['deliveryDateMin', 'deliveryDateMax'], startDate: new Date('10/01/2022 00:00:00'), endDate: new Date('10/15/2022 00:00:00') }
    //     ],
    //     "tab2": [
    //         { type: 'dropdown', label: 'Origin', payloadLabel: 'origin', selectedValue: 'All' },
    //         { type: 'dropdown', label: 'Destinations', payloadLabel: 'destination', selectedValue: 'All' },
    //         { type: 'dateRange', label: 'Ordered Week', payloadLabel: ['deliveryDateMin', 'deliveryDateMax'], startDate: new Date('10/01/2022 00:00:00'), endDate: new Date('10/08/2022 00:00:00'), selectedValue: '' }
    //     ]
    // },
    '/home/soe/projected-shortages': {
        'tab1': [
            { type: 'dropdown', label: 'SKU Category', payloadLabel: 'skuCategory', selectedValue: 'All' },
            { type: 'dropdown', label: 'DC ID', payloadLabel: 'dcId', selectedValue: 'All' },
            { type: 'dropdown', label: 'Ordered Week', payloadLabel: 'orderWeek', selectedValue: 'All' }
        ]
    },
    '/home/soe/weekly-truck-availability': {
        'tab1': [
            { type: 'dropdown', label: 'Origin', payloadLabel: 'origin', selectedValue: 'All' },
            { type: 'dropdown', label: 'Destinations', payloadLabel: 'destination', selectedValue: 'All' },
            { type: 'dropdown', label: 'Ordered Week', payloadLabel: 'orderWeek', selectedValue: 'All' }
        ]
    },
    '/home/soe/warehouse-utilization': {
        'tab1': [
            { type: 'dropdown', label: 'Plant ID', payloadLabel: 'Plant_ID', selectedValue: 'All' },
            { type: 'dropdown', label: 'Ordered Week', payloadLabel: 'Calender Week', selectedValue: 'All' }
        ]
    }
};

export function createFilterEndpoints(
    apiService: ApiService,
    ordersAtRiskService: OrdersAtRiskService,
    warehouseUtilizationService: WarehouseUtilizationService,
redeploymentApprovalService: RedeploymentApprovalsService) {
    const tmsDailyFilters$ = apiService.getTMSFiltersDaily().pipe(
        shareReplay(1) 
    );
    const tmsWeeklyFilters$ = apiService.getTMSFiltersWeekly().pipe(
        shareReplay(1)
    );
    const ordersAtRiskFilters$ = ordersAtRiskService.getOrdersAtRiskFilters().pipe(
        shareReplay(1)
    );
    const projectedShortagesFilters$ = apiService.getPSFilters().pipe(
        shareReplay(1)
    );
    const warehouseFilters$ = warehouseUtilizationService.getDailyWMSFilters().pipe(
        shareReplay(1)
    );
    const RedeploymentApprovalsFilters$ = redeploymentApprovalService.getRedeploymentApprovalsFilters().pipe(
        shareReplay(1)
    );

    const warehouseWeeklyFilters$ = warehouseUtilizationService.getWeeklyWMSFilters().pipe(
        shareReplay(1)
    )

    return {
        '/home/soe/orders-at-risk': {
            'tab1': [
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.sku)),
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.skuName)),
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.plantID)),
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.dcName)),
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.customerId)),
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.customerName)),
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.skuCategory)),
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.customerPriority)),
                () => ordersAtRiskFilters$.pipe(map((data) => data?.filter[0]?.deliveryDate)),

            ]
        },
        '/home/soe/redeployment-approval-impact': {
            'tab1': [
                () => RedeploymentApprovalsFilters$.pipe(map((data) => data?.filter[0]?.Source)),
                () => RedeploymentApprovalsFilters$.pipe(map((data) => data?.filter[0]?.Destination)),
                () => RedeploymentApprovalsFilters$.pipe(map((data) => data?.filter[0]?.TransferDate)),
                () => RedeploymentApprovalsFilters$.pipe(map((data) => data?.filter[0]?.SKU)),
                () => RedeploymentApprovalsFilters$.pipe(map((data) => data?.filter[0]?.ModeOfTransport)),
                () => RedeploymentApprovalsFilters$.pipe(map((data) => data?.filter[0]?.Flag)),
                
             ],
            // 'tab2': [
            //     () => warehouseFilters$.pipe(map((data) => data?.filter[0]?.Plant_ID)),
            //     () => warehouseFilters$.pipe(map((data) => data?.filter[0]?.Requested_Delivery_Date))
            // ],
            // 'tab3': [
            //     () => tmsDailyFilters$.pipe(map((data) => data?.filter[0]?.Origin)),
            //     () => tmsDailyFilters$.pipe(map((data) => data?.filter[0]?.Destination)),
            //     () => tmsDailyFilters$.pipe(map((data) => data?.filter[0]?.Order_date))
            // ]
        },
        '/home/soe/projected-shortages': {
            'tab1': [
                () => projectedShortagesFilters$.pipe(map((data) => data?.filter[0]?.SKU_Category)),
                () => projectedShortagesFilters$.pipe(map((data) => data?.filter[0]?.DC_ID)),
                () => projectedShortagesFilters$.pipe(map((data) => data?.filter[0]?.Date))
            ]
        },
        '/home/soe/weekly-truck-availability': {
            'tab1': [
                () => tmsWeeklyFilters$.pipe(map((data) => data?.filter[0]?.Origin)),
                () => tmsWeeklyFilters$.pipe(map((data) => data?.filter[0]?.Destination)),
                () => tmsWeeklyFilters$.pipe(map((data) => data?.filter[0]?.Order_week))
            ]
        },
        '/home/soe/warehouse-utilization': {
            'tab1': [
                () => warehouseWeeklyFilters$.pipe(map((data) => data?.filter[0]?.Plant_ID)),
                () => warehouseWeeklyFilters$.pipe(map((data) => data?.filter[0]?.["Calender Week"]))
            ]
        },
    };
}

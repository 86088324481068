import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterDataService } from 'src/app/services/filter-data/filter-data.service';
import { SideNavService } from 'src/app/services/side-nav/side-nav.service';

@Component({
  selector: 'app-right-side-nav',
  templateUrl: './right-side-nav.component.html',
  styleUrls: ['./right-side-nav.component.scss'],
})
export class RightSideNavComponent implements OnInit, OnDestroy {
  loadingMessage = 'Loading filters...';
  selectedFilters: any = {};
  filters: {
    type: string;
    label: string;
    selectedValue?: string | string[];
    payloadLabel?: any;
    startDate?: Date;
    endDate?: Date;
    options: string[];
    dropdownType?: string;
    searchValue?: '';
    filteredOptions?: string[];
  }[];
  selectedValue: string = 'All';
  startDate: Date;
  endDate: Date;
  filtersLoaded: boolean = false;
  @Output() filterApplied = new EventEmitter<any>();
  filtersSubscription: Subscription;
  minRequiredDeliveryDate: Date;
  maxRequiredDeliveryDate: Date;
  private readonly SOE_FILTER_RESET_STATE = 'soeFilterResetState';
  constructor(
    private sideNavService: SideNavService,
    private filterDataService: FilterDataService
  ) {
    this.initializeFilters();
  }

  ngOnInit(): void { }

  initializeFilters() {
    this.filtersSubscription =
      this.sideNavService.rightSidebarFilters$.subscribe((filters) => {
        if (!filters || !filters.filters || filters.filters.length === 0)
          return;

        this.filters = filters.filters.map((filter) => ({
          ...filter,
          searchValue: '',
          filteredOptions: filter.options,
        }));
        this.selectedFilters = {};

        this.filters.forEach((filter) => {
          if (filter.type === 'dropdown') {
            this.selectedFilters[filter.payloadLabel] = filter.selectedValue;
          } else if (filter.type === 'dateRange') {
            this.selectedFilters[filter.payloadLabel[0]] =
              this.getCorrectSelectedDate(filter.startDate);
            this.selectedFilters[filter.payloadLabel[1]] =
              this.getCorrectSelectedDate(filter.endDate);
            this.minRequiredDeliveryDate = new Date(filter.startDate);
            this.maxRequiredDeliveryDate = new Date(filter.endDate);
          }
        });

        if (Object.keys(this.selectedFilters).length > 0) {
          this.filterDataService.updateFilterData(this.selectedFilters);
          this.filtersLoaded = true;
        }
      });
  }

  onDropdownChange(filter: any): void {
    this.selectedFilters[filter.payloadLabel] = filter.selectedValue;
  }

  filterOptions(filter: any): void { 
    // Apply the filter
    filter.filteredOptions = filter.options.filter(option => {
      const optionName = option.name ? option.name.toLowerCase() : '';
      return optionName.includes(filter.searchValue.toLowerCase());
    });
  }
  
  
  

  onDateRangeChange(filter: any) {
    this.selectedFilters[filter?.payloadLabel[0]] = this.getCorrectSelectedDate(
      filter.startDate
    );
    this.selectedFilters[filter?.payloadLabel[1]] = this.getCorrectSelectedDate(
      filter.endDate
    );
  }

  getCorrectSelectedDate(selectedDate: Date) {
    return new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      selectedDate.getHours(),
      selectedDate.getMinutes() - selectedDate.getTimezoneOffset()
    ).toISOString();
  }

  resetAllFilters(): void {
    this.filters.forEach((filter) => {
      if (filter.type === 'dropdown' && filter.dropdownType != 'multiselect') {
        filter.selectedValue = 'All';
        this.selectedFilters[filter.payloadLabel] = 'All';
      } else if (filter.type === 'dateRange') {
        filter.startDate = new Date(
          this.getCorrectSelectedDate(this.minRequiredDeliveryDate)
        );
        filter.endDate = new Date(
          this.getCorrectSelectedDate(this.maxRequiredDeliveryDate)
        );
        this.selectedFilters[filter.payloadLabel[0]] =
          this.getCorrectSelectedDate(this.minRequiredDeliveryDate);
        this.selectedFilters[filter.payloadLabel[1]] =
          this.getCorrectSelectedDate(this.maxRequiredDeliveryDate);
      } else if (filter.type === 'dropdown' && filter.dropdownType === 'multiselect') {
        filter.selectedValue = ['All'];
        this.selectedFilters[filter.payloadLabel] = ['All'];
      }
    });
    localStorage.setItem(this.SOE_FILTER_RESET_STATE, 'true');
    this.filterDataService.updateFilterData(this.selectedFilters);
    this.sideNavService.closeRightSidebar();
  }


  onApply() {
    localStorage.setItem(this.SOE_FILTER_RESET_STATE, 'false');
    this.filterDataService.updateFilterData(this.selectedFilters);
    this.sideNavService.closeRightSidebar();
  }

  ngOnDestroy(): void {
    if (this.filtersSubscription) {
      this.filtersSubscription.unsubscribe();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';
import { DS_SHIPMENT_TABLE_DATA, DS_SUMMARY_CARD, DS_TABLE_NAME } from '../../constants/prod-scheduling.constant';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { ISchedulingDetailsSummaryCard } from '../../interfaces/scheduling-interface';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';

export interface ShippingTblData {
  shippingId: string;
  orderId: string;
  sku: string;
  origin: string;
  recommended: string;
  shippingQuantity: number;
  shippingLocation: string;
  dueDate: string;
  estShipDate: string;
  deliveryTimeImpact: string;
  logisticsCost: string;
  revenue: string;
  difference: string;
  childData: childData[];
}

export interface childData {
  shippingId: string;
  orderId: string;
  sku: string;
  origin: string;
  recommended: string;
  shippingQuantity: number;
  shippingLocation: string;
  dueDate: string;
  estShipDate: string;
  deliveryTimeImpact: string;
  logisticsCost: string;
  revenue: string;
  difference: string;
}


@Component({
  selector: 'app-direct-shipping',
  templateUrl: './direct-shipping.component.html',
  styleUrls: ['./direct-shipping.component.scss']
})
export class DirectShippingComponent implements OnInit {

  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  isLoading: boolean = true;
  displayLoader: boolean = false;
  isApiError: Boolean = false;
  loadView: boolean = false;
  
  //dataSource = DSTABLE_DATA;
  storageDetailsTable: any[] = [];
  columnsToDisplay = [
    'expandIcon',
    // 'shippingId',
    // 'orderId',
    'SKU',
    'Origin',
    'Recommended',
    'ShippingQuantity',
    'ShippingLocation',
    'DueDate',
    'EstimatedShipDate',
    //'deliveryTimeImpact',
    'LogisticsCost',
    'Revenue',
    'Difference',
    'approveReject'
  ];
  childColumnsToDisplay = [
    'expandIcon',
    'shippingId',
    'orderId',
    'sku',
    'origin',
    'recommended',
    'shippingQuantity',
    'shippingLocation',
    'dueDate',
    'estShipDate',
    'deliveryTimeImpact',
    'logisticsCost',
    'revenue',
    'difference',
    'approveReject'
  ];
  expandedElement: ShippingTblData;
  columnHeaderMap = {
    'expandIcon': 'Details',
    // 'shippingId': 'Shipping ID',
    // 'orderId': 'Order ID',
    'SKU': 'SKU',
    'Origin': 'Origin',
    'Recommended': 'Recommended',
    'ShippingQuantity': 'Shipping Quantity',
    'ShippingLocation': 'Shipping Location',
    'DueDate': 'Due Date',
    'EstimatedShipDate': 'Estimated Ship Date',
    //'deliveryTimeImpact': 'Delivery Time Impact',
    'LogisticsCost': 'Logistics Cost',
    'Revenue': 'Revenue',
    'Difference': 'Difference',
    'approveReject': 'Actions'
  };
  summaryCardData: ISchedulingDetailsSummaryCard[] = [];
  skuTableLabel = DS_TABLE_NAME.QUANTITY;
  shipmentDetailsTable: any[] = [];
  formattedTableData: any[] = [];
  tableColumns: string[] = [];
  displayedShipmentColumns: string[] = ['rowName', 'day0', 'day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7', 'day8', 'day9', 'day10', 'day11', 'day12', 'day13', 'day14'];
  constructor(
    private schedulingDetailsService: SchedulingDetailsService,
    private readonly toasterService: ToasterWrapperService,

  ) { }

  ngOnInit(): void {
    this.getDirectShipmentData();
  }

  /**
  * function to get colors.
  * @param index card number as index
  * @returns color
  */
  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  getDirectShipmentData(): void {

    this.displayLoader = true;
    this.loadView = false;
    this.isLoading = true;


    this.schedulingDetailsService.getDirectShipmentData().subscribe(
      (response) => {
        if (response) {
          this.summaryCardData = response?.summaryCard.data;

          this.storageDetailsTable = response.storageDetailsTable;

          this.shipmentDetailsTable = response.shipmentDetailsTable[0]?.datas[0]?.columnValues || [];
          if (this.shipmentDetailsTable.length > 0) {
            this.tableColumns = ['Name', ...Object.keys(this.shipmentDetailsTable[0]).filter(key => key !== 'Name')];
          }
      
          // Format the table data
          this.formattedTableData = this.shipmentDetailsTable;
          

        }
        (error) => {
          this.toasterService.error('Error occurred while fetching data');
        }

          this.isLoading = false;
          this.displayLoader = false;
          this.loadView = true;

      }
    )
  }

  formatDate(dateString: string): string {
    if (dateString === 'Name') return 'Name';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' });
  }

  
  
}

export const STATUS_FILTER_DROPDOWN = [
  {
    name: 'All',
    value: '',
  },
  {
    name: 'Rejected',
    value: 0,
  },
  {
    name: 'Approved',
    value: 1,
  },
  {
    name: 'Pending',
    value: 1,
  },
];

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Highcharts from 'highcharts';

import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);

import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import FullScreen from 'highcharts/modules/full-screen';
import HighStock from 'highcharts/modules/stock';
import { IClickedBarDetails } from 'src/app/features/home/components/soe/orders-at-risk/interfaces/orders-at-risk.interface';
import {
  ORDERS_AT_RISK_GRAPH_NAME,
  ORDERS_AT_RISK_LINE_CHART_NAMES,
} from 'src/app/features/home/components/soe/orders-at-risk/constants/orders-at-risk.constant';
import { GRAPH_LIST_WITH_SECONDARY_Y_AXIS } from 'src/app/features/home/components/soe/constants/soe.constants';
import Networkgraph from 'highcharts/modules/networkgraph';
import Treegraph from 'highcharts/modules/treegraph';
import Venn from 'highcharts/modules/venn';

Venn(Highcharts);
Treegraph(Highcharts);
Networkgraph(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
FullScreen(Highcharts);
HighStock(Highcharts); // added for x-axis scrollbar

@Component({
  selector: 'high-chart',
  templateUrl: './high-chart.component.html',
  styleUrls: ['./high-chart.component.scss'],
})
export class HighChartComponent implements OnInit, OnChanges, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  @Input() seriesData: any;
  updateFlag = false;
  @Input() yAxisLabel: string;
  @Input() xAxisLabel: string;
  @Input() yAxisMin: number;
  @Input() yAxisMax: number;
  @Input() yAxisTickAmount: number;
  @Input() chartHeight: string | number;
  @Input() areasplineOptions: Highcharts.SeriesAreasplineOptions = null;
  @Input() enableLegend: boolean = true;
  @Input() yAxisLabelFormat?: string = '{value}';
  @Input() stacking: Highcharts.OptionsStackingValue;
  @Input() enabledExporting: boolean = false;
  @Input() legendVerticalAlign: Highcharts.VerticalAlignValue = 'top';
  @Input() legendAlign: Highcharts.AlignValue = 'center';
  @Input() legendLayout: Highcharts.OptionsLayoutValue = 'horizontal';
  @Input() drilldownSeriesData: any;
  @Input() dataLabelsEnabled: boolean = false;
  @Input() secondaryYAxisLabel: string;
  @Input() secondaryYAxisLabelFormat?: string = '{value}';
  @Input() enableXAxisScrollbar: boolean = false;
  @Input() showSecondaryYAxis: boolean = false;
  @Output() drilldownEvent = new EventEmitter<any>(); // Emit the event to parent
  @Input() isBarClickable: boolean = false;
  @Output() emitClickedBarDetails = new EventEmitter<any>();
  @Input() graphName: string;
  chart: Highcharts.Chart;
  ordersAtRiskChartName = Object.values(GRAPH_LIST_WITH_SECONDARY_Y_AXIS);
  ordersAtRiskLineChartName = ORDERS_AT_RISK_LINE_CHART_NAMES;
  @Input() chartInverted: boolean = false;
  @Input() allowCollapse: boolean = true;

  constructor() { }
  ngOnInit(): void {
    // const self = this; // Reference the component

    // this.toggleCollapseFunction = (function (H: any) {
    //   if (H.seriesTypes && H.seriesTypes.treegraph) {
    //     const originalToggleCollapse =
    //       H.seriesTypes.treegraph.prototype.pointClass.prototype.toggleCollapse;

    //     H.seriesTypes.treegraph.prototype.pointClass.prototype.toggleCollapse = function () {
    //       if (self.allowCollapse) {
    //         // Call the original toggleCollapse if allowed
    //         originalToggleCollapse.apply(this, arguments);
    //       } else {
    //         console.log('Collapse is disabled for this component!');
    //       }
    //     };
    //   }
    // })(Highcharts);
  }

  initializeChart() {
    if (this.seriesData && this.seriesData.length > 0) {
      this.chartOptions = {
        chart: {
          backgroundColor: 'none',
          height: this.chartHeight,
          inverted: this.chartInverted,
          // events: {
          //   drilldown: (event: any) => this.handleDrilldown(event),
          // },
        },
        exporting: {
          enabled: this.enabledExporting,
          buttons: {
            contextButton: {
              theme: {
                fill: '#131329',
                stroke: '#FFFFFF',
              },
              //symbolStroke: '#FFFFFF',
              //symbolFill: '#131329',
              menuItems: [
                'viewFullscreen',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG',
              ],
            },
          },
        },
        title: {
          text: '',
          style: {
            display: 'none',
          },
        },
        credits: { enabled: false },
        xAxis: this.enableXAxisScrollbar
          ? {
            type: 'category',
            title: {
              text: this.xAxisLabel,
              style: {
                color: '#C3C3CB',
                fontSize: '11px',
                fontFamily: 'EYInterstate-Regular',
              },
            },
            min: 0,
            max: 9,
            scrollbar: {
              enabled: this.enableXAxisScrollbar, // Enable the scrollbar
              trackBorderColor: '#747480',
              trackBorderWidth: 1,
              trackBorderRadius: 0,
              trackBackgroundColor: '#747480',
              barBorderRadius: 5,
              barBorderWidth: 0,
              barBackgroundColor: '#2e2e38',
              //rifleColor: 'yellow',
            },
            labels: {
              style: {
                color: '#AFAEBA',
              },
            },
          }
          : {
            type: 'category',
            title: {
              text: this.xAxisLabel,
              style: {
                color: '#C3C3CB',
                fontSize: '11px',
                fontFamily: 'EYInterstate-Regular',
              },
            },
            labels: {
              useHTML: true, // Enables HTML in labels
              formatter: function () {
                // Use word wrap logic
                var label = this.value;
                var wrappedLabel = String(label).split(' ').join('<br/>');
                return wrappedLabel;
              },
              style: {
                color: '#AFAEBA',
                width: 100, // Set a fixed width for labels
                textOverflow: 'ellipsis', // Optional: Add ellipsis if text overflows
                whiteSpace: 'normal', // Allow the label to wrap
              },
            },
          },
        yAxis: this.showSecondaryYAxis
          ? [
            {
              title: {
                text: this.yAxisLabel,
                style: {
                  color: '#C3C3CB',
                  fontSize: '11px',
                  fontFamily: 'EYInterstate-Regular',
                },
              },
              labels: {
                format: this.yAxisLabelFormat,
                formatter: function () {
                  var value = this.value;
                  if (typeof value === 'number') {
                    if (value >= 1000000000 || value <= -1000000000) {
                      return (value / 1000000000).toFixed(0) + 'B'; // Billion
                    } else if (value >= 1000000 || value <= -1000000) {
                      return (value / 1000000).toFixed(0) + 'M'; // Million
                    } else if (value >= 1000 || value <= -1000) {
                      return (value / 1000).toFixed(0) + 'K'; // Thousand
                    }
                  }
                  return value.toString(); // Less than 1000 or non-numeric
                },
                style: {
                  color: '#AFAEBA',
                  //fontSize: '1em'
                },
              },
              min: this.yAxisMin,
              max: this.yAxisMax,
              tickAmount: this.yAxisTickAmount,
              gridLineColor: '#414257',
              gridLineDashStyle: 'Dash',
            },
            {
              title: {
                //secondary Y axis
                text: this.secondaryYAxisLabel,
                style: {
                  color: '#C3C3CB',
                  fontSize: '11px',
                  fontFamily: 'EYInterstate-Regular',
                },
              },
              labels: {
                format: this.secondaryYAxisLabelFormat,
                formatter: this.ordersAtRiskChartName.includes(this.graphName)
                  ? function () {
                    var value = this.value;
                    return value + '%';
                  }
                  : function () {
                    var value = this.value;
                    if (typeof value === 'number') {
                      if (value >= 1000000000 || value <= -1000000000) {
                        return (value / 1000000000).toFixed(0) + 'B'; // Billion
                      } else if (value >= 1000000 || value <= -1000000) {
                        return (value / 1000000).toFixed(0) + 'M'; // Million
                      } else if (value >= 1000 || value <= -1000) {
                        return (value / 1000).toFixed(0) + 'K'; // Thousand
                      }
                    }
                    return value.toString(); // Less than 1000 or non-numeric
                  },
                style: {
                  color: '#AFAEBA',
                  //fontSize: '1em'
                },
              },
              min: this.ordersAtRiskChartName.includes(this.graphName)
                ? 0
                : this.yAxisMin,
              max: this.ordersAtRiskChartName.includes(this.graphName)
                ? 100
                : this.yAxisMax,
              tickAmount: this.yAxisTickAmount,
              gridLineColor: '#414257',
              gridLineDashStyle: 'Dash',
              opposite: true,
            },
          ]
          : {
            title: {
              text: this.yAxisLabel,
              style: {
                color: '#C3C3CB',
                fontSize: '11px',
                fontFamily: 'EYInterstate-Regular',
              },
            },
            labels: {
              format: this.ordersAtRiskChartName.includes(this.graphName)
                ? this.secondaryYAxisLabelFormat
                : this.yAxisLabelFormat,
              formatter: this.ordersAtRiskChartName.includes(this.graphName)
                ? function () {
                  var value = this.value;
                  return value + '%';
                }
                : function () {
                  var value = this.value;
                  if (typeof value === 'number') {
                    if (value >= 1000000000 || value <= -1000000000) {
                      return (value / 1000000000).toFixed(0) + 'B'; // Billion
                    } else if (value >= 1000000 || value <= -1000000) {
                      return (value / 1000000).toFixed(0) + 'M'; // Million
                    } else if (value >= 1000 || value <= -1000) {
                      return (value / 1000).toFixed(0) + 'K'; // Thousand
                    }
                  }
                  return value.toString(); // Less than 1000 or non-numeric
                },

              style: {
                color: '#AFAEBA',
              },
            },
            min: this.yAxisMin,
            max: this.yAxisMax,
            tickAmount: this.yAxisTickAmount,
            gridLineColor: '#414257',
            gridLineDashStyle: 'Dash',
          },
        legend: {
          enabled: this.enableLegend,
          layout: this.legendLayout,
          align: this.legendAlign,
          verticalAlign: this.legendVerticalAlign,
          itemStyle: {
            color: '#ffffff',
            borderRadius: 5,
            fontSize: '11px',
            fontWeight: 'regular',
            opacity: 0.8,
          },
          itemHoverStyle: {
            color: '#fffff',
            cursor: 'pointer',
          },
          itemCheckboxStyle: {
            color: '#8F8FAC',
            borderRadius: 5,
          },
          symbolRadius: 0,
          //itemDistance: 5,
          //width: 450,
          //itemWidth: 140,
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            zIndex: 1,
            lineColor: '#B61FB0',
            lineWidth: 1,
            marker: {
              enabled: false,
            },
            color: '#2b193f',
            dataLabels: {
              enabled: false,
              inside: true,
              verticalAlign: 'bottom',
              y: 20,
              formatter: function () {
                return this.y + '%';
              },
              style: {
                color: '#f9f9fa',
                fontWeight: 'normal',
              },
            },
          },
          column: {
            stacking: this.stacking,
            zIndex: 2
          },
          areaspline: this.areasplineOptions || {
            marker: {
              enabled: false, // Disable markers for areaspline series
            },
            fillOpacity: 0.4
          },
          line: {
            lineWidth: 2,
          },
          columnpyramid: {
            borderWidth: 0,
          },
          pie: {
            innerSize: '50%', // This creates the "donut" effect
            depth: 45, // 3D effect depth (optional)
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.percentage:.1f} %',
              style: {
                color: '#C3C3CB',
                textOutline: 'none',
                textDecoration: 'none'
              }
            },
            slicedOffset: 16,
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true,
          },
          // pie: {
          //   allowPointSelect: true,
          //   cursor: 'pointer',
          //   dataLabels: {
          //     enabled: true,
          //     format: '<b>{point.name}</b><br>' + '{point.y}' + this.yAxisLabelFormat,
          //     distance: '0',
          //     style: {
          //       fontSize: '10px',
          //       color: '#A7A7DC'
          //     }
          //   },
          //   showInLegend: true
          // },
          networkgraph: {
            keys: ['from', 'to'],
            layoutAlgorithm: {
              type: 'reingold-fruchterman',
              enableSimulation: true,
              linkLength: 100,
              maxIterations: 1000
            },
            link: {
              width: 1,
              color: "#cccccc",
              dashStyle: 'solid',
            },
            dataLabels: {
              enabled: true,
              textPath: {
                enabled: false
              },
              allowOverlap: false,
              linkFormat: '{point.from} → {point.to}',
              format: '{point.name}',
              style: {
                color: '#ffffff',
                fontSize: '10px',
                fontWeight: 'normal',
                textOutline: 'none'
              },
            }
          },
          treegraph: {
            dataLabels: {
              enabled: true,
              align: 'center',
              verticalAlign: 'bottom',
              //y: 10,
              format: '{point.name}',
              style: {
                textAlign: 'center',
                color: '#ffffff',
                fontSize: '10px',
                fontWeight: 'normal',
                textOutline: 'none',
                whiteSpace: 'nowrap',
              },
              crop: false,
              allowOverlap: false
            }
          },
          venn: {
            borderWidth: 0,
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              format: '{point.name}',
              style: {
                color: '#ffffff',
                fontSize: '10px',
                fontWeight: 'normal',
                textOutline: 'none'
              },
            },

          },
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: this.dataLabelsEnabled,
              inside: false,
              format: '{point.y:.1f}' + this.yAxisLabelFormat,
              style: {
                color: '#f9f9fa',
                fontWeight: 'normal',
              },
            },
            cursor: this.isBarClickable ? 'pointer' : 'default',
            point: {
              // events: {
              //   click: this.onPointClick.bind(this),
              // },
              events: {
                click: (event: any) => {
                  this.handleDrilldown(event);
                  this.onPointClick(event);
                },
              },
            },
          },
        },
        series: this.seriesData.slice(),
        drilldown: {
          series: this.drilldownSeriesData, // Drilldown series data
          activeAxisLabelStyle: {
            color: '#AFAEBA',
            textDecoration: 'none',
            fontWeight: 'normal',
          },
          // activeDataLabelStyle: {
          //   fontWeight: 'bold',
          //   color: '#D7D7DC',
          // },
          // breadcrumbs: {
          //   position: {
          //     align: 'left',
          //     verticalAlign: 'top',
          //   },
          //   style: {
          //     color: '#AFAEBA',
          //     fontSize: '11px',
          //     fontWeight: 'bold'
          //   },
          //   buttonTheme: {
          //     fill: '#FFFFFF',
          //     // style: {
          //     //   color: '#874534',
          //     // },
          //     states: {
          //       // hover: {
          //       //   style: {
          //       //     color: '#FFFFFF',
          //       //   }
          //       // },
          //       select: {
          //         style: {
          //           color: '#AFAEBA',
          //         }
          //       }
          //     }
          //   }
          // }
        },
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['seriesData'] && changes['seriesData'].currentValue) {
      this.initializeChart();
      this.updateFlag = true;
    }
  }

  updateChart() {
    if (this.seriesData && this.seriesData.length > 0) {
      this.chartOptions.series = this.seriesData.slice(); // Update with new data
      this.updateFlag = true; // Indicate that the chart needs to be updated

      // Reset updateFlag after the chart has been updated
      setTimeout(() => {
        this.updateFlag = false; // Reset the flag after update
      }, 0);
    }
  }

  handleDrilldown(event: any) {
    const point = event.point;
    if (!event || !point || !point.drilldown) {
      console.warn('Invalid drilldown event');
      return;
    }
    // Emit the drilldown event to the parent component
    this.drilldownEvent.emit({
      point,
      callback: (drilldownSeries) => {
        // Dynamically add drilldown data after API response
        if (drilldownSeries) {
          const chart = this.chart;
          chart.addSeriesAsDrilldown(point, drilldownSeries);
        }
      },
    });
  }

  // Click event handler for map points
  onPointClick(event: Highcharts.PointClickEventObject): void {
    const dataToEmit: IClickedBarDetails = {
      graphName: this.graphName,
      xAxisLabel: event?.point?.options?.name,
    };
    this.emitClickedBarDetails.emit(dataToEmit);
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  constructor() { }

  private filterDataSource = new BehaviorSubject<any>(null);
  currentFilterData = this.filterDataSource.asObservable();

  private isFilterReset = new BehaviorSubject<any>(false);
  getFilterResetState = this.isFilterReset.asObservable();


  updateFilterData(data: any) {
    this.filterDataSource.next(data);
  }

  setFilterResetState(data: any) {
    this.isFilterReset.next(data);
  }
}

import { MENU_DATA_DETAILS } from "./menu-constant-deta";

// Priveleges - 1 VP of Operations, 2 Plant Operator, 3 Admin
export const MENU_DATA = {
  1: [
    MENU_DATA_DETAILS.DASHBOARD,
    MENU_DATA_DETAILS.APPSTORE,
    MENU_DATA_DETAILS.SC_ASSISTANT,
    MENU_DATA_DETAILS.DATA_MGT,
    MENU_DATA_DETAILS.OTIF,
    MENU_DATA_DETAILS.OEE,
    MENU_DATA_DETAILS.TRACEABILITY,
    MENU_DATA_DETAILS.SC_COSTING,
    //MENU_DATA_DETAILS.TRANSPORTATION,
    MENU_DATA_DETAILS.INVENTORY,
    MENU_DATA_DETAILS.PROCUREMENT,
    MENU_DATA_DETAILS.SOE,
    MENU_DATA_DETAILS.SUPPLY_CHAIN_RESILIENCY,
    MENU_DATA_DETAILS.CHANGE_MANAGEMENT,
    MENU_DATA_DETAILS.ESG,
    MENU_DATA_DETAILS.FORECAST_REVIEW,
    MENU_DATA_DETAILS.RETAIL,
    MENU_DATA_DETAILS.ASSISTANT2,
    MENU_DATA_DETAILS.ASSISTANT3
    // MENU_DATA_DETAILS.ASTERISK,
    // MENU_DATA_DETAILS.ASTERISK_BRIDGESTONE,
    //MENU_DATA_DETAILS.SUPPLY_PERFORMANCE_MONITO
  ]
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './features/home/components/header/header.component';

import { MsalRedirectComponent } from '@azure/msal-angular';
import { HomeComponent } from './features/home/components/home/home.component';
import { NavComponent } from './common/components/nav/nav.component';
import { MainComponent } from './features/main/components/main/main.component';
import { PbiLoaderComponent } from './common/components/pbi-loader/pbi-loader.component';
import { IframeLoaderComponent } from './common/components/iframe-loader/iframe-loader.component';
import { SideNavComponent } from './common/components/side-nav/side-nav.component';
import { MenuItemComponent } from './common/components/side-nav/menu-item/menu-item.component';
import { BreadcrumbComponent } from './common/components/breadcrumb/breadcrumb.component';
import { RightSideNavComponent } from './common/components/right-side-nav/right-side-nav.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    NavComponent,
    MainComponent,
    PbiLoaderComponent,
    IframeLoaderComponent,
    SideNavComponent,
    MenuItemComponent,
    BreadcrumbComponent,
    RightSideNavComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { CARD_DATA } from './constants/card-data.constant';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-simulate-scenario',
  templateUrl: './simulate-scenario.component.html',
  styleUrls: ['./simulate-scenario.component.scss']
})
export class SimulateScenarioComponent implements OnInit {

  cards = CARD_DATA.cards;
  months = CARD_DATA.months;
  skus = CARD_DATA.skus;
  cubeSizes = CARD_DATA.cubeSizes;
  cartoonSizes = CARD_DATA.cartoonSizes;
  scenarios = CARD_DATA.scenarios;
  packingEfficiency: number = 50; // Add this property
  processingLineUtil: number = 50;
  demandVariation: number = 50;
  wastage: number = 50;
  changeoverHours: number = 12;
  originalDemand: number = 200000;
  isRunning: boolean = false;
  colors = [
    '#188CE5',
    '#DA5BB3',
    '#9D7CD4',
    '#229B9A',
    '#E79ECD',
    '#D07200'
  ];

  selectedOptions: { [key: string]: string[] } = {}; // To store user selections
  scenarioTitle: string = '';
  selectedPackagingLine: string = '';
  selectedProcessingLine: string = '';
  availableDays: number | null = null;
  selectedMonth: string = '';
  processingRate: string = '';
  selectedFoodCategory: string = '';
  changeoverFrom: string = '';
  changeoverTo: string = '';
  selectedSKU: string = '';
  currentCubeSize: string = '';
  modifiedCubeSize: string = '';
  currentCartoonSize: string = '';
  modifiedCartoonSize: string = '';


  isRunning1 = false;
  isRunning2 = false;
  isRunning3 = false;

  constructor(private toastrService: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }

  // Toggle selected options for primary factors
  toggleOption(category: string, option: string) {
    if (!this.selectedOptions[category]) {
      this.selectedOptions[category] = [];
    }
    const index = this.selectedOptions[category].indexOf(option);
    if (index === -1) {
      this.selectedOptions[category].push(option);
    } else {
      this.selectedOptions[category].splice(index, 1);
    }
  }

  isPrimaryFactorsValid(): boolean {
    return this.cards.every(card => (this.selectedOptions[card.heading] || []).length > 0);
  }

  // Function to collect scenario data
  collectScenarioData() {
    return {
      scenarioTitle: this.scenarioTitle.trim() || 'Untitled Scenario', // Ensure title is included
      primaryFactors: this.selectedOptions,
      optionalInputs: {
        packagingLine: this.selectedPackagingLine || '',
        processingLine: this.selectedProcessingLine || '',
        availableDays: this.availableDays || '',
        selectedMonth: this.selectedMonth || '',
        processingRate: this.processingRate || '',
        foodCategory: this.selectedFoodCategory || '',
        changeoverFrom: this.changeoverFrom || '',
        changeoverTo: this.changeoverTo || '',
        sku: this.selectedSKU || '',
        currentCubeSize: this.currentCubeSize || '',
        modifiedCubeSize: this.modifiedCubeSize || '',
        currentCartoonSize: this.currentCartoonSize || '',
        modifiedCartoonSize: this.modifiedCartoonSize || '',
        packingEfficiency: this.packingEfficiency,
        processingLineUtil: this.processingLineUtil,
        demandVariation: this.demandVariation,
        wastage: this.wastage,
        changeoverHours: this.changeoverHours,
        originalDemand: this.originalDemand,
        modifiedDemand: this.getModifiedDemand()
      }
    };
  }

  getModifiedDemand(): number {
    return this.originalDemand + (this.originalDemand * (this.demandVariation / 100));
  }

  runScenario() {
    if (!this.isPrimaryFactorsValid()) {
      this.toastrService.error('Please select at least 1 option from each primary factor category.');
      return;
    }

    this.isRunning = true;
    setTimeout(() => {
      this.isRunning = false;
      const scenarioData = this.collectScenarioData();
      console.log('Scenario Data:', JSON.stringify(scenarioData, null, 2)); // Logs collected data
      this.toastrService.success('Simulation complete!');
      this.router.navigate(['/home/network-model/scenario1']); // Navigate to results
    }, 5000);
  }

  runSmallScenario(description: string, buttonNumber: number): void {
    if (buttonNumber === 1 && this.isRunning1) return;
    if (buttonNumber === 2 && this.isRunning2) return;
    if (buttonNumber === 3 && this.isRunning3) return;

    if (buttonNumber === 1) this.isRunning1 = true;
    if (buttonNumber === 2) this.isRunning2 = true;
    if (buttonNumber === 3) this.isRunning3 = true;

    setTimeout(() => {
      this.toastrService.success(`${description} simulation completed!`);

      if (buttonNumber === 1) this.isRunning1 = false;
      if (buttonNumber === 2) this.isRunning2 = false;
      if (buttonNumber === 3) this.isRunning3 = false;
    }, 5000);
  }
}

<div class="main-container">
    <div class="row ps-container">
        <mat-tab-group class="ps-tab-group" mat-align-tabs="left" dynamicHeight="true"
            [selectedIndex]="getSelectedIndex()" (selectedTabChange)="onTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <div class="tab-header">Production Scheduling Details</div>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <app-prod-scheduling-details></app-prod-scheduling-details>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <div class="tab-header">Direct Shipments</div>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <app-direct-shipping></app-direct-shipping>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <div class="tab-header">Production Scheduling Approvals</div>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                   <app-prod-scheduling-approvals></app-prod-scheduling-approvals>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
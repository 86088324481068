<div *ngIf="breadcrumbs.length > 0" class="breadcrumb-wrapper">
    <nav class="breadcrumb-container">
        <ol class="breadcrumb">
            <li *ngFor="let breadcrumb of breadcrumbs; let last = last"
                [ngClass]="{ 'breadcrumb-item': true, 'active': last }">
                <a class="breadcrumb-lbl" *ngIf="!last"
                    [routerLink]="breadcrumb.routerLink ? breadcrumb.routerLink : null">
                    {{ breadcrumb.label }}
                </a>
                <span class="breadcrumb-lbl last-breadcrumb" *ngIf="last"
                    [routerLink]="breadcrumb.routerLink ? breadcrumb.routerLink : null" routerLinkActive="active-route">
                    {{ breadcrumb.label }}</span>
            </li>
        </ol>
    </nav>
    <div class="actions-container">
        <div class="left-actions">
            <button class="daily-btn" mat-raised-button [routerLink]="['/home/soe/redeployment-approval-impact']"
                routerLinkActive="active-route" *ngIf="isDailyButtonVisible">
                Redeployment Approval
            </button>
            <button class="daily-btn" mat-raised-button [routerLink]="['/home/soe/production-scheduling']"
                routerLinkActive="active-route" *ngIf="isDailyButtonVisible">
                Production Scheduling
            </button>

            <button class="weekly-btn" mat-raised-button [routerLink]="['/home/soe/projected-shortages']"
                routerLinkActive="active-route" *ngIf="isWeeklyButtonVisible">
                Projected Shortages
            </button>
            <button class="weekly-btn" mat-raised-button [routerLink]="['/home/soe/forecast-consumption']"
                routerLinkActive="active-route" *ngIf="isWeeklyButtonVisible">
                Forecast Consumption
            </button>
            <button class="weekly-btn" mat-raised-button routerLinkActive="active-route" *ngIf="isWeeklyButtonVisible"
                [routerLink]="['/home/soe/production-attainment']">
                Production Attainment
            </button>
            <button class="weekly-btn" mat-raised-button [routerLink]="['/home/soe/inventory-health']"
                routerLinkActive="active-route" *ngIf="isWeeklyButtonVisible">
                Inventory Health
            </button>
            <button class="weekly-btn" mat-raised-button [routerLink]="['/home/soe/weekly-warehouse-utilization']"
                routerLinkActive="active-route" *ngIf="isWeeklyButtonVisible">
                Warehouse Utilization
            </button>
            <button class="weekly-btn" mat-raised-button [routerLink]="['/home/soe/weekly-truck-availability']"
                routerLinkActive="active-route" *ngIf="isWeeklyButtonVisible">
                Truck Availability
            </button>

            <button class="resiliency-btn" mat-raised-button [routerLink]="['/home/alerts-details']"
                routerLinkActive="active-route" *ngIf="isResiliencyAlertBtnVisible">
                Alerts Details
            </button>
            <button class="resiliency-btn" mat-raised-button routerLinkActive="active-route"
                *ngIf="isResiliencyAlertBtnVisible">
                Resolution Details
            </button>
            <button class="resiliency-btn" mat-raised-button [routerLink]="['/home/supplier-mapping']"
                routerLinkActive="active-route" *ngIf="isResiliencyAlertBtnVisible">
                N-Tier Visibility
            </button>
        </div>
        <div class="right-actions">
            <button class="weekly-btn" mat-raised-button [routerLink]="['/home/output-template']"
                routerLinkActive="active-route" *ngIf="isExecutiveViewVisible">
                Forecast Review
            </button>
            <!-- <button class="home-btn" mat-icon-button [routerLink]="['/home/forecast_review']"
                routerLinkActive="active-route" *ngIf="isExecutiveViewVisible">
                <mat-icon class="home-icon">home</mat-icon>
            </button> -->
            <mat-button-toggle-group *ngIf="showQuantityValueToggle" class="toggle-btn"
                (change)="getSelectedToggle($event)">
                <mat-button-toggle value="true" [checked]="true"><span class="label">Quantity</span></mat-button-toggle>
                <mat-button-toggle value="false"><span class="label">Value</span></mat-button-toggle>
            </mat-button-toggle-group>

            <mat-button-toggle-group *ngIf="showAsIsOrRecommendationToggle" class="toggle-btn"
                (change)="getAsIsOrRecommendationToggle($event)">
                <mat-button-toggle value="true" [checked]="true"><span class="label">As is</span></mat-button-toggle>
                <mat-button-toggle value="false"><span class="label">With accepted
                        recommendations</span></mat-button-toggle>
            </mat-button-toggle-group>

            <button class="filter-btn" mat-icon-button (click)="toggleRightSidebar()" *ngIf="isButtonVisible">
                <mat-icon class="filter-icon" matTooltip="Open Filter">filter_alt</mat-icon>
            </button>

            <div class="documents-link" *ngIf="isVisibleDocLink">
                <a class="document-link" (click)="openDocuments()">
                    Documents
                </a>
            </div>
        </div>
    </div>

</div>
<div class="warehouse-main-container">
  <ng-container>
    <!-- summary card start -->
    <div class="row">
      <div class="col" *ngFor="let data of wmsSummaryCardData; let i = index">
        <div class="card card-stats mb-4 mb-xl-0 summary-card warehouse-card">
          <div class="card-body">
            <div class="row">
              <div class="col header">
                <span class="card-title">{{ data.name }}</span>
              </div>
            </div>
          </div>
          <div class="score-txt">
            <div class="score" [ngStyle]="{ color: gradientColors(i) }">
              {{ data.currentYear }}{{data.unit}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- summary card end -->

    <!-- Forecasted DC Utilization Start -->
    <div class="row">
      <div class="col-md-12 forecasted-dc-utilization-container">
        <div class="map-card">
          <div class="header-div">
            <div class="title">
              {{ utilizedDCCapacityChartData?.title }}
            </div>
          </div>
          <ng-container>
            <div *ngIf="utilizedDCCapacityChartData?.chartData?.length > 0" class="utilization-chart-container">
              <high-chart [enableLegend]="true" [yAxisLabelFormat]="'{value}'" [secondaryYAxisLabelFormat]="'{value}'"
                [yAxisTickAmount]="6" [xAxisLabel]="utilizedDCCapacityChartData?.xAxisLabel" [yAxisLabel]="
                utilizedDCCapacityChartData?.yAxisLabel || yAxisLabel
                " [seriesData]="utilizedDCCapacityChartData?.chartData" [chartHeight]="280" [isBarClickable]="true"
                [showSecondaryYAxis]="true" [yAxisMin]="yAxisMin" [yAxisMax]="yAxisMax" [secondaryYAxisMin]="yAxisMin"
                [secondaryYAxisMax]="yAxisMax" [graphName]="graphName?.CAPACITY_IN_PALLETS"
                (emitClickedBarDetails)="getClickedBarDetails($event)">
              </high-chart>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Forecasted DC Utilization end -->

    <!-- Incoming Vs Outgoing Shipment Start -->
    <div class="row" *ngIf="ioShipmentChartData?.chartData?.length > 0">
      <div class="col-md-12 forecasted-dc-utilization-container">
        <div class="map-card">
          <div class="header-div d-flex">
            <div class="title">
              Incoming vs Outgoing Shipments of Distribution Center {{ selectedDC }}
            </div>
            <div class="close-icon"><mat-icon (click)="closeIOShipmentChart()">close</mat-icon></div>
          </div>
          <ng-container>
            <div *ngIf="ioShipmentChartData?.chartData?.length > 0" class="utilization-chart-container">
              <high-chart [enableLegend]="true" [yAxisLabelFormat]="'{value}'" [yAxisTickAmount]="6"
                [xAxisLabel]="ioShipmentChartData?.xAxisLabel" [yAxisLabel]="
                ioShipmentChartData?.yAxisLabel || yAxisLabel
                " [seriesData]="ioShipmentChartData?.chartData" [chartHeight]="280">
              </high-chart>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Incoming Vs Outgoing Shipment end -->

    <!-- Incoming Vs Outgoing Shipment loader start -->
    <div *ngIf="displayIOShipmentLoader" class="d-flex justify-content-center loader">
      <mat-spinner class="loading"></mat-spinner>
    </div>
    <!-- Incoming Vs Outgoing Shipment loader end -->

    <!-- Incoming Vs Outgoing Shipment error message container start -->
    <div *ngIf="showIOShipmentChartApiError" class="d-flex justify-content-center error-container">
      <div class="row error-label"> Error occurred while fetching API Data. Please try After some time.</div>
    </div>
    <!-- Incoming Vs Outgoing Shipment error message container end -->

    <!-- Space Utilization Color Representation Start -->
    <div class="row">
      <div class="col" *ngFor="let data of spaceUtilizationData; let i = index">
        <div class="card card-stats mb-4 mb-xl-0 summary-card space-utilization-card">
          <div class="card-body">
            <div class="row">
              <div class="col header">
                <div [style.background]="utilizationColors[i]" class="color-container"></div>
                <span class="card-title">{{ data.name }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col subheader">
                <span class="card-subtitle">{{ data.lastYear }}</span>
              </div>
            </div>
          </div>
          <div class="score-txt">
            <div class="score" [style.color]="utilizationColors[i]">
              {{ data.currentYear }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Space Utilization Color Representation End -->

    <!-- Forecasted DC Utilization % start -->
    <div class="row">
      <div class="col-md-12 forecasted-dc-utilization-container">
        <div class="map-card">
          <div class="header-div">
            <div class="title">
              Forcasted DC Utilization%
            </div>  
          </div>
          <ng-container>
            <div class="utilization-table-container">
              <app-mat-table [dataSource]="forecastedDCUtilizationTableData?.data"
                [displayedColumns]="forecastedDCUtilizationTableData?.columns"
                [headers]="forecastedDCUtilizationTableData?.headers" [isStickyHeader]="true" [htmlView]="viewPage"
                [showPaginator]="false" [isStickyHeader]="true" [colorClassList]="utilizationTableColorClassList"
                (rowClicked)="getSelectedDc($event)"></app-mat-table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Forecasted DC Utilization % end -->
  </ng-container>
</div>
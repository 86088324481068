import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URLS } from '../constants/api.urls';
import { RedeploymentApprovalsFilterList } from '../features/home/components/soe/redeployment-approvals/redeployment-approvals.interface';

@Injectable({
  providedIn: 'root'
})
export class RedeploymentApprovalsService {
  constructor(private http: HttpClient) {}

  getRedeploymentApprovalsFilters(): Observable<RedeploymentApprovalsFilterList> {
    return this.http.get<RedeploymentApprovalsFilterList> (
      API_URLS.getRedeploymentApprovalFilters
    );
  }

  getApprovalTableData(payload: any): Observable<any> {
    return this.http.post(API_URLS.getApprovalTableData, payload)
  }

  checkIfSubmitAllowed(): Observable<any>{
    return this.http.get(API_URLS.checkIfSubmitAllowed);
  }

  submitRedeploymentScenario(payload: any): Observable<any> {
    return this.http.post<any>(API_URLS.submitRedeploymentScenario, payload);
  }

  getCardData(): Observable<any> {
    return this.http.get<any>(API_URLS.getCardData)
  }
}

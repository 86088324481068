<mat-toolbar class="header" color="primary">
    <!-- EY Logo Section -->
    <!-- Preload and optimize the logo image -->
    <link rel="preload" href="assets/dist/images/EY_Logo_Beam_RGB.png" as="image">
    <div class="ey-vc" (click)="gotoMainPage()">
        <img src="assets/dist/images/EY_Logo_Beam_RGB.png" alt="EY Logo" class="ey-logo" />
        <p class="heading-class title-content">
            {{title ?? 'Supply Chain & Ops Platform'}}
        </p>
    </div>

    <div class="toolbar-spacer"></div>

    <mat-card class="mission-card">
        <mat-card-content class="mission-content">
            <div class="left-section">
                <img src="assets/dist/images/SCHolmes.png" alt="SCHolmes" class="logo-icon" />
            </div>

            <div class="middle-section">
                <mat-form-field class="mission-field" appearance="outline">
                    <input type=" text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                        placeholder="Greetings! I'm SCHolmes, Your Supply Chain Detective. How can I Solve Your Puzzle?">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="autocompleteTrigger.closePanel()">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="right-section">
                <button mat-raised-button color="primary" class="activate-button" (click)="sendMessage()">
                    <mat-icon class="ads_click_icon">ads_click</mat-icon>
                    <span class="button-text">Solve Now</span>
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="toolbar-spacer"></div>

    <div class="logoutDiv">
        <mat-menu #userMenu="matMenu" class="profile-mat-menu">
            <div class="user-info">
                <div class="row">
                    <div class="col-md-2">
                        <div class="circle">
                            <img *ngIf="profilePicUrl" [src]="profilePicUrl" class="profile-pic"
                                alt="Profile Picture" />
                            <span *ngIf="!profilePicUrl" class="initials">{{ userInitials }}</span>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <span class="user-name">{{ loggedInUser |uppercase }}</span>
                        <span class="user-email">{{ loggedInEmail }}</span>
                    </div>
                </div>
            </div>

            <mat-divider></mat-divider>
            <ng-container *ngFor="let role of userRoles">
                <button mat-menu-item (click)="userDefinedTabs(role)">
                    <mat-icon>credit_card</mat-icon>
                    <span>{{ role }}</span>
                </button>
            </ng-container>

            <button mat-menu-item disabled>
                <mat-icon>settings</mat-icon>
                <span>Settings</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="userMenu" class="user-button">
            <div class="circle">
                <img *ngIf="profilePicUrl" [src]="profilePicUrl" class="profile-pic" alt="Profile Picture" />
                <span *ngIf="!profilePicUrl" class="initials">{{ userInitials }}</span>
            </div>
        </button>
    </div>
</mat-toolbar>
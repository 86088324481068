export const FILL_RATE_LINE_CHART_NAME = 'Fill Rate';

export const MIN_X_AXIS_LIMIT = 14;

export const SERIES_CHART_COLORS = [
  //'#082C65',
  '#1777CF',
  '#AC6F0E',
  // '#DA5BB3',
  // '#9D7CD4',
  // '#E79ECD',
  //'#FF736A',
];

export const CAPACITY_CHART_COLORS = [
  '#4bac0e', //green
  '#AC6F0E',
  

]

export const PSA_SUMMARY_CARD = {
  data: [
    {
      name: 'Orders at Risk',
      icon: null,
      currentYear: '15',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Quantity at risk',
      icon: null,
      currentYear: '326',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Available Production Capacity',
      icon: null,
      currentYear: '300',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Value at risk',
      icon: null,
      currentYear: '$ 1.1M',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Capacity Utilization',
      icon: null,
      currentYear: '87%',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Fulfilment',
      icon: null,
      currentYear: '90%',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
  ],
};

export const PACKED_EXCESS_CAPACITY_DATA = [
  {
    type: 'column',
    color: '#0e4bac',
    name: 'Excess',
    data: [
      {
        name: 'p10',
        y: 4550,
      },
      {
        name: 'p11',
        y: 6770,
      },
      {
        name: 'p12',
        y: 5670,
      },
    ],
  },
  {
    type: 'column',
    color: '#4bac0e',
    name: 'planned',
    data: [
      {
        name: 'p10',
        y: 4560,
      },
      {
        name: 'p11',
        y: 56670,
      },
      {
        name: 'p12',
        y: 4678,
      },
    ],
  },
];

export const QTY_Y_AXIS_LABEL = 'Quantity';

export const CAPACITY_Y_AXIS_LABEL = 'Capacity';

export const INVENTORY_HEADERS = [
  'Material ID',
  'DIOH',
  'On Hand Inventory',
  'Average Weekly Forecast',
];

export const INVENTORY_HEADER_OBJ = {
  Material_ID: 'Material ID',
  DIOH: 'DIOH',
  OnHand: 'On Hand Inventory',
  Average_Weekly_Forecast: 'Average Weekly Forecast',
};

export const INVENTORY_COLUMNS = [
  "Material_ID",
  "DIOH",
  "OnHand",
  "Average_Weekly_Forecast"
]

export const QUANTITY_COLUMNS = [
  'action',
  'SKU',
  'Qty at Risk',
  'Requested Delivery Date',
  'Next Production Batch',
  'Plant ID',
  'Available Production Capacity'
];

export const QUANTITY_HEADERS = {
  action: 'SKU',
  SKU: 'Qty at Risk',
  'Qty at Risk': 'Requested Delivery Date',
  'Requested Delivery Date': 'Next Production Batch',
  'Next Production Batch': 'Plant',
  'Plant': 'Available Production Capacity'
};

export const PRODUCTION_SCHEDULING_GRAPH_NAME = {
  QTY_AT_RISK: 'Quantity at Risk',
  EXCESS_CAPACITY: 'Packed Vs Excess capacity',
  QTY_AT_RISK_DETAILS_TBL: 'Quantity at risk details table',
  INVENTORY_TABLE: 'Inventory_table',
};

export const QTY_RISK_DETAILS_HEADER = [
  'SKU',
  'Qty at Risk',
  'Requested Delivery Date',
  'Next Production Batch',
  'Plant',
];

export const DS_SUMMARY_CARD = {
  data: [
    {
      name: 'Oppurtunities for DS',
      icon: null,
      currentYear: '8',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Potential Savings',
      icon: null,
      currentYear: '$100K',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Average Truck load utilization',
      icon: null,
      currentYear: '83%',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'DS conversion rate',
      icon: null,
      currentYear: '0',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
  ],
};

export const DS_TABLE_NAME = {
  QUANTITY: 'Shipment Details',
  VALUE: 'Shipment Details',
};

export const DS_SHIPMENT_TABLE_DATA = [
  {
    rowName: 'Open Orders',
    day0: 5,
    day1: 3,
    day2: 6,
    day3: 3,
    day4: 7,
    day5: 3,
    day6: 2,
    day7: 3,
    day8: 6,
    day9: 7,
    day10: 4,
    day11: 2,
    day12: 5,
    day13: 7,
    day14: 6,
  },
  {
    rowName: 'Ship from DC',
    day0: 5,
    day1: 3,
    day2: 3,
    day3: 3,
    day4: 4,
    day5: 3,
    day6: 2,
    day7: 1,
    day8: 6,
    day9: 7,
    day10: 4,
    day11: 2,
    day12: 5,
    day13: 7,
    day14: 6,
  },
  {
    rowName: 'Ship from Plant',
    day0: 0,
    day1: 0,
    day2: 3,
    day3: 0,
    day4: 3,
    day5: 0,
    day6: 0,
    day7: 2,
    day8: 0,
    day9: 7,
    day10: 4,
    day11: 2,
    day12: 5,
    day13: 7,
    day14: 6,
  },
  {
    rowName: 'Opportunity - Direct shipment',
    day0: 0,
    day1: 1,
    day2: 0,
    day3: 1,
    day4: 0,
    day5: 2,
    day6: 2,
    day7: 0,
    day8: 2,
    day9: 7,
    day10: 4,
    day11: 2,
    day12: 5,
    day13: 7,
    day14: 6,
  },
];

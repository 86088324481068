<div class="modal-container">
    <div class="title-container">
        <h2 mat-dialog-title class="title">Plant Production Order Details</h2>
        <span class="close-icon"><mat-icon mat-dialog-close>cancel</mat-icon></span>
    </div>
    <mat-dialog-content class="mat-typography">
        <div class="content-container">
            <div class="content">
                <div *ngIf="modalTableData?.data?.length === 0" class="no-data-message">
                    No data available for the selected plant
                </div>
                <div class="sku-table" *ngIf="modalTableData?.data?.length > 0">
                    <app-mat-table [dataSource]="modalTableData?.data" [displayedColumns]="modalTableData?.columns"
                        [headers]="modalTableData?.headers" [isStickyHeader]="true"
                        [showPaginator]="showPaginator"></app-mat-table>
                </div>
                <div *ngIf="displayLoader" class="d-flex justify-content-center loader">
                    <mat-spinner class="loading"></mat-spinner>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
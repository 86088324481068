export const SOE_VIEWS = {
  SKU_PROJECTIONS: 'SKU Projections',
  ORDERS_AT_RISK_MODAL_TABLE: 'Orders at Risk Modal Table',
  WAREHOUSE_UTILIZATION: 'Warehouse Utilization',
};

export const GRAPH_LIST_WITH_SECONDARY_Y_AXIS = {
  OTIF_BY_CUSTOMER: 'OTIF by Customer',
  OTIF_BY_RDD: 'OTIF by RDD',
  OTIF_BY_LOCATION: 'OTIF by Location',
  QTY_AT_RISK: 'Quantity at Risk',
  FORECAST_CHART:'Forecast Chart',
  PROJECTED_SHORTAGE_FILL_RATE:'Fill Rate',
};

import { Component, OnInit } from '@angular/core';
import { DEMAND_ALLOCATION_DATA } from '../constants/card-data.constant';

@Component({
  selector: 'app-scenario-result',
  templateUrl: './scenario-result.component.html',
  styleUrls: ['./scenario-result.component.scss']
})
export class ScenarioResultComponent implements OnInit {


  demandAllocationData = DEMAND_ALLOCATION_DATA.chartData;

  constructor() { }

  ngOnInit(): void {
  }

}

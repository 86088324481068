import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MsalService } from '@azure/msal-angular';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';
import { SUMMARYCARD_COLORS } from 'src/app/shared/constants/color-palette.constant';
import { STATUS_FILTER_DROPDOWN } from '../../constants/redeployment-approval.constant';
import { RedeploymentApprovalsService } from '../../services/redeployment-approvals.service';
import { IRecommendationApprovalRequestBody } from '../../interfaces/redeployment-approvals.interface';
import { FilterDataService } from 'src/app/services/filter-data/filter-data.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-redeployment-approvals',
  templateUrl: './redeployment-approvals.component.html',
  styleUrls: ['./redeployment-approvals.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class RedeploymentApprovalsComponent implements OnInit, OnDestroy {
  loadingMessage = "Just a moment, we're getting things ready for you....";
  apiErrorMessage = 'Oops! Something went wrong. Please try again later.';
  displayedColumns: string[] = [
    'Details',
    'Source Location',
    'Destination Location',
    'Transfer Date',
    'SKU',
    'Tlane_Mode_Type',
    'Quantity',
    'Pallets Space Required',
    'Trucks availability at Source',
    'Pallets availability at Destination',
    'Cost of Redeployment',
    'Revenue',
    'Difference in USD',
    'Actions',
  ];

  childColumnsToDisplay: string[] = [
    'Source Location',
    'Destination Location',
    'Transfer Date',
    'SKU',
    'Tlane_Mode_Type',
    'Quantity',
    'Pallets Space Required',
    'Trucks availability at Source',
    'Pallets availability at Destination',
    'Cost of Redeployment',
    'Revenue',
    'Difference in USD',
  ];

  columnDisplayNames: { [key: string]: string } = {
    'Source Location': 'Source Location',
    'Destination Location': 'Destination Location',
    'Transfer Date': 'Transfer Date',
    SKU: 'SKU',
    Tlane_Mode_Type: 'Transport Mode',
    Quantity: 'Quantity',
    'Pallets Space Required': 'Quantity in Pallets',
    'Trucks availability at Source': 'Trucks Availability in Pallets',
    'Pallets availability at Destination': 'WH Availability in Pallets',
    'Cost of Redeployment': 'Logistics Cost',
    Revenue: 'Revenue',
    'Difference in USD': '$ Difference',
  };

  isLoading: boolean = true;
  tableData: any[] = [];
  filteredData = [];
  selectedFlag: string = '';
  expandedRow: any | null = null;
  isSubmitAllowed: boolean = false;
  showDropdown: boolean = false;
  isInProgress: boolean = false;
  summaryCardData: any = [];
  isRecordSelected: boolean = false;
  @Output() filterChange = new EventEmitter<any>();
  private activeSearchIndex: number = -1;
  @Input() columns: string[] = [];
  showSearch: boolean[] = [];
  rowIndices: { [key: string]: number } = {};
  filterValues: { [key: string]: string } = {
    'Source Location': '',
    'Destination Location': '',
    Tlane_Mode_Type: '',
    Flag: '',
    SKU: '',
  };
  selectedAction: string[] = [];
  displayLoader: boolean = false;
  statusDropdownList = STATUS_FILTER_DROPDOWN;
  selectedFilters: IRecommendationApprovalRequestBody;
  selectedButtons: { [key: string]: string } = {}; // Track user choices per row ('approved' or 'rejected')
  selectionStatus: { [key: string]: { status: string; data: any } } = {};
  private subscriptions$: Array<Subscription> = new Array<Subscription>();
  private summaryCardSubscriptions$: Array<Subscription> = new Array<Subscription>();
  private submitSelectionsSubscriptions$: Array<Subscription> = new Array<Subscription>();
  private submitAllowedSubscriptions$: Array<Subscription> = new Array<Subscription>();
  private tableDataSubscriptions$: Array<Subscription> = new Array<Subscription>();

  constructor(
    private redeploymentApprovalsService: RedeploymentApprovalsService,
    private msalService: MsalService,
    private readonly toasterService: ToasterWrapperService,
    private filterDataService: FilterDataService
  ) {
    this.getRecommendationFilter();
  }

  ngOnInit(): void { }

  getRecommendationFilter(): void {
    this.subscriptions$.push(
      this.filterDataService.currentFilterData
        .pipe(debounceTime(300))
        .subscribe((filterData) => {
          if (filterData != null) {
            this.selectedFlag = '';
            this.filterValues = {
              'Source Location': '',
              'Destination Location': '',
              Tlane_Mode_Type: '',
              Flag: '',
              SKU: '',
            };
            this.tableData = [];
            this.summaryCardData = [];
            this.selectedFilters = {
              SKU: filterData?.SKU,
              'Source Location': filterData?.source,
              'Destination Location': filterData?.destination,
              ModeOfTransport: filterData?.modeOfTransport,
              transferDateMin: filterData?.deliveryDateMin,
              transferDateMax: filterData?.deliveryDateMax,
              Flag: filterData?.actions,
            };
            this.getSummaryCardData();
            this.getTableData();
            this.checkIfSubmitAllowed();
            this.showSearch = this.displayedColumns.map(() => false);
          }
        })
    );
  }

  // Returns the display name for the column header
  getHeader(column: string): string {
    const headers = {
      'Source Location': 'Source Location',
      'Destination Location': 'Destination Location',
      'Transfer Date': 'Transfer Date',
      'Requirement Date': 'Requirement Date',
      SKU: 'SKU',
      Quantity: 'Quantity',
      Revenue: 'Revenue',
      'Cost of Redeployment': 'Logistics Cost',
    };
    return headers[column] || column;
  }

  gradientColors(index: number): string {
    const colors = SUMMARYCARD_COLORS;
    return colors[index % colors.length];
  }

  getSummaryCardData(): void {
    this.isLoading = true;
    this.summaryCardSubscriptions$.push(this.redeploymentApprovalsService
      .getSummaryCardData(this.selectedFilters)
      .subscribe(
        (response) => {
          this.summaryCardData = response.data;
          this.isLoading = false;
        },
        (error) => {
          this.toasterService.error(
            'Error occurred while fetching summary data'
          );
          console.error('Error fetching data:', error);
          // Stop the loader even in case of errors
          this.isLoading = false;
        }
      )
    );
  }

  approve(row: any, action: string): void {
    if (this.selectedAction[row.id] === action) {
      this.selectedAction[row.id] = null;
      delete this.selectionStatus[row.SKU];
      this.checkSubmitEnabled();
    } else {
      this.selectedAction[row.id] = action;
      this.selectedButtons[row.SKU] = 'approved';
      this.selectionStatus[row.SKU] = { status: 'approved', data: row };
      this.isRecordSelected = true;
      this.checkSubmitEnabled(); // Check if submit should be enabled
    }
  }

  reject(row: any, action: string): void {
    if (this.selectedAction[row.id] === action) {
      this.selectedAction[row.id] = null;
      delete this.selectionStatus[row.SKU];
      this.checkSubmitEnabled();
    } else {
      this.selectedAction[row.id] = action;
      this.selectedButtons[row.SKU] = 'rejected';
      this.selectionStatus[row.SKU] = { status: 'rejected', data: row };
      this.isRecordSelected = true;
      this.checkSubmitEnabled(); // Check if submit should be enabled
    }
  }

  checkSubmitEnabled(): void {
    this.isRecordSelected = Object.keys(this.selectionStatus).length > 0;
  }

  submitSelections(): void {
    this.displayLoader = true;
    const results = Object.values(this.selectionStatus).map((item) => ({
      ...item.data,
      Flag: item.status === 'approved' ? 1 : 0,
      child: item.data.child?.map((data: any) => {
        return { ...data, Flag: item.status === 'approved' ? 1 : 0 };
      }),
    }));

    const payload = {
      user: this.msalService.instance.getActiveAccount().username,
      results,
    };

    this.submitSelectionsSubscriptions$.push(this.redeploymentApprovalsService
      .submitRedeploymentScenario(payload)
      .subscribe(
        (response) => {
          this.selectionStatus = {};
          this.selectedButtons = {};
          this.isSubmitAllowed = false;
          //this.getTableData();
          //location.reload();

          this.toasterService.success(
            'Submitted successfully. Please wait for the request to be processed.'
          );
          this.getTableData();
          this.isRecordSelected = false;
          this.displayLoader = false;
        },
        (error) => {
          this.displayLoader = false;
          this.toasterService.error(error?.error);
          console.error('Submission error:', error);
        }
      )
    );
    //this.isInProgress = true;  // Disable the button and lock selections
    //this.updateTableData();
  }

  updateTableData() {
    setTimeout(() => {
      // After some delay (mimicking a backend call), enable interactions
      this.isInProgress = false;
    }, 10000); // Adjust this to match your data-fetching time
  }

  // Ensure no selection is allowed when isInProgress is true
  isSelectionDisabled(): boolean {
    return this.isInProgress;
  }

  isButtonSelected(row: any, status: string): boolean {
    return this.selectedButtons[row.SKU] === status;
  }

  // Check if submission is allowed
  checkIfSubmitAllowed(): void {
    this.submitAllowedSubscriptions$.push(this.redeploymentApprovalsService.checkIfSubmitAllowed().subscribe({
      next: (response) => {
        if (response.allowEdits === true) {
          this.isSubmitAllowed = true;
        } else {
          this.isSubmitAllowed = false;
        }
      },
      error: (error) => {
        console.error('Error in getting the parameter edit access', error);
      },
    })
    );
  }

  // Toggle row expansion
  toggleRow(row: any): void {
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  // Check if the row is expanded
  isExpanded(row: any): boolean {
    return this.expandedRow === row;
  }

  // Method to get the formatted SKU
  getFormattedSKU(sku: string): string {
    const materials = sku.split('|').map((material) => material.trim()); // Split by pipe and trim whitespace
    const mainMaterial = materials[0]; // The first material
    const additionalCount = materials.length - 1; // Count of extra materials

    // Return formatted string
    return additionalCount > 0
      ? `${mainMaterial} + (${additionalCount})`
      : mainMaterial;
  }

  hasAnySelection(): boolean {
    return Object.keys(this.selectedButtons).length > 0;
  }

  // Method to toggle the visibility of the search field for a specific column
  toggleSearch(index: number): void {
    if (this.activeSearchIndex === index) {
      // If the same column is clicked, toggle its visibility
      this.showSearch[index] = !this.showSearch[index];
    } else {
      // Hide all other search fields and show only the clicked one
      this.showSearch = this.displayedColumns.map(() => false);
      this.showSearch[index] = true;
    }
    // Update the active search index based on visibility
    this.activeSearchIndex = this.showSearch[index] ? index : -1;
  }

  getTableData(): void {
    this.displayLoader = true;
    const payload = this.buildFilterPayload(); // Build payload with filters
    this.selectedAction = [];
    this.tableDataSubscriptions$.push(this.redeploymentApprovalsService.getApprovalTableData(payload).subscribe(
      (response) => {
        this.tableData = response.results.map((row: any, index: number) => ({
          id: index,
          ...row,
        }));
        this.filteredData = [...this.tableData];
        this.displayLoader = false;
      },
      (error) => {
        this.toasterService.error('Error fetching approval data.');
        console.error('Error fetching approval data:', error);
        //this.isLoading = false; // Stop loading even on error
        this.displayLoader = false;
      }
    ));
  }

  private buildFilterPayload(): any {
    const payload = {
      ...this.selectedFilters,
      ModeOfTransport:
        this.filterValues?.Tlane_Mode_Type ||
        this.selectedFilters?.ModeOfTransport,
      SKU: this.filterValues?.SKU || this.selectedFilters.SKU,
      'Source Location':
        this.filterValues['Source Location'] ||
        this.selectedFilters['Source Location'],
      'Destination Location':
        this.filterValues['Destination Location'] ||
        this.selectedFilters['Destination Location'],
      Flag: this.selectedFlag ? this.selectedFlag : this.selectedFilters.Flag,
      strategyFlag: "NR"
    };
    return payload;
  }

  // Apply a specific filter and reload data from API
  applyFilter(columnName: string, value: string): void {
    this.displayLoader = true; // show loader till filtered data is displayed
    this.filterValues[columnName] = value;
    this.toggleSearch(Number(columnName)); //close search window
    this.getTableData(); // Reload data with new filters
  }

  // Clear a specific filter and reload data
  clearFilter(columnName: string): void {
    this.displayLoader = true;
    this.filterValues[columnName] = ''; // Clear the filter value
    this.toggleSearch(Number(columnName)); //close search window
    this.getTableData(); // Reload data with filters reset
  }

  // Apply a flag filter and reload data
  applyFlagFilter(): void {
    this.displayLoader = true;
    this.getTableData(); // Reload data with the selected flag
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
    this.summaryCardSubscriptions$.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.submitSelectionsSubscriptions$.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.submitAllowedSubscriptions$.forEach((subscription) =>
      subscription.unsubscribe()
    );
    this.tableDataSubscriptions$.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }
}

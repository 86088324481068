<div *ngIf="displayLoader" class="spinner-overlay">
  <app-loader [isLoading]="displayLoader" [message]="loadingMessage"></app-loader>
</div>
<div *ngIf="isApiError" class="spinner-overlay">
  <app-loader [isLoading]="isApiError" [message]="apiErrorMessage"></app-loader>
</div>

<div class="main-container" *ngIf="loadView">
  <div class="row">
    <div class="col" *ngFor="let data of summaryCardData; let i = index">
      <div class="card card-stats mb-4 mb-xl-0 summary-card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="card-title">{{ data.name }}</span>
            </div>
          </div>
        </div>
        <div class="score-txt">
          <span class="score" [ngStyle]="{
                'background': gradientColors(i),
                'background-clip': 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
              }">
            {{ data.currentYear }}{{ data.unit || '' }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col tbl-container">
      <div class="chart-card">
        <div class="header-div">
          <div class="title">{{ skuTableLabel }}</div>
        </div>
        <table mat-table [dataSource]="formattedTableData" class="mat-elevation-z8 shipping-details-tbl parent-table">
          <!-- Dynamic Column Headers -->
          <ng-container *ngFor="let column of tableColumns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef> {{ column === 'Name' ? 'Name' : formatDate(column) }} </th>
            <td mat-cell *matCellDef="let element"> {{ element[column] || '-' }} </td>
          </ng-container>
        
          <!-- Header and Row Definitions -->
          <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
        </table>
        
      </div>
    </div>
  </div>
    
      <app-expandable-table-with-action-btn [dataSource]="storageDetailsTable" [columnsToDisplay]="columnsToDisplay"
        
        [columnHeaderMap]="columnHeaderMap"></app-expandable-table-with-action-btn>
    
</div>
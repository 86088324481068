export const CARD_DATA = {
  cards: [
    {
      heading: "Plants",
      searchPlaceholder: "Search Packing Line",
      options: [
        "Texas", "Michigan", "California", "Florida", "Arizona (CMO 1)", "Ohio (CMO 2)"
      ]
    },
    {
      heading: "Packaging Line",
      searchPlaceholder: "Search Card 2",
      options: [
        "L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108",
        "LT01", "LT02", "L201", "L202", "L203", "L204", "LT03",
        "L301", "L302", "L303", "L304", "L305", "L306", "L307", "L308",
        "L309", "L310", "L311", "L312", "L313", "L314", "L315", "L316",
        "L317", "L318", "L319", "L320", "LK01", "LK02", "LK03", "LK04",
        "L401", "L402", "L403", "L404", "L405", "L406", "L407", "L408",
        "L409", "L410", "L501", "L502", "L503", "L601", "L602", "L603",
        "L604", "L605", "L606", "L607", "L608", "L609", "L610", "L611",
        "L612", "L613", "L614", "L615", "L616", "L617", "LK101", "LK102",
        "LK103", "LVEND5"
      ]
    },
    {
      heading: "Processing Line",
      searchPlaceholder: "Search Card 3",
      options: [
        "PL 1", "PL 2", "PL 3", "PL 4", "PL 5", "PL 6", "PL 7", "PL 8",
        "PL 9", "PL 10", "PL 11", "PL 12", "PL 13", "PL 14", "PL 15", "PL 16",
        "PL 17", "PL 18", "PL 19", "PL 20", "PL 21", "PL 22", "PL 23", "PL 24",
        "PL 25", "PL 26", "PL 27", "PL 28"
      ]
    },
    {
      heading: "Food categories",
      searchPlaceholder: "Search Card 4",
      options: [
        "Sunrise Crunch", "Banana Bite Crunch", "Caramel Corn Crunch", "Vanilla Delight Crunch",
        "Nutty Nugget Crunch", "Chocolate Cluster Crunch", "Berry Blast Crunch", "Sweet Syrup Crunch",
        "Honey Bear Puffs", "Cinnamon Puff Pops", "Golden Grain Puffs", "Sugar Sprinkled Puffs",
        "Munchy Mulberry Puffs", "Cocoa Cloud Puffs", "Tropical Tidbit Puffs", "Pearlescent Puff Pearls",
        "Frosted Sugar Flakes", "Maple Mingle Flakes", "Tangy Tart Flakes", "Fruity Flake Fiesta",
        "Pecan Pie Flakes", "Winter Wheat Flakes", "Barely Blended Flakes", "Spicy Cinnamon Flakes",
        "Oatmeal Golden Nuggets", "Strawberry Delight Nuggets", "Blueberry Bliss Nuggets", "Chocolate Chip Nuggets",
        "Crazy Coconut Nuggets", "Malted Milk Nuggets", "Forest Fruit Nuggets", "Vanilla Velvet Nuggets",
        "Corny Crispies", "Chocolate Craze Crispies", "Green Apple Crispies", "Spicy Caramel Crispies",
        "Blueberry Bundle Crispies", "Tropical Treat Crispies", "Nutella Nudge Crispies", "Marshmallow Magic Crispies",
        "Caramel Candy Crunchies", "Lemon Luscious Crunchies", "Coconut Cream Crunchies", "Vanilla Vortex Crunchies",
        "Biscuit Bliss Crunchies", "Chocolate Cherish Crunchies", "Strawberry Surprise Crunchies", "Buttered Popcorn Crunchies",
        "Honey Heaven Squarez", "Caramel Cloud Squarez", "Blueberry Bliss Squarez", "Chocolate Churned Squarez",
        "Orange Overload Squarez", "Minty Magic Squarez", "Cinnamon Celebration Squarez", "Nutty Nugget Squarez",
        "Tropical Tango Frosties", "Berry Blast Frosties", "Strawberry Splash Frosties", "Caramel Crush Frosties",
        "Vanilla Vivacity Frosties", "Milky Malt Frosties", "Chocolate Chunk Frosties", "Lemon Lush Frosties",
        "Almond Dream Tidbits", "Caramel Candy Tidbits", "Chocolate Chip Tidbits", "Fruity Feast Tidbits",
        "Vanilla Velvet Tidbits", "Banana Bliss Tidbits", "Coconut Crumble Tidbits", "Honey Harmony Tidbits",
        "Whole Wheat Nuggets", "Fresh Fruit Wholemeal", "Chocolate Choice Wholemeal", "Tangy Tart Wholemeal",
        "Frosted Figs Wholemeal", "Brownie Bite Wholemeal", "Vanilla Vogue Wholemeal", "Creamy Cashew Wholemeal",
        "Harvest Honey Multigrain", "Banana Blend Multigrain", "Cranberry Crunch Multigrain", "Malted Multigrain Mornings",
        "Frosted Frappe Multigrain", "Cinnamon Circa Multigrain", "Vanilla Velvet Multigrain", "Caramel Corn Multigrain",
        "Tropical Sugar Puffs", "Honey Sugar Wheels", "Choco Sugar Balls", "Fruit Sugar Spheres",
        "Vanilla Sugar Sparkles", "Marshmallow Sugar Stars"
      ]
    }
  ],

  months: [
    "Jan 2024", "Feb 2024", "Mar 2024", "Apr 2024", "May 2024", "Jun 2024",
    "Jul 2024", "Aug 2024", "Sep 2024", "Oct 2024", "Nov 2024", "Dec 2024"
  ],

  skus: [1100001, 1100002, 1100003, 1100004, 1100005, 1100006, 1100007, 1100008, 1100009, 1100010],

  cubeSizes: [100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600],

  cartoonSizes: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 28, 30],

  scenarios: [
    { simulation: "done", description: "Shutdown Processing Line 3 in Florida", isRunning: false },
    { simulation: "done", description: "Shutdown contract manufacturing (Arizona)", isRunning: false },
    { simulation: "not done", description: "Reduce operational hours by 60% in Ohio due to weather conditions", isRunning: false },
    { simulation: "not done", description: "Increase equipment efficiency at Michigan by 5%", isRunning: false },
    { simulation: "not done", description: "Increase demand of Cocoa Cloud Puffs by 20% in the month of October (Seasonal demand increase)", isRunning: false },
  ],
};

export const DEMAND_ALLOCATION_DATA = {
  title: 'Available vs Forecasted Utilized Capacity In Pallets',
  xAxisLabel: 'State',
  yAxisLabel: 'Capacity In Pallets',
  chartData: [
    {
      type: 'column',
      name: 'Warehouse Capacity',
      color: '#1777CF',
      yAxis: 0,
      data: [
        ['California', 180.2],
        ['Florida', 75.3],
        ['Ohio', 110.3],
        ['Michigan', 156.1],
        ['Texas', 65.5],
        ['Arizona', 65.7],
      ],
    },
    {
      type: 'column',
      name: 'Utilized Capacity',
      color: '#082C65',
      yAxis: 0,
      data: [
        ['California', 286.6],
        ['Florida', 85.2],
        ['Ohio', 128.5],
        ['Michigan', 185.5],
        ['Texas', 66.5],
        ['Arizona', 68.9],
      ],
    },
  ],
};
<div class="main">
    <div class="container">
        <div class="left-div">
            <h4><b>SCENARIO BUILDER</b></h4>
            <!-- Title Input -->
            <mat-form-field class="title-input" appearance="outline">
                <mat-label>Add Title</mat-label>
                <input matInput placeholder="Enter scenario title" required>
            </mat-form-field>
            <!-- Required Text -->
            <p class="required-text"><span class="red-asterisk">*</span>Primary Factors: Please select AT LEAST ONE from
                each category to run a scenario</p>
                <!-- primary factors -->
            <div class="cards-container">
                <mat-card class="card" *ngFor="let card of cards; let i = index">
                    <h3 class="card-heading" [ngStyle]="{ 'color': colors[i % colors.length] }">
                        {{ card.heading }}
                    </h3>
                    <div class="checkboxes">
                        <mat-checkbox *ngFor="let option of card.options" (change)="toggleOption(card.heading, option)">{{ option }}</mat-checkbox>
                    </div>
                </mat-card>
            </div>

            <p class="required-text">Optional: Edit secondary parameters or take default values</p>
            <!-- Additional Cards -->
             <!-- packing line -->
            <div class="additional-cards-container">
                <mat-card class="card small">
                    <p>Packaging Line</p>
                    <mat-form-field appearance="outline" class="small-select-2">
                        <mat-label style="color: gray;">Select Packaging Line</mat-label>
                        <mat-select [(ngModel)]="selectedPackagingLine">
                            <mat-option *ngFor="let option of cards[1].options" [value]="option">{{ option
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <hr class="grey-line">
                    <!--packing efficienct-->
                    <p>Packing efficiency</p>
                    <div class="slider-container">
                        <div class="slider-value-box">{{ packingEfficiency }}</div>%
                        <mat-slider min="1" max="100" step="1" [(ngModel)]="packingEfficiency"></mat-slider>
                    </div>
                    <hr class="grey-line">
                    <!-- processing line -->
                    <p>Processing Line</p>
                    <mat-form-field appearance="outline" class="small-select-2">
                        <mat-label style="color: gray;">Select Processing Line</mat-label>
                        <mat-select [(ngModel)]="selectedProcessingLine">
                            <mat-option *ngFor="let option of cards[2].options" [value]="option">{{ option
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <hr class="grey-line">
                    <!-- available days -->
                    <p>Enter available days</p>
                    <div class="date-inputs">
                        <div class="date-row">
                            <mat-form-field appearance="outline" class="small-input">
                                <input matInput type="number" min="1" max="31" [(ngModel)]="availableDays">
                            </mat-form-field><b>Days</b> /
                            <mat-form-field appearance="outline" class="small-select">
                                <mat-label style="color: gray;">Month</mat-label>
                                <mat-select [(ngModel)]="selectedMonth">
                                    <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <br>
                    <hr class="grey-line">
                    <!-- processing line utilization -->
                    <p>Processing Line Utilization</p>
                    <div class="slider-container">
                        <div class="slider-value-box">{{ processingLineUtil }}</div>%
                        <mat-slider min="1" max="100" step="1" [(ngModel)]="processingLineUtil"></mat-slider>
                    </div>
                    <hr class="grey-line">
                    <!-- processing Rate -->
                    <p>Desired Processing Rate</p>
                    <mat-form-field appearance="outline" class="small-select-2">
                        <mat-label style="color: gray;">Enter Rate</mat-label>
                        <input matInput placeholder="Enter processing rate" [(ngModel)]="processingRate">
                    </mat-form-field>

                </mat-card>
                <!-- food category -->
                <mat-card class="card small">
                    <p>Food Category</p>
                    <mat-form-field appearance="outline" class="small-select-2">
                        <mat-label style="color: gray;">Select Food Category</mat-label>
                        <mat-select [(ngModel)]="selectedFoodCategory">
                            <mat-option *ngFor="let option of cards[3].options" [value]="option">{{ option
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <hr class="grey-line">
                    <!-- demand variation -->
                    <p>Demand Variation</p>
                    <div class="slider-container">
                        <div class="slider-value-box">{{ demandVariation }}</div>%
                        <mat-slider min="1" max="100" step="1" [(ngModel)]="demandVariation"></mat-slider>
                    </div>
                    <div class="demand-box">
                        <p>Original Demand: <span class="value-color"><b>{{originalDemand | number}}</b></span></p>
                        <p>Modified Demand: <span class="value-color"><b>{{ getModifiedDemand() | number }}</b></span>
                        </p>
                    </div>
                    <hr class="grey-line">
                    <!-- wastage -->
                    <p>Wastage</p>
                    <div class="slider-container">
                        <div class="slider-value-box">{{ wastage }}</div>%
                        <mat-slider min="1" max="100" step="1" [(ngModel)]="wastage"></mat-slider>
                    </div>
                </mat-card>
                <!-- changeover from -->
                <mat-card class="card large">
                    <div class="changeover-container">
                        <div class="changeover-item">
                            <p>Changeover From</p>
                            <mat-form-field appearance="outline" class="small-select-2">
                                <mat-label style="color: gray;">Select Option</mat-label>
                                <mat-select [(ngModel)]="changeoverFrom">
                                    <mat-option *ngFor="let option of cards[3].options" [value]="option">{{ option
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- changeover to -->
                        <div class="changeover-item">
                            <p>Changeover To</p>
                            <mat-form-field appearance="outline" class="small-select-2">
                                <mat-label style="color: gray;">Select Option</mat-label>
                                <mat-select [(ngModel)]="changeoverTo">
                                    <mat-option *ngFor="let option of cards[3].options" [value]="option">{{ option
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <hr class="grey-line">
                    <!-- changeover hours -->
                    <p>Changeover Hours</p>
                    <div class="slider-container">
                        <div class="slider-value-box">{{ changeoverHours }}</div>%
                        <mat-slider min="1" max="24" step="1" [(ngModel)]="changeoverHours"></mat-slider>
                    </div>
                    <hr class="grey-line">
                    <!-- select sku -->
                    <p>Select SKU to modify</p>
                    <mat-form-field appearance="outline">
                        <mat-label style="color: gray;">Select SKU</mat-label>
                        <mat-select [(ngModel)]="selectedSKU">
                            <mat-option *ngFor="let sku of skus" [value]="sku">{{ sku }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <hr class="grey-line">
                    <!-- current cube size -->
                    <div class="changeover-container">
                        <div class="changeover-item">
                            <p>Current Cube Size</p>
                            <mat-form-field appearance="outline" class="small-select-2">
                                <mat-label style="color: gray;">Select Cube size</mat-label>
                                <mat-select [(ngModel)]="currentCubeSize">
                                    <mat-option *ngFor="let cube of cubeSizes" [value]="cube">{{ cube }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- modified cube size -->
                        <div class="changeover-item">
                            <p>Modified Cube Size</p>
                            <mat-form-field appearance="outline" class="small-select-2">
                                <mat-label style="color: gray;">Select Cube size</mat-label>
                                <mat-select [(ngModel)]="modifiedCubeSize">
                                    <mat-option *ngFor="let cube of cubeSizes" [value]="cube">{{ cube }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <hr class="grey-line">
                    <div class="changeover-container">
                        <div class="changeover-item">
                            <!-- current pack size -->
                            <p>Current Pack/Cartoon Size</p>
                            <mat-form-field appearance="outline" class="small-select-2">
                                <mat-label style="color: gray;">Select Pack size</mat-label>
                                <mat-select [(ngModel)]="currentCartoonSize">
                                    <mat-option *ngFor="let cartoonSize of cartoonSizes" [value]="cartoonSize">{{
                                        cartoonSize }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- modified cartoon size -->
                        <div class="changeover-item">
                            <p>Modified Pack/Cartoon Size</p>
                            <mat-form-field appearance="outline" class="small-select-2">
                                <mat-label style="color: gray;">Select Pack Size</mat-label>
                                <mat-select [(ngModel)]="modifiedCartoonSize">
                                    <mat-option *ngFor="let cartoonSize of cartoonSizes" [value]="cartoonSize">{{
                                        cartoonSize }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card>
            </div>
            <!-- Run Scenario Button -->
            <button mat-raised-button class="run-scenario-button" [ngClass]="{ 'running': isRunning }"
                (click)="runScenario()">
                <span class="button-text">{{ isRunning ? 'Running Scenario...' : 'Run Scenario' }}</span>
                <div class="fill-animation" *ngIf="isRunning"></div>
            </button>


        </div>
        <div class="right-div">
            <div class="heading-box">
                <h5><b>Possible Scenarios</b></h5>
            </div>
            <div class="scenario-list">
                <div class="scenario-item">
                    <p>Shutdown Processing Line 3 in Florida</p>
                    <hr class="grey-line">
                    <button mat-raised-button class="run-scenario-button-small" [ngClass]="{ 'running': isRunning1 }" 
                        (click)="runSmallScenario('Shutdown Processing Line 3 in Florida', 1)">
                        <span class="button-text">{{ isRunning1 ? 'Running Scenario...' : 'Run Scenario' }}</span>
                        <div class="fill-animation" *ngIf="isRunning1"></div>
                    </button>
                </div>
                
                <div class="scenario-item">
                    <p>Shutdown contract manufacturing (Arizona)</p>
                    <hr class="grey-line">
                    <button mat-raised-button class="run-scenario-button-small" [ngClass]="{ 'running': isRunning2 }" 
                        (click)="runSmallScenario('Shutdown contract manufacturing (Arizona)', 2)">
                        <span class="button-text">{{ isRunning2 ? 'Running Scenario...' : 'Run Scenario' }}</span>
                        <div class="fill-animation" *ngIf="isRunning2"></div>
                    </button>
                </div>
                
                <div class="scenario-item">
                    <p>Reduce operational hours by 60% in Ohio due to weather conditions</p>
                    <hr class="grey-line">
                    <button mat-raised-button class="run-scenario-button-small">
                        Run Scenario
                    </button>
                </div>
                <div class="scenario-item">
                    <p>Increase equipment efficiency at Michigan by 5%</p>
                    <hr class="grey-line">
                    <button mat-raised-button class="run-scenario-button-small">
                        Run Scenario
                    </button>
                </div>
        
                <!-- Scenario 5 (Clickable but Does Nothing) -->
                <div class="scenario-item">
                    <p>Increase demand of Cocoa Cloud Puffs by 20% in October</p>
                    <hr class="grey-line">
                    <button mat-raised-button class="run-scenario-button-small">
                        Run Scenario
                    </button>
                </div>
            </div>
        </div>
        
        
    </div>
</div>
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchedulingDetailsService } from '../../services/scheduling-details.service';
import { Subscription } from 'rxjs';
import { IPSPopUpRequest } from '../../interfaces/scheduling-interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plant-details-model',
  templateUrl: './plant-details-model.component.html',
  styleUrls: ['./plant-details-model.component.scss']
})
export class PlantDetailsModelComponent implements OnInit, OnDestroy {
  displayLoader: boolean = false;
  showPaginator: boolean = false;
  modalTableData = { data: null, columns: null, headers: null };
  subscriptions$: Subscription[] = [];
  dateColumns: string[] = [];
  originalSums: { [key: string]: number } = {};  // To track original sums for each date column
  reducedValues: { [key: string]: number } = {}; // To track the total amount reduced in each column
  addedValues: { [key: string]: number } = {};   // To track the total amount added back in each column
  viewPage = 'PLANT_DETAILS_MODEL';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private schedulingDetailsService: SchedulingDetailsService,
    private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.getModalTableData();
  }

  getModalTableData() {
    this.displayLoader = true;
    this.modalTableData = { data: null, columns: null, headers: null };
    const payload: IPSPopUpRequest = {
      plantID: this.data['plantID'],
    };

    this.subscriptions$.push(
      this.schedulingDetailsService.getPSPopUpData(payload).subscribe(
        (response: any[]) => {
          if (response && response.length) {
            let plantsHeaders: { [key: string]: string } = {};
            const plantsColumns = Object.keys(response[0]);

            this.dateColumns = plantsColumns.filter((col) => /^\d{2}-\d{2}-\d{4}$/.test(col));

            plantsColumns.forEach(column => {
              plantsHeaders[column] = column;
            });

            // Calculate original sums here
            this.originalSums = this.calculateOriginalSums(response);

            this.modalTableData = {
              data: this.transformData(response),  // Modified function
              columns: plantsColumns,
              headers: plantsHeaders,
            };
          }
          else {
            this.modalTableData = { data: [], columns: [], headers: [] };
            this.toasterService.info('No data available for the selected plant');
          }
          this.displayLoader = false;
        },
        (error) => {
          this.toasterService.error('Error occurred while fetching API response');
          this.displayLoader = false;
        }
      )
    );
  }

  calculateDateFieldsSum(row: any): number {
    return Object.keys(row)
      .filter((key) => /^\d{2}-\d{2}-\d{4}$/.test(key)) // Match date format
      .map((key) => Number(row[key]))
      .reduce((sum, value) => sum + value, 0);
  }

  transformData(inputData: any[]): any[] {
    // Adding three static rows at the top
    const staticRows = [
      {
        "Material ID": "M800145908",
        "Qty at Risk": "0",
        "Inventory": "14,908",
        "Avg Weekly Forecast": "0",
        "Production Plant": inputData.length > 0 ? inputData[0]["Production Plant"] : "Unknown",
        "01-10-2022": "285",
        "02-10-2022": "392",
        "03-10-2022": "0",
        "04-10-2022": "385",
        "05-10-2022": "201",
        "06-10-2022": "0",
        "07-10-2022": "387",
        "08-10-2022": "339",
        "09-10-2022": "225",
        "10-10-2022": "0",
        "11-10-2022": "353",
        "12-10-2022": "374",
        "13-10-2022": "0",
        "14-10-2022": "138"
      },
      {
        "Material ID": "M800908453",
        "Qty at Risk": "0",
        "Inventory": "20,907",
        "Avg Weekly Forecast": "1098",
        "Production Plant": inputData.length > 0 ? inputData[0]["Production Plant"] : "Unknown",
        "01-10-2022": "320",
        "02-10-2022": "0",
        "03-10-2022": "316",
        "04-10-2022": "0",
        "05-10-2022": "293",
        "06-10-2022": "389",
        "07-10-2022": "0",
        "08-10-2022": "215",
        "09-10-2022": "179",
        "10-10-2022": "0",
        "11-10-2022": "0",
        "12-10-2022": "0",
        "13-10-2022": "123",
        "14-10-2022": "0"
      },
      {
        "Material ID": "M800908123",
        "Qty at Risk": "0",
        "Inventory": "0",
        "Avg Weekly Forecast": "0",
        "Production Plant": inputData.length > 0 ? inputData[0]["Production Plant"] : "Unknown",
        "01-10-2022": "303",
        "02-10-2022": "225",
        "03-10-2022": "0",
        "04-10-2022": "297",
        "05-10-2022": "105",
        "06-10-2022": "0",
        "07-10-2022": "164",
        "08-10-2022": "216",
        "09-10-2022": "287",
        "10-10-2022": "0",
        "11-10-2022": "334",
        "12-10-2022": "393",
        "13-10-2022": "0",
        "14-10-2022": "359"
      }
    ];

    // Merge static rows with API response data
    const combinedData = [...staticRows, ...inputData];

    return combinedData.map((item, index) => {
      const cellColors: { [key: string]: string } = {};
      const materialID = item['Material ID'];
      const inventory = Number(item['Inventory']);
      const avgWeeklyForecast = Number(item['Avg Weekly Forecast']);
      const sumOfDateFields = this.calculateDateFieldsSum(item);

      const formatNumber = (value: any) => {
        const num = Number(value);
        return !isNaN(num) ? new Intl.NumberFormat('en-US').format(num) : value;
      };

      for (const key in item) {
        if (materialID === this.data.materialID) {
          // Condition 1: Selected Material ID => Green
          cellColors[key] = "green";
        } else if (avgWeeklyForecast === 0 && inventory > 0 && sumOfDateFields > 0) {
          // Condition 2: Avg Weekly Forecast = 0, Inventory > 0, Sum of Date > 0 => Red
          cellColors[key] = 'red';
        } else if (sumOfDateFields + inventory > avgWeeklyForecast) {
          // Condition 3: Sum of Date + Inventory < Avg Weekly Forecast => No color
          cellColors[key] = 'transparent'; // No color
        }
      }

      return {
        id: index + 1,
        cellColors: cellColors,
        ...Object.keys(item).reduce((acc, key) => {
          acc[key] = formatNumber(item[key]);
          return acc;
        }, {})
      };
    });
  }

  calculateOriginalSums(data: any[]): { [key: string]: number } {
    const sums = {};
    data.forEach(row => {
      this.dateColumns.forEach(col => {
        if (!sums[col]) sums[col] = 0;
        sums[col] += Number(row[col]);
      });
    });
    return sums;
  }

  onTableValueChange(event: { rowIndex: number, column: string, newValue: number }): void {
    const { rowIndex, column, newValue } = event;
    const row = this.modalTableData.data[rowIndex];

    // Check if the row and column exist
    if (!row || !row.hasOwnProperty(column)) {
      console.error(`Invalid row or column: ${column}`);
      return;
    }

    // Validation for negative values
    if (newValue < 0) {
      alert('Value cannot be less than 0');
      return;
    }

    // Update the value if everything is valid
    row[column] = newValue;
  }



  reduceValue(column: string, row: any) {
    // Ensure that the value can be reduced but not below 0
    if (row[column] > 0) {
      row[column] -= 1; // Decrease by 1 for example (you can adjust as needed)
      this.reducedValues[column] = (this.reducedValues[column] || 0) + 1; // Track the reduction
    }
  }

  addValue(column: string, row: any) {
    const reducedAmount = this.reducedValues[column] || 0;
    const addedAmount = this.addedValues[column] || 0;

    // Ensure that adding does not exceed the original sum of the column
    const currentSum = this.calculateColumnSum(column);
    if (addedAmount < reducedAmount) {
      row[column] += 1; // You can adjust the amount added
      this.addedValues[column] = addedAmount + 1; // Track the added value
    } else {
      this.toasterService.warning('Cannot add more values, the sum limit has been reached.');
    }
  }

  // Helper method to calculate the sum for a column
  calculateColumnSum(column: string): number {
    let sum = 0;
    this.modalTableData?.data?.forEach(row => {
      sum += Number(row[column]);
    });
    return sum;
  }



  ngOnDestroy() {
    this.subscriptions$.forEach(subscription => subscription.unsubscribe());
  }
}

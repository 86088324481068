<div class="main-container">
    <div class="row">
        <div class="col" *ngFor="let data of summaryCardData; let i = index">
            <div class="card card-stats mb-4 mb-xl-0 summary-card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <span class="card-title">{{data.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="score-txt">
                    <span class="score"
                        [ngStyle]="{ 'background': gradientColors(i), 'background-clip': 'text', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent' }">{{ formatNumber(data.currentYear) }}{{data.unit}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="chart-card">
                <div class="color-indicator-tbl">
                    <div class="clr-indicator-row">
                        <span class="clr-indicator-color" style="background-color: yellow;"></span>
                        <span class="clr-indicator-txt">Capacity% <= 50%</span>
                    </div>
                    <div class="clr-indicator-row">
                        <span class="clr-indicator-color" style="background-color: green;"></span>
                        <span class="clr-indicator-txt">50% < Capacity% <=100%</span>
                    </div>
                    <div class="clr-indicator-row">
                        <span class="clr-indicator-color" style="background-color: red;"></span>
                        <span class="clr-indicator-txt">Capacity% > 100%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="chart-card">
                <div class="header-div">
                    <div class="title">{{tblTitles[0]}}</div>
                </div>
                <div class="capacity-planned-tbl">
                    <app-mat-table [showPaginator]="false" [dataSource]="capacityPlannedbyWeekTbl"
                        [displayedColumns]="capacityPlannedbyWeekColumns"
                        [headers]="capacityPlannedbyWeekHeaders"></app-mat-table>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2">
            <div class="chart-card">
                <div class="header-div">
                    <div class="title">Truck Required By Plant</div>
                </div>
                <ng-container *ngIf="apiDataLoaded">
                    <high-chart [enableLegend]="false" [enabledExporting]="true" [yAxisLabelFormat]="'{value}'"
                        [yAxisTickAmount]="6" [xAxisLabel]="'Origin'" [yAxisLabel]="'Truck Required'"
                        [seriesData]="truckPlantSeries" [chartHeight]="610" [enableXAxisScrollbar]="xAxisScrollable"
                        (drilldownEvent)="onDrilldown($event, 'Origin')">
                    </high-chart>
                </ng-container>
            </div>
        </div>
        <div class="col-md-2">
            <div class="chart-card">
                <div class="header-div">
                    <div class="title">Truck Required By Lane</div>
                </div>
                <ng-container *ngIf="apiDataLoaded">
                    <high-chart [enableLegend]="false" [enabledExporting]="true" [yAxisLabelFormat]="'{value}'"
                        [yAxisTickAmount]="6" [xAxisLabel]="'Origin->Destination'" [yAxisLabel]="'Truck Required'"
                        [seriesData]="truckLaneSeries" [chartHeight]="610" [enableXAxisScrollbar]="xAxisScrollable"
                        (drilldownEvent)="onDrilldown($event, 'Origin->Destination')">
                    </high-chart>
                </ng-container>
            </div>
        </div>
        <div class="col-md-8">
            <div class="chart-card">
                <div class="header-div">
                    <div class="title">{{tblTitles[1]}}</div>
                </div>
                <div class="capacity-planned-tbl" style="height: 260px;">
                    <app-mat-table [showPaginator]="false" [dataSource]="capacityPlannedbyLaneWeekTbl"
                        [displayedColumns]="capacityPlannedbyLaneWeekColumns"
                        [headers]="capacityPlannedbyLaneWeekHeaders"
                        (rowClicked)="handleRowClick($event)"></app-mat-table>
                </div>
                <div class="header-div">
                    <div class="title">Additional Cost of Shifting Excess TL to LTL</div>
                </div>
                <ng-container *ngIf="apiDataLoaded">
                    <high-chart [enableLegend]="true" [enabledExporting]="true" [yAxisLabelFormat]="'{value}'"
                        [yAxisTickAmount]="6" [xAxisLabel]="'Day'" [yAxisLabel]="'LTL Cost'"
                        [seriesData]="lTLCostSeries" [chartHeight]="280" [stacking]="'normal'"
                        (drilldownEvent)="onDrilldown($event, 'LTL Cost')">
                    </high-chart>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="spinner-overlay">
    <app-spinner></app-spinner>
</div>
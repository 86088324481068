export const ALL_LABEL = 'All';

export const RECOMMENDATION_GROUP_TAB_NAME = {
  RECOMMENDATION:'Recommendation',
  WAREHOUSE:'Warehouse',
  TRUCK:'TRUCK'
}

export const WMS_MIN_X_AXIS_LIMIT = 20;

export const WMS_CHART_COLORS = ['#1777CF', '#082C65', '#FF736A', '#da5bb3'];

export const SPACE_UTILIZATION_RANGE_DATA = [
  'Above 90%',
  'Below 50%',
  'Between 50% and 90%',
];

export const UTILIZATION_COLOR_CLASS = {
  UNDERUTILIZED: 'underutilized_dc',
  OVERUTILIZED: 'overutilized_dc',
  OPTIMALLY_UTILIZED: 'optimally_utilized_dc',
};

export const WMS_CHART_NAMES = {
  UTILIZED_CAPACITY: 'Utilized Capacity',
  WAREHOUSE_CAPACITY: 'Warehouse Capacity',
  FIFTY_PERCENT_WAREHOUSE_CAPACITY: '50% Warehouse Capacity',
  NINETY_PERCENT_WAREHOUSE_CAPACITY: '90% Warehouse Capacity',
};

export const WAREHOUSE_UTILIZATION_GRAPH_NAME = {
  CAPACITY_IN_PALLETS: 'Capacity in Pallets',
};

export const UTILIZATION_COLORS = ['#B9251C', '#D5C10B', '#168736'];

export const IO_Y_AXIS_LABEL = 'Capacity of Pallets';

export const DC_FILTER = [
  {
    name: 'All',
    value: 'All',
  },
  {
    name: 'P108',
    value: 'P108',
  },
  {
    name: 'P109',
    value: 'P109',
  },
  {
    name: 'P11',
    value: 'P11',
  },
  {
    name: 'P110',
    value: 'P110',
  },
  {
    name: 'P111',
    value: 'P111',
  },
  {
    name: 'P116',
    value: 'P116',
  },
  {
    name: 'P118',
    value: 'P118',
  },
  {
    name: 'P119',
    value: 'P119',
  },
  {
    name: 'P12',
    value: 'P12',
  },
  {
    name: 'P120',
    value: 'P120',
  },
  {
    name: 'P122',
    value: 'P122',
  },
  {
    name: 'P123',
    value: 'P123',
  },
  {
    name: 'P125',
    value: 'P125',
  },
  {
    name: 'P129',
    value: 'P129',
  },
  {
    name: 'P131',
    value: 'P131',
  },
];

export const WAREHOUSE_CARD = {
  data: [
    {
      name: 'Projected OTIF %',
      icon: null,
      currentYear: '18%',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Projected Revenue',
      icon: null,
      currentYear: '$2.29M',
      lastYear: null,
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Distribution Centers',
      icon: null,
      currentYear: '15',
      lastYear: '71%',
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Overall Pallet Utilization',
      icon: null,
      currentYear: '29%',
      lastYear: '98%',
      unit: null,
      hasIncreased: null,
    },
  ],
};

export const SPACE_UTILIZATION_CARD = {
  data: [
    {
      name: 'Optimally Utilized',
      icon: null,
      currentYear: '87',
      lastYear: 'Between 50% and 100%',
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Underutilized',
      icon: null,
      currentYear: '228',
      lastYear: 'Below 50%',
      unit: null,
      hasIncreased: null,
    },
    {
      name: 'Overutilized',
      icon: null,
      currentYear: '5',
      lastYear: 'Above 100%',
      unit: null,
      hasIncreased: null,
    },
  ],
};

export const UTILIZED_CAPACITY_DATA = {
  title: 'Available vs Forecasted Utilized Capacity In Pallets',
  xAxisLabel: 'DC',
  yAxisLabel: 'Capacity In Pallets',
  chartData: [
    {
      type: 'column',
      name: 'Warehouse Capacity',
      color: '#1777CF',
      yAxis: 0,
      data: [
        ['P108', 760737],
        ['P109', 363172],
        ['P11', 1132046],
        ['P110', 372110],
        ['P111', 176500],
        ['P116', 137911],
        ['P118', 515955],
        ['P119', 267630],
        ['P12', 162066],
        ['P120', 60545],
        ['P122', 730338],
        ['P123', 409218],
        ['P125', 230906],
        ['P129', 58511],
        ['P131', 427370],
      ],
    },
    {
      type: 'column',
      name: 'Utilized Capacity',
      color: '#082C65',
      yAxis: 0,
      data: [
        ['P108', 783558],
        ['P109', 380688],
        ['P11', 1229098],
        ['P110', 386246],
        ['P111', 177071],
        ['P116', 135964],
        ['P118', 519062],
        ['P119', 267821],
        ['P12', 163160],
        ['P120', 57368],
        ['P122', 758752],
        ['P123', 418460],
        ['P125', 226588],
        ['P129', 55977],
        ['P131', 437540],
      ],
    },
    {
      type: 'line',
      name: '50 %',
      color: '#FF736A',
      yAxis: 1,
      data: [
        ['P108', 25],
        ['P109', 32],
        ['P11', 22],
        ['P110', 20],
        ['P111', 19],
        ['P116', 8],
        ['P118', 23],
        ['P119', 37],
        ['P12', 38],
        ['P120', 15],
        ['P122', 24],
        ['P123', 25],
        ['P125', 33],
        ['P129', 31],
        ['P131', 9],
      ],
    },
  ],
  xAxisScrollable: true,
};

export const DC_UTILIZATION_TABLE_DATA = [
  {
    DC_ID: 'P100',
    ranking: 1,
    'Oct 1': '50%',
    'Oct 2': '101%',
    'Oct 3': '20%',
    'Oct 14': '70%',
  },
  {
    DC_ID: 'P101',
    ranking: 2,
    'Oct 1': '50%',
    'Oct 2': '101%',
    'Oct 3': '20%',
    'Oct 14': '70%',
  },
  {
    DC_ID: 'P102',
    ranking: 3,
    'Oct 1': '50%',
    'Oct 2': '101%',
    'Oct 3': '20%',
    'Oct 14': '70%',
  },
  {
    DC_ID: 'P103',
    ranking: 4,
    'Oct 1': '50%',
    'Oct 2': '101%',
    'Oct 3': '20%',
    'Oct 14': '70%',
  },
  {
    DC_ID: 'P104',
    ranking: 5,
    'Oct 1': '50%',
    'Oct 2': '101%',
    'Oct 3': '20%',
    'Oct 14': '70%',
  },
];

export const DC_UTILIZATION_COLUMNS: string[] = [
  'DC_ID',
  'ranking',
  'Oct 1',
  'Oct 2',
  'Oct 3',
  'Oct 14',
];

export const DC_UTILIZATION_HEADERS = {
  DC_ID: 'DC ID',
  ranking: 'Ranking',
  'Oct 1': 'Oct 1',
  'Oct 2': 'Oct 2',
  'Oct 3': 'Oct 3',
  'Oct 14': 'Oct 14',
};

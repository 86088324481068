<div class="main-container" *ngIf="loadOrdersAtRiskView">
  <div class="row">
    <div class="col" *ngFor="let data of ordersAtRiskSummaryCardData; let i = index">
      <div class="card card-stats mb-4 mb-xl-0 summary-card risk-card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span class="card-title">{{ data.name }}</span>
            </div>
          </div>
        </div>
        <div class="score-txt">
          <div class="score" [ngStyle]="{
              background: gradientColors(i),
              'background-clip': 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }">
            <span *ngIf="data.name=='# SO at Risk'">{{data.currentYear | number: '1.0-0' }}</span>
            <span *ngIf="data.name!='# SO at Risk'">{{ data.currentYear }}</span>
          </div>
          <div class="bench-score" [style.visibility]="data.lastYear ? 'visible' : 'hidden'">
            ({{ data.lastYear }})
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="map-card">
        <div class="header-div">
          <div class="map-legendTbl">
            <table class="map-legendTable" style="width: 100%">
              <tr>
                <td>
                  <span class="title">{{ skuTableLabel }}</span>
                </td>
                <td>
                  <span class="details-btn-container" *ngIf="showDetailsBtn">
                    <button class="details-btn" mat-raised-button (click)="openDialog()"
                      matTooltip="View Orders at risk and SKU plant projection details">Details</button>
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <ng-container *ngIf="projectedOTIFBySKUData?.length > 0">
          <app-projected-otif-by-sku-table [tableData]="projectedOTIFBySKUData" [showSelectionBox]="false"
            [showPagination]="false" [showSearch]="false" [customHeight]="250"
            (emitSelectedCustomerId)="getSelectedCustomerId($event)"
            [selectedToggle]="selectedToggle"></app-projected-otif-by-sku-table>
        </ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="map-card">
        <div class="header-div">
          <div class="title">
            {{ projectedOTIFByCustomerResponse?.title }}
          </div>
        </div>
        <ng-container>
          <div *ngIf="projectedOTIFByCustomerResponse?.chartData?.length > 0" class="customer">
            <high-chart [enableLegend]="true" [enabledExporting]="false" [yAxisLabelFormat]="'{value}'"
              [secondaryYAxisLabelFormat]="'{value}%'" [yAxisTickAmount]="6"
              [xAxisLabel]="projectedOTIFByCustomerResponse?.xAxisLabel" [yAxisLabel]="
                projectedOTIFByCustomerResponse?.yAxisLabel || yAxisLabel
              " [seriesData]="projectedOTIFByCustomerResponse?.chartData" [chartHeight]="280" [enableXAxisScrollbar]="
                projectedOTIFByCustomerResponse?.xAxisScrollable
              " [isBarClickable]="true" [showSecondaryYAxis]="true" [graphName]="graphName.OTIF_BY_CUSTOMER"
              (emitClickedBarDetails)="getClickedBarDetails($event)">
            </high-chart>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="chart-card cursor-pointer">
        <div class="header-div">
          <div class="title">{{ projectedOTIFByRDDResponse?.title }}</div>
        </div>
        <ng-container>
          <div *ngIf="projectedOTIFByRDDResponse?.chartData?.length > 0">
            <high-chart [enableLegend]="true" [enabledExporting]="false" [yAxisLabelFormat]="'{value}'"
              [yAxisTickAmount]="6" [xAxisLabel]="projectedOTIFByRDDResponse?.xAxisLabel" [yAxisLabel]="
                projectedOTIFByRDDResponse?.yAxisLabel || yAxisLabel
              " [seriesData]="projectedOTIFByRDDResponse?.chartData" [chartHeight]="280" [isBarClickable]="true"
              [secondaryYAxisLabelFormat]="'{value}%'" [showSecondaryYAxis]="true" [enableXAxisScrollbar]="
                projectedOTIFByRDDResponse?.xAxisScrollable
              " (emitClickedBarDetails)="getClickedBarDetails($event)" [graphName]="graphName.OTIF_BY_RDD">
            </high-chart>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="chart-card">
        <div class="header-div">
          <div class="title">
            {{ projectedOTIFByLocationResponse?.title }}
          </div>
        </div>
        <ng-container>
          <div *ngIf="projectedOTIFByLocationResponse?.chartData?.length > 0">
            <high-chart [enableLegend]="true" [enabledExporting]="false" [yAxisLabelFormat]="'{value}'"
              [yAxisTickAmount]="6" [xAxisLabel]="plantIdLabel" [yAxisLabel]="
                projectedOTIFByLocationResponse?.yAxisLabel || yAxisLabel
              " [seriesData]="projectedOTIFByLocationResponse?.chartData" [chartHeight]="280" [isBarClickable]="true"
              [secondaryYAxisLabelFormat]="'{value}%'" [showSecondaryYAxis]="true" [enableXAxisScrollbar]="
                projectedOTIFByLocationResponse?.xAxisScrollable
              " (emitClickedBarDetails)="getClickedBarDetails($event)" [graphName]="graphName.OTIF_BY_LOCATION">
            </high-chart>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
export const VISIBLE_FILTERPANE_ROUTES = [
    '/home/soe/orders-at-risk',
    '/home/soe/weekly-truck-availability',
    '/home/soe/projected-shortages',
    '/home/soe/redeployment-approval-impact',
    '/home/soe/warehouse-utilization'
];

export const SHOW_QUANTITY_VALUE_TOGGLE_ROUTES = [
    '/home/soe/orders-at-risk'
];

export const SHOW_ASIS_OR_RECOMMENDATION_TOGGLE_ROUTES = [
    '/home/soe/orders-at-risk'
];

export const SHOW_APPROVAL_BUTTON_ROUTES = [
    '/home/soe/orders-at-risk',
    '/home/soe/redeployment-approval-impact',
    '/home/soe/soe-monitor',
    '/home/soe/production-approval'
];
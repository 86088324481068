import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ScreenRotationService } from 'src/app/services/screen-rotation.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    loggedInUser: string;
    userRoles = ['Admin'];
    //userRoles = ['Admin', 'VP of Operations', 'Plant Operator'];
    defaultuserRole: any;

    userInput: string = '';
    isDisabled: boolean = false;

    myControl = new FormControl();
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
    filteredOptions: Observable<string[]> = new Observable<string[]>();
    msgOptions: any;
    hasOptions: boolean = false;
    searchKeys = [
        "shortfall",
        "allocation plan",
        "impact",
        "SKUs",
        "processing line",
        "utilization",
        "reallocation plan",
        "shutdown",
        "demand",
        "CMOs",
        "backlog",
        "SKU",
        "unfulfilled backlog",
        "revised backlog",
        "delayed shipment",
        "delays",
        "lane",
        "root causes",
        "loading delay",
        "unloading delay",
        "Non - primary carrier",
        "Capacity constraint",
        "sales order",
        "deficit",
        "Qty",
        "carrier",
        "delay reasons",
        "recommended solution"
    ];

    @Input() title: string;
    loggedInEmail: string;

    constructor(
        private authService: MsalService,
        private router: Router,
        private apiService: ApiService) {
        this.initializeFilterOptions();

        this.apiService.getMsgOptions().subscribe((response) => {
            if (response && response.questions) {
                this.msgOptions = response.questions;
            } else {
                this.msgOptions = []; // or handle the error as needed
                console.error('No data received for msgOptions.');
            }
        });
    }

    ngOnInit(): void {
        this.defaultuserRole = localStorage.getItem('role') ?? this.userRoles[0];
        this.loggedInUser = this.authService.instance.getActiveAccount().name.split('.').join(' ');
        this.loggedInEmail = this.authService.instance.getActiveAccount().username
    }

    logout() {
        sessionStorage.removeItem('tsqExpressions');
        localStorage.removeItem('role');
        this.router.navigate(["/logout"]);
    }
    userDefinedTabs(role = 'VP of Operations') {
        localStorage.setItem('role', role);
        location.reload()

    }

    gotoMainPage(): void {
        this.router.navigate(['/home/mps-overview']);
    }

    // Initialize the filtered options
    initializeFilterOptions() {
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
    }
    private _filter(value: string | null): string[] {
        if (!value) {
            this.hasOptions = false;
            return [];
        }
        const filterValue = value.toLowerCase();

        // Check if any keyword from searchKeys is present in the input
        const containsAnyKeyword = this.searchKeys.filter(key => filterValue.includes(key));
        var filtered = [];
        if (containsAnyKeyword.length > 0) {
            filtered = this.msgOptions.filter(option => option.toLowerCase().includes(containsAnyKeyword));
        } else {
            filtered = this.msgOptions.filter(option => option.toLowerCase().includes(filterValue));
        }
        // Check if the filtered options are not empty and the input is dirty or touched
        this.hasOptions = (this.myControl.dirty || this.myControl.touched) && filtered.length > 0;
        return this.hasOptions ? filtered : [];
    }

    sendMessage() {
        if (this.myControl.value) {
            //this.router.navigate(['/home/sc-assistant']);
            this.router.navigate(['/home/sc-assistant'], { queryParams: { msg: this.myControl.value } });
        }
    }
}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Breadcrumb } from '../../models/breadcrumb.model';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SideNavService } from 'src/app/services/side-nav/side-nav.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import {
  RECOMMENDATION_TAB_NAME,
  SHOW_ASIS_OR_RECOMMENDATION_TOGGLE_ROUTES,
  SHOW_DAILY_BUTTON_ROUTES,
  SHOW_DOC_LINK_BUTTON_ROUTES,
  SHOW_EXECUTIVE_VIEW_BUTTON_ROUTES,
  SHOW_QUANTITY_VALUE_TOGGLE_ROUTES,
  SHOW_RESILIENCY_ALERT_BTN_ROUTES,
  SHOW_WEEKLY_BUTTON_ROUTES,
  SOE_ROUTES,
  VISIBLE_FILTERPANE_ROUTES,
} from 'src/app/constants/breadcrumb.constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Output() redeploymentApprovalClicked = new EventEmitter<any>();
  isVisibleDocLink: boolean = false;
  isDailyButtonVisible: boolean = false;
  breadcrumbs: Breadcrumb[];
  isButtonVisible: boolean = false;
  @Output() toggleApplied = new EventEmitter<any>();
  selectedToggle: boolean = true;
  asISOrRecommendationToggle: boolean = true;
  showQuantityValueToggle: boolean = false;
  showAsIsOrRecommendationToggle: boolean = false;
  visibleFilterPaneRoutes = VISIBLE_FILTERPANE_ROUTES;
  showQuantityValueToggleRoutes = SHOW_QUANTITY_VALUE_TOGGLE_ROUTES;
  showAsIsOrRecommendationToggleRoutes =
    SHOW_ASIS_OR_RECOMMENDATION_TOGGLE_ROUTES;
  showDailyButtonRoutes = SHOW_DAILY_BUTTON_ROUTES;
  showWeeklyButtonRoutes = SHOW_WEEKLY_BUTTON_ROUTES;
  private readonly SOE_FILTER_RESET_STATE = 'soeFilterResetState';
  isWeeklyButtonVisible: boolean = false;
  currentRoute: string;
  soeRoutes = SOE_ROUTES;
  isExecutiveViewVisible: boolean = false;
  showExecutiveViewButtonRoutes = SHOW_EXECUTIVE_VIEW_BUTTON_ROUTES;
  showDocLinkButtonRoutes = SHOW_DOC_LINK_BUTTON_ROUTES;
  tabSubscription: Subscription;
  isResiliencyAlertBtnVisible: boolean = false;
  showResiliencyAlertBtnRoutes = SHOW_RESILIENCY_ALERT_BTN_ROUTES;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private sideNavService: SideNavService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (
          event.urlAfterRedirects === '/home/soe/redeployment-approval-impact' ||
          event.urlAfterRedirects === '/home/soe/production-scheduling'
        ) {
          this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(
            '/home/soe/orders-at-risk'
          );
        } else if (
          event.urlAfterRedirects === '/home/soe/production-attainment' ||
          event.urlAfterRedirects === '/home/soe/inventory-health' ||
          event.urlAfterRedirects === '/home/soe/weekly-warehouse-utilization' ||
          event.urlAfterRedirects === '/home/soe/weekly-truck-availability' ||
          event.urlAfterRedirects === '/home/soe/forecast-consumption'
        ) {
          this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(
            '/home/soe/projected-shortages'
          );
        } else if (event.urlAfterRedirects === '/home/output-template') {
          this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(
            '/home/forecast-review'
          );
        } else {
          this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(
            event.urlAfterRedirects
          );
        }
      });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
        this.checkButtonVisibility();
        this.checkToggleVisibility();
        this.checkDailyOrWeeklyButtonVisibility();
        this.checkDocLinkVisibility();
        this.checkExecutiveViewButtonVisibility();
        this.checkResiliencyAlertBtnVisibility();
      }
    });
    this.checkButtonVisibility();
    this.checkToggleVisibility();
    this.checkDailyOrWeeklyButtonVisibility();
    this.breadcrumbService.setToggleState(this.selectedToggle);
    this.breadcrumbService.setasISOrRecommendationToggleState(
      this.asISOrRecommendationToggle
    );
    this.checkExecutiveViewButtonVisibility();
    this.tabSubscription = this.sideNavService.selectedTabName$.subscribe((tabName) => {
      this.asISOrRecommendationToggle = true;
    });
    this.checkResiliencyAlertBtnVisibility();
  }

  checkButtonVisibility(): void {
    this.isButtonVisible = this.visibleFilterPaneRoutes.includes(
      this.router.url
    );
  }

  toggleRightSidebar() {
    this.sideNavService.toggleRightSidenav();
  }

  checkToggleVisibility(): void {
    this.showQuantityValueToggle = this.showQuantityValueToggleRoutes.includes(
      this.router.url
    );

    if (this.currentRoute === this.soeRoutes.ORDERS_AT_RISK) {
      this.sideNavService.setSelectedIndex(0);
    }

    /**
     * for recommendation tab we don't need to show As Is and recommendation toggle button
     * but for rest of the tabs in the route need to show hence get that tab and set variable to false
     */
    if (this.currentRoute === this.soeRoutes.REDEPLOYMENT_APPROVAL_IMPACT_ROUTE) {
      this.sideNavService.selectedTabName$.subscribe((tabName) => {
        if (tabName == RECOMMENDATION_TAB_NAME) {
          this.showAsIsOrRecommendationToggle = false;
        } else {
          this.showAsIsOrRecommendationToggle = true;
        }
      });
    } else {
      // for other routes show as required
      this.showAsIsOrRecommendationToggle =
        this.showAsIsOrRecommendationToggleRoutes.includes(this.router.url);
    }
  }

  checkDailyOrWeeklyButtonVisibility(): void {
    this.isDailyButtonVisible = this.showDailyButtonRoutes.includes(
      this.router.url
    );
    this.isWeeklyButtonVisible = this.showWeeklyButtonRoutes.includes(
      this.router.url
    );
  }

  checkExecutiveViewButtonVisibility(): void {
    this.isExecutiveViewVisible = this.showExecutiveViewButtonRoutes.includes(
      this.router.url
    );
  }

  checkResiliencyAlertBtnVisibility(): void {
    this.isResiliencyAlertBtnVisible = this.showResiliencyAlertBtnRoutes.includes(
      this.router.url
    );
  }

  getSelectedToggle(event: MatButtonToggleChange) {
    this.selectedToggle = event?.value == 'true' ? true : false;
    localStorage.setItem(this.SOE_FILTER_RESET_STATE, 'false');
    this.breadcrumbService.setToggleState(this.selectedToggle);
  }

  getAsIsOrRecommendationToggle(event: MatButtonToggleChange) {
    this.asISOrRecommendationToggle = event?.value == 'true' ? true : false;
    localStorage.setItem(this.SOE_FILTER_RESET_STATE, 'false');
    this.breadcrumbService.setasISOrRecommendationToggleState(
      this.asISOrRecommendationToggle
    );
  }

  checkDocLinkVisibility(): void {
    this.isVisibleDocLink = this.showDocLinkButtonRoutes.includes(
      this.router.url
    );
  }

  openDocuments() {
    const libraryUrl =
      'https://sites.ey.com/sites/GenAI_Documents/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGenAI%5FDocuments%2FShared%20Documents%2FGenAI%5FDoc&viewid=6aa59d1e%2D8e0e%2D489d%2Dbb39%2De1a3b4177b2e?view=tiles';
    window.open(libraryUrl, '_blank');
  }

  ngOnDestroy(): void {
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
  }
}

import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular'; // Ensure this is the correct import
import { PlantSimulationAvailabilityModalComponent } from '../features/home/components/plant-simulation-availability-modal/plant-simulation-availability-modal.component';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private individualUserEmails: string[] = [
        'gaurav.ramakant.patil@gds.ey.com',
        'tarun.shankar.mathur@gds.ey.com',
        'oviamathi.karunanidhi@gds.ey.com',
        'kokulakanth.lalitha@gds.ey.com',
        'naveen.kumar.s1@gds.ey.com'
    ];

    constructor(private msalService: MsalService) { }

    // Method to get the user's email from the MsalService token
    private getUserEmail(): string | null {
        const account = this.msalService.instance.getActiveAccount();
        if (account && account.idTokenClaims) {
            return account.idTokenClaims.email as string || account.idTokenClaims.preferred_username as string; // Use preferred_username if email is not available
        }
        return null;
    }

    // Method to check if the user is an individual
    isIndividualUser(): boolean {
        const userEmail = this.getUserEmail()?.toLowerCase(); // Convert the userEmail to lowercase
        return userEmail
            ? this.individualUserEmails.some(email => email.toLowerCase() === userEmail) // Check case-insensitively
            : false;
    }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  RDD_LABEL,
  SKU_PLANT_COLOR_CLASS,
  TOGGLE_BUTTON,
} from '../../constants/details-modal.constant';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { OrdersAtRiskService } from '../../services/orders-at-risk.service';
import {
  IModalTable,
  IOrdersAtRiskDataRequestBody,
} from '../../interfaces/orders-at-risk.interface';
import { SOE_VIEWS } from '../../../constants/soe.constants';
import { ToasterWrapperService } from 'src/app/services/toaster-wrapper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.scss'],
})
export class DetailsModalComponent implements OnInit, OnDestroy {
  toggleBtn = TOGGLE_BUTTON;
  skuTableColorClass = SKU_PLANT_COLOR_CLASS;
  rddLabel = RDD_LABEL;
  viewPage: string = SOE_VIEWS.ORDERS_AT_RISK_MODAL_TABLE;
  selectedFilters: { name: string; value: string | Date }[];
  apiParam = { toggle: true, page: 1 };
  private subscriptions$: Array<Subscription> = new Array<Subscription>();
  modalTableData: IModalTable;
  selectedToggle: boolean = true;
  displayLoader: boolean = false;
  showPaginator: boolean = false;
  noTableData: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ordersAtRiskService: OrdersAtRiskService,
    private readonly toasterService: ToasterWrapperService
  ) {
    this.getModalData();
  }

  ngOnInit(): void {
    const filterValue: IOrdersAtRiskDataRequestBody = this.data?.filter?.value;
    this.selectedFilters = [
      { name: 'SKU', value: filterValue?.materialID },
      { name: 'Customer Id', value: filterValue?.customer },
      { name: 'Requested Delivery Date', value: filterValue?.rdd },
      { name: 'DC Id', value: filterValue?.plantID },
    ];
  }

  getModalData() {
    this.displayLoader = true;
    this.noTableData = false;
    this.modalTableData = { data: null, columns: null, headers: null };
    const payload = this.data?.filter?.value;
    const asISOrRecommendationToggle =
      this.data?.filter?.asISOrRecommendationToggle;
    this.apiParam = asISOrRecommendationToggle
      ? { ...this.apiParam, page: 1 }
      : { ...this.apiParam, page: 2 };
    this.subscriptions$.push(
      this.ordersAtRiskService
        .getModalDataForOrdersAtRisk(payload, this.apiParam)
        .subscribe(
          (tableData: any[]) => {
            if (tableData.length) {
              if (this.selectedToggle) {
                const ordersAtRiskColumns = Object.keys(tableData[0]);
                let ordersAtRiskHeaders = {};
                const headers = ordersAtRiskColumns.map((data) => {
                  return { [data]: data };
                });
                headers.map((data) => {
                  Object.assign(ordersAtRiskHeaders, data);
                });
                this.modalTableData = {
                  data: tableData,
                  columns: ordersAtRiskColumns,
                  headers: ordersAtRiskHeaders,
                };
                this.viewPage = SOE_VIEWS.ORDERS_AT_RISK_MODAL_TABLE;
              } else {
                const skuProjectionsColumns = Object.keys(tableData[0]);
                let skuProjectionsHeaders = {};
                const headers = skuProjectionsColumns.map((data) => {
                  return { [data]: data };
                });
                headers.map((data) => {
                  Object.assign(skuProjectionsHeaders, data);
                });

                this.modalTableData = {
                  data: this.formatDataToCommaSeparatedValue(tableData),
                  columns: skuProjectionsColumns,
                  headers: skuProjectionsHeaders,
                };
                this.viewPage = SOE_VIEWS.SKU_PROJECTIONS;
              }
            }
            this.displayLoader = false;
            this.noTableData = true;
          },
          (error) => {
            this.toasterService.error(
              'Error occurred while fetching api response'
            );
            this.displayLoader = false;
          }
        )
    );
  }

  formatDataToCommaSeparatedValue(tableData: any[]) {
    const formatter = new Intl.NumberFormat();
    const formattedData = tableData.map((item) => {
      const formattedItem = { ...item };
      Object.keys(formattedItem).forEach((key) => {
        if (typeof formattedItem[key] === 'number') {
          formattedItem[key] = formatter.format(formattedItem[key]);
        }
      });
      return formattedItem;
    });
    return formattedData;
  }

  getSelectedToggle(event: MatButtonToggleChange) {
    this.selectedToggle = event?.value == 'true' ? true : false;
    this.apiParam = { ...this.apiParam, toggle: this.selectedToggle };
    this.getModalData();
  }

  ngOnDestroy(): void {
    this.selectedFilters = null;
    this.subscriptions$.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}

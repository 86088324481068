import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Breadcrumb } from '../../models/breadcrumb.model';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SideNavService } from 'src/app/services/side-nav/side-nav.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import {
  SHOW_APPROVAL_BUTTON_ROUTES,
  SHOW_ASIS_OR_RECOMMENDATION_TOGGLE_ROUTES,
  SHOW_QUANTITY_VALUE_TOGGLE_ROUTES,
  VISIBLE_FILTERPANE_ROUTES,
} from 'src/app/constants/breadcrumb.constants';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Output() redeploymentApprovalClicked = new EventEmitter<any>();
  isVisibleDocLink: boolean = false;
  isApprovalsButtonVisible: boolean = false;
  breadcrumbs: Breadcrumb[];
  isButtonVisible: boolean = false;
  @Output() toggleApplied = new EventEmitter<any>();
  selectedToggle: boolean = true;
  asISOrRecommendationToggle: boolean = true;
  showQuantityValueToggle: boolean = false;
  showAsIsOrRecommendationToggle: boolean = false;
  visibleFilterPaneRoutes = VISIBLE_FILTERPANE_ROUTES;
  showQuantityValueToggleRoutes = SHOW_QUANTITY_VALUE_TOGGLE_ROUTES;
  showAsIsOrRecommendationToggleRoutes =
    SHOW_ASIS_OR_RECOMMENDATION_TOGGLE_ROUTES;
  showApprovalButtonRoutes = SHOW_APPROVAL_BUTTON_ROUTES;
  private readonly SOE_FILTER_RESET_STATE = 'soeFilterResetState';
  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private sideNavService: SideNavService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (
          (event.urlAfterRedirects === '/home/soe/redeployment-approval-impact' || event.urlAfterRedirects === '/home/soe/production-approval')
        ) {
          this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(
            '/home/soe/orders-at-risk'
          );
        } else {
          this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(
            event.urlAfterRedirects
          );
        }
      });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkButtonVisibility();
        this.checkToggleVisibility();
        this.checkApprovalButtonVisibility();
        this.checkDocLinkVisibility();
      }
    });
    this.checkButtonVisibility();
    this.checkToggleVisibility();
    this.checkApprovalButtonVisibility();
    this.breadcrumbService.setToggleState(this.selectedToggle);
    this.breadcrumbService.setasISOrRecommendationToggleState(this.asISOrRecommendationToggle);
  }

  checkButtonVisibility(): void {
    this.isButtonVisible = this.visibleFilterPaneRoutes.includes(
      this.router.url
    );
  }

  toggleRightSidebar() {
    this.sideNavService.toggleRightSidenav();
  }

  checkToggleVisibility(): void {
    this.showQuantityValueToggle = this.showQuantityValueToggleRoutes.includes(
      this.router.url
    );
    this.showAsIsOrRecommendationToggle =
      this.showAsIsOrRecommendationToggleRoutes.includes(this.router.url);
  }

  checkApprovalButtonVisibility(): void {
    this.isApprovalsButtonVisible = this.showApprovalButtonRoutes.includes(
      this.router.url
    );
  }

  getSelectedToggle(event: MatButtonToggleChange) {
    this.selectedToggle = event?.value == 'true' ? true : false;
    localStorage.setItem(this.SOE_FILTER_RESET_STATE, 'false');
    this.breadcrumbService.setToggleState(this.selectedToggle);
  }

  getAsIsOrRecommendationToggle(event: MatButtonToggleChange) {
    this.asISOrRecommendationToggle = event?.value == 'true' ? true : false;
    localStorage.setItem(this.SOE_FILTER_RESET_STATE, 'false');
    this.breadcrumbService.setasISOrRecommendationToggleState(
      this.asISOrRecommendationToggle
    );
  }

  checkDocLinkVisibility(): void {
    this.isVisibleDocLink = this.router.url.includes('/home/tms-chatbot');
  }

  openDocuments() {
    const libraryUrl = "https://sites.ey.com/sites/GenAI_Documents/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGenAI%5FDocuments%2FShared%20Documents%2FGenAI%5FDoc&viewid=6aa59d1e%2D8e0e%2D489d%2Dbb39%2De1a3b4177b2e?view=tiles";
    window.open(libraryUrl, "_blank");
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, of, ReplaySubject } from 'rxjs';
import { createFilterEndpoints, FILTER_LABELS } from 'src/app/features/home/components/soe/constants/sidenav-filters.constants';
import { ApiService } from '../api.service';
import { OrdersAtRiskService } from 'src/app/features/home/components/soe/orders-at-risk/services/orders-at-risk.service';
import { catchError } from 'rxjs/operators';
import { WarehouseUtilizationService } from 'src/app/features/home/components/soe/warehouse-truck-utilization/services/warehouse-utilization.service';
import { RedeploymentApprovalsService } from '../redeployment-approvals.service';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  isRightSidenavOpen: any;
  private rightSidebarFiltersSubject = new BehaviorSubject<{ tab: string; filters: { type: string; label: string; selectedValue?: string; payloadLabel?: any; startDate?: Date; endDate?: Date; options: string[] }[] } | null>(null);
  rightSidebarFilters$ = this.rightSidebarFiltersSubject.asObservable();

  private isRightSidenavOpenSubject = new BehaviorSubject<boolean>(false);
  isRightSidenavOpen$ = this.isRightSidenavOpenSubject.asObservable();
  private filterEndpoints;

  constructor(private apiService: ApiService,
    private ordersAtRiskService: OrdersAtRiskService,
    private warehouseUtilizationService: WarehouseUtilizationService,
    private redeploymentApprovalService: RedeploymentApprovalsService,
  ) {
    this.filterEndpoints = createFilterEndpoints(apiService, ordersAtRiskService, warehouseUtilizationService, redeploymentApprovalService);
  }

  setFiltersForCurrentRoute(route: string, tab: string): void {
    const filterLabelsData = FILTER_LABELS[route]?.[tab];
    const filterEndpoints = this.filterEndpoints[route]?.[tab];

    if (filterLabelsData && filterEndpoints) {
      const apiCalls = filterEndpoints.map(endpointFn =>
        endpointFn().pipe(
          catchError(() => of([]))
        )
      );
      forkJoin(apiCalls).subscribe(optionsArray => {
        const filters = filterLabelsData.map((filter, index) => ({
          type: filter.type,
          label: filter.label,
          payloadLabel: filter.payloadLabel,
          selectedValue: filter.selectedValue,
          startDate: filter.startDate,
          endDate: filter.endDate,
          options: optionsArray[index] as string[] || []
        }));
        this.updateRightSidebarFilters({ tab, filters });
      });
    } else {
      this.updateRightSidebarFilters(null);
    }
  }
  updateRightSidebarFilters(filters: { tab: string; filters: { type: string; label: string; selectedValue?: string; payloadLabel?: any; startDate?: Date; endDate?: Date; options: string[] }[] } | null): void {
    this.rightSidebarFiltersSubject.next(filters);
  }

  toggleRightSidenav() {
    const currentState = this.isRightSidenavOpenSubject.value;
    this.isRightSidenavOpenSubject.next(!currentState);
  }

  closeRightSidebar() {
    this.isRightSidenavOpenSubject.next(false);
  }

  openRightSidebar() {
    this.isRightSidenavOpenSubject.next(true);
  }

  private selectedIndex = new BehaviorSubject<number>(0);
  selectedIndex$ = this.selectedIndex.asObservable();

  setSelectedIndex(index: number): void {
    this.selectedIndex.next(index);
  }
}
